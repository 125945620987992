
import { Outlet, Navigate, useLocation, useParams, } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectAuthIhrWebSdk } from './features/auth/ihr-web-sdk/authSlice'; // auth/ihr-web-sdk

import DashboardDefaultLayout from './layouts/private/dashboard/default/dashboard-default'; // Private Dashboard Layout
import VerifyView from './features/public/verify-view/verify-view'; // Verify View

import { Loader } from 'semantic-ui-react'

import { getQueryStringParams } from './layouts/utils'

// auth/ihr-web-sdk
export const PrivateRouteAuthIhrWebSdk = ({ redirectTo, children, component: Component, }) => {
  const location = useLocation();
  const { init, authentication, profile, } = useSelector(selectAuthIhrWebSdk);
  //console.debug('PrivateRouteAuthIhrWebSdk', {location, init, authentication, profile});
  return (
    init.loading || authentication.loading || profile.loading ? 
      <Loader content='Loading...' />
    // : init.error || authentication.error || profile.error ? 
    //   <Message error header='An Error Occurred' content={init.error} />
    : init.isInitialized && authentication.loggedIn && profile.profileData !== null ?
      (children ?
        children
      : Component ?
        <Component />
      :
        <Outlet />
      )
    :
      <Navigate to={redirectTo} state={{ from: location }} replace />
  )
};

// auth/ihr-web-sdk dashboard
export const PrivateRouteDashboardIhrWebSdk = ({ redirectTo, children, component: Component, ...rest }) => {
  const location = useLocation();
  const { init, authentication, profile, } = useSelector(selectAuthIhrWebSdk);
  //console.debug('PrivateRouteDashboardIhrWebSdk - location', location);
  //console.debug('PrivateRouteDashboardIhrWebSdk - init', init);
  //console.debug('PrivateRouteDashboardIhrWebSdk - authentication', authentication);
  //console.debug('PrivateRouteDashboardIhrWebSdk - profile', profile);
  return (
    init.loading || authentication.loading || profile.loading ? 
      <Loader content='Loading...' />
    // : init.error || authentication.error || profile.error ? 
    //   <Message error header='An Error Occurred' content={init.error} />
    : init.isInitialized && authentication.loggedIn && profile.profileData !== null ?
      <DashboardDefaultLayout>
        {children ?
          children
        : Component ?
          <Component {...rest} />
        :
          <Outlet />
        }
      </DashboardDefaultLayout>
    :
      <Navigate to={redirectTo} state={{ from: location }} replace />
  )
};

export const OwnerVerifyRoute = () => {  
  let location = useLocation();
  let { submissionUUID } = useParams();
  const parsedParams = getQueryStringParams(location.search);
  //console.debug('OwnerVerifyRoute - params', { location, submissionUUID, parsedParams, });
  return (
    <VerifyView location={location} submissionUUID={submissionUUID} type={parsedParams.type}  />
  )
};