import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { generateApiUrl, generateiHrAuthHeaders } from '../podcast-submit/utils'

import { setActiveStatus } from '../podcast-submit/podcastSubmitSlice'

const apiUrl = generateApiUrl('dashboardSlice.js');

const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
//console.log('podcasts-table.js - REACT_APP_ENV_MODE', REACT_APP_ENV_MODE);

const initialState = {
  value: 0,
  podcastSubmissions: {
    loading: false,
    error: null,
    allPodcastSubmissions: null,
    psByLimitPage: {},
    limit: 5,
    pageNumber: 1,
    totalRows: 0,
    totalPages: 0,
    continuationToken: null,
  },
  resendOwnerVerify: {
    loading: false,
    error: null,
    uuid: '',
    podcastSubmitData: null,
    verifyResponseData: null,
    resentByUuid: {},
  },
  updateRssUrl: {
    loading: false,
    error: null,
    formError: false,
    showId: '',
    rssFeedUrl: '',
    submissionUUID: null,
    podcastSubmitData: null,
    responseData: null,
    updatedByShowId: {},
  },
  takedownShow: {
    loading: false,
    error: null,
    showId: '',
    partitionKey: null,
    rowKey: null,
    responseData: null,
    updatedByShowId: {},
  },
  restoreShow: {
    loading: false,
    error: null,
    showId: '',
    partitionKey: null,
    rowKey: null,
    responseData: null,
    updatedByShowId: {},
  },
  modalUpdateRssUrl: {
    visible: false,
    loading: false,
    error: null,
    showId: null,
    podcastSubmitData: null,
  },
  modalTakedownShow: {
    visible: false,
    loading: false,
    error: null,
    showId: null,
    partitionKey: null,
    rowKey: null,
    showName: null,
    podcastSubmitData: null,
  },
  modalRestoreShow: {
    visible: false,
    loading: false,
    error: null,
    showId: null,
    partitionKey: null,
    rowKey: null,
    podcastSubmitData: null,
  }
}

export const getPodcastSubmissions = createAsyncThunk(
  'dashboard/getPodcastSubmissions',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('getPodcastSubmissions - args', args);
    const state = getState();
    const { podcastSubmissions, } = state.dashboard;
    const { limit, pageNumber } = podcastSubmissions
    // check for data already fetched
    let checkRefresh = args && args.refresh ? true : false;
    if (checkRefresh === false && typeof podcastSubmissions.psByLimitPage[limit+'-'+pageNumber] !== 'undefined') {
      return podcastSubmissions.psByLimitPage[limit+'-'+pageNumber] // return data from state
    } else {
      // fetch data
      const headers = await generateiHrAuthHeaders(getState, 'getPodcastSubmissions');
      //console.debug('getPodcastSubmissions - headers', headers);
      //const { limit, continuationToken, } = args;
      headers.limit = args && args.limit ? args.limit : podcastSubmissions.limit;
      headers.continuationToken = args && args.continuationToken ? args.continuationToken : initialState.podcastSubmissions.continuationToken;
      const requestOptions = {
        method: 'GET',
        headers,
      };
      //console.debug('getPodcastSubmissions - requestOptions', requestOptions);
      try {
        const res = await fetch(apiUrl+'/dashboard/podcasts', requestOptions)
        const resJSON = await res.json();
        if (res.ok) {
          //console.debug('getPodcastSubmissions - resJSON', resJSON);
          if (resJSON.pagedPodcastSubmissions.length !== 0) {
            for (let podcast of resJSON.pagedPodcastSubmissions) {
              try {
                // eslint-disable-next-line
                const podcastiHrData = await fetch((REACT_APP_ENV_MODE === 'production' ? 'https://us.api.iheart.com' : 'https://us-stg.api.iheart.com') + '/api/v3/podcast/podcasts/'+podcast.showId, {method: 'GET'})
                  .then((data) => data.json())
                  .then((json) => {
                    //console.debug('getPodcastSubmissions - json', json);
                    podcast.ihrData = JSON.parse(JSON.stringify(json));
                    // also handle podcast image property
                    podcast.showImageUrl = json.imageUrl ? json.imageUrl : (REACT_APP_ENV_MODE === 'production' ? 'https://i.iheart.com' : 'https://i-stg.iheart.com') + '/v3/catalog/podcast/'+ podcast.showId +'';
                    //console.debug('getPodcastSubmissions - podcast.showImageUrl', podcast.showImageUrl);
                  })
              } catch (error) {
                podcast.ihrData = error
              }
            }
          }
          return resJSON;
        }
        throw resJSON
      } catch (error) {
        //console.debug('getPodcastSubmissions - error', error);
        let errorBody
        if (error.type) {
          errorBody = error
        } else {
          errorBody = {
            type: 'dashboard-podcast',
            message: 'Get All User Podcasts Failed',
            rawError: error && error.message ? error.message : error,
          }
        }
        return rejectWithValue(errorBody)
      }
    }
})

export const resendOwnerVerification = createAsyncThunk(
  'dashboard/resendOwnerVerification',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('resendOwnerVerification - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'resendOwnerVerification');
    //console.debug('resendOwnerVerification - headers', headers);
    //const state = getState();
    //const { podcastSubmissions, } = state.dashboard;
    //const { limit, pageNumber } = podcastSubmissions
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        podcastSubmitData: args.podcastSubmitData,
      })
    };
    //console.debug('resendOwnerVerification - requestOptions', requestOptions);
    try {
      const res = await fetch(apiUrl+'/dashboard/owner-verify', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('resendOwnerVerification - error', error);
      const errorBody = {
        type: 'dashboard-resend-owner-verify',
        message: 'Error',
        rawError: error && error.message ? error.message : error,
      }
      return rejectWithValue(errorBody)
      //throw error
    }
})

export const postUpdateRssUrl = createAsyncThunk(
  'dashboard/postUpdateRssUrl',
  async (args, { getState, rejectWithValue, dispatch }) => {
    //console.debug('postUpdateRssUrl - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'postUpdateRssUrl');
    //console.debug('postUpdateRssUrl - headers', headers);
    const state = getState();
    const { authentication, profile } = state.authIhrWebSdk;
    // eslint-disable-next-line
    const { submitPodcast, rssVerification } = state.podcastSubmit;
    const { modalUpdateRssUrl, } = state.dashboard;
    const { confirmInfoAccurate, confirmNoGuarantee, confirmTermsCopyright } = args;

    //const newAttemptValue = submitPodcast.attempt+1;
    //console.debug('postUpdateRssUrl - newAttemptValue', newAttemptValue);

    // generate a snapshot of the Podcast Submission Data User Verified
    //const rssFeedDataSnapshot = JSON.stringify(rssVerification.rssData);
    //console.debug('postUpdateRssUrl - rssFeedDataSnapshot', rssFeedDataSnapshot);
    let rssFeedDataSnapshotNoEpisodes = JSON.parse(JSON.stringify(rssVerification.rssData));
    if (rssFeedDataSnapshotNoEpisodes.episodes && rssFeedDataSnapshotNoEpisodes.episodes.length !== 0) {
      rssFeedDataSnapshotNoEpisodes.episodes = []
    }
    //console.debug('postUpdateRssUrl - rssFeedDataSnapshotNoEpisodes - episodes removed', rssFeedDataSnapshotNoEpisodes);

    const podcastSubmitData = {
      showId: modalUpdateRssUrl.showId,
      ihrUrl: modalUpdateRssUrl.podcastSubmitData.ihrUrl,
      submissionUUID: modalUpdateRssUrl.submissionUUID,
      customScanBroadcasterName: modalUpdateRssUrl.podcastSubmitData.customScanBroadcasterName,
      ihrProfileId: authentication.loginData.profileId,
      ihrProfileName: profile.profileData.name,
      ihrProfileEmail: profile.profileData.email,
      formData: {confirmInfoAccurate, confirmNoGuarantee, confirmTermsCopyright},
      rssFeedUrl: rssVerification.rssFeedUrl,
      rssFeedDataSnapshot: JSON.stringify(rssFeedDataSnapshotNoEpisodes),
    };
    //console.debug('postUpdateRssUrl - podcastSubmitData', podcastSubmitData);
    dispatch(setPodcastUpdateData({showId: modalUpdateRssUrl.showId, ihrUrl: modalUpdateRssUrl.podcastSubmitData.ihrUrl, podcastSubmitData}));

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(podcastSubmitData)
    };
    //console.debug('postUpdateRssUrl - requestOptions', requestOptions);

    try {
      const res = await fetch(apiUrl+'/dashboard/update-rss-url', requestOptions)
      let resJSON = await res.json();
      //console.debug('postUpdateRssUrl - resJSON', resJSON);
      if (resJSON.rawError || typeof resJSON.showId === 'undefined') {
        throw resJSON;
      } else {
        dispatch(setActiveStatus('update-rss-url-success'));
        return resJSON
      }
    } catch (error) {
      //console.debug('postUpdateRssUrl - error', error);
      // catch already on iHeartRadio error
      if (error.code === 409) {
        let alreadyAddedResponse = error.rawError;
        alreadyAddedResponse.code = error.code;
        // append owner verify data
        if (typeof error.ownerVerify !== 'undefined') {
          alreadyAddedResponse.ownerVerify = error.ownerVerify
        }
        return alreadyAddedResponse
      } else {
        //throw error
        return rejectWithValue(error)
      }
    }
})

export const postTakedownShow = createAsyncThunk(
  'dashboard/postTakedownShow',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('postTakedownShow - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'postTakedownShow');
    //console.debug('postTakedownShow - headers', headers);
    //const state = getState();
    //const { podcastSubmissions, } = state.dashboard;
    //const { limit, pageNumber } = podcastSubmissions
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        showId: args.showId,
        partitionKey: args.partitionKey,
        rowKey: args.rowKey,
      })
    };
    //console.debug('postTakedownShow - requestOptions', requestOptions);
    try {
      const res = await fetch(apiUrl+'/dashboard/takedown-show', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('postTakedownShow - error', error);
      const errorBody = {
        type: 'dashboard-takedown-show',
        message: 'Takedown Show Error',
        rawError: error && error.rawError ? error.rawError : error,
      }
      return rejectWithValue(errorBody)
      //throw error
    }
})

export const postRestoreShow = createAsyncThunk(
  'dashboard/postRestoreShow',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('postRestoreShow - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'postRestoreShow');
    //console.debug('postRestoreShow - headers', headers);
    //const state = getState();
    //const { podcastSubmissions, } = state.dashboard;
    //const { limit, pageNumber } = podcastSubmissions
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        showId: args.showId,
        partitionKey: args.partitionKey,
        rowKey: args.rowKey,
      })
    };
    //console.debug('postRestoreShow - requestOptions', requestOptions);
    try {
      const res = await fetch(apiUrl+'/dashboard/restore-show', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('postRestoreShow - error', error);
      const errorBody = {
        type: 'dashboard-restore-show',
        message: 'Restore Show Error',
        rawError: error && error.rawError ? error.rawError : error,
      }
      return rejectWithValue(errorBody)
      //throw error
    }
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    toggleModal_updateRssUrl: (state, action) => {
      //console.debug('toggleModal_updateRssUrl - action', action);
      state.modalUpdateRssUrl = {
        ...state.modalUpdateRssUrl,
        visible: !state.modalUpdateRssUrl.visible,
        showId: action.payload.showId ? action.payload.showId : null,
        submissionUUID: action.payload.submissionUUID ? action.payload.submissionUUID : null,
        podcastSubmitData: action.payload.podcastSubmitData ? action.payload.podcastSubmitData : null,
      }
    },
    closeModal_updateRssUrl: (state) => {
      //console.debug('closeModal_updateRssUrl');
      state.updateRssUrl = initialState.updateRssUrl
      state.modalUpdateRssUrl = initialState.modalUpdateRssUrl
    },
    setPodcastUpdateData: (state, action) => {
      //console.debug('setPodcastUpdateData - action.payload', action.payload);
      //state.updateRssUrl.showId = action.payload.showId ? action.payload.showId : null
      //state.updateRssUrl.rssFeedUrl = action.payload.rssFeedUrl ? action.payload.rssFeedUrl : null
      state.updateRssUrl.podcastSubmitData = action.payload.podcastSubmitData ? action.payload.podcastSubmitData : null
    },
    toggleModal_takedownShow: (state, action) => {
      //console.debug('toggleModal_takedownShow - action', action);
      state.modalTakedownShow = {
        ...state.modalTakedownShow,
        visible: !state.modalTakedownShow.visible,
        showId: action.payload.showId ? action.payload.showId : null,
        partitionKey: action.payload.partitionKey ? action.payload.partitionKey : null,
        rowKey: action.payload.rowKey ? action.payload.rowKey : null,
        showName: action.payload.showName ? action.payload.showName : null,
        podcastSubmitData: action.payload.podcastSubmitData ? action.payload.podcastSubmitData : null,
      }
    },
    closeModal_takedownShow: (state) => {
      //console.debug('closeModal_takedownShow');
      state.takedownShow = initialState.takedownShow
      state.modalTakedownShow = initialState.modalTakedownShow
    },
    toggleModal_restoreShow: (state, action) => {
      //console.debug('toggleModal_restoreShow - action', action);
      state.modalRestoreShow = {
        ...state.modalRestoreShow,
        visible: !state.modalRestoreShow.visible,
        showId: action.payload.showId ? action.payload.showId : null,
        partitionKey: action.payload.partitionKey ? action.payload.partitionKey : null,
        rowKey: action.payload.rowKey ? action.payload.rowKey : null,
        showName: action.payload.showName ? action.payload.showName : null,
        podcastSubmitData: action.payload.podcastSubmitData ? action.payload.podcastSubmitData : null,
      }
    },
    closeModal_restoreShow: (state) => {
      //console.debug('closeModal_restoreShow');
      state.restoreShow = initialState.restoreShow
      state.modalRestoreShow = initialState.modalRestoreShow
    },
  },
  extraReducers: {
    [getPodcastSubmissions.pending]: (state, { meta }) => {
      state.podcastSubmissions.loading = true
      state.podcastSubmissions.error = null
      state.podcastSubmissions.limit = meta?.arg?.limit ? meta.arg.limit : state.podcastSubmissions.limit
      state.podcastSubmissions.pageNumber = meta?.arg?.page ? meta.arg.page : state.podcastSubmissions.pageNumber
    },
    [getPodcastSubmissions.fulfilled]: (state, { payload }) => {
      //console.debug('payload', payload);
      state.podcastSubmissions.loading = false
      // test for loading redux cache data
      if (typeof state.podcastSubmissions.psByLimitPage[state.podcastSubmissions.limit+'-'+state.podcastSubmissions.pageNumber] !== 'undefined') {
        state.podcastSubmissions.psByLimitPage[state.podcastSubmissions.limit+'-'+state.podcastSubmissions.pageNumber] = payload
        state.podcastSubmissions.continuationToken = payload.continuationToken ? payload.continuationToken : null
      } else {
        state.podcastSubmissions.allPodcastSubmissions = state.podcastSubmissions.allPodcastSubmissions === null ? payload.pagedPodcastSubmissions : [...state.podcastSubmissions.allPodcastSubmissions, ...payload.pagedPodcastSubmissions]
        state.podcastSubmissions.psByLimitPage[state.podcastSubmissions.limit+'-'+state.podcastSubmissions.pageNumber] = payload
        //state.podcastSubmissions.psByLimitPage[state.limit+'-'+state.pageNumber] = newAllPodcastSubmissionsChunked !== null ? newAllPodcastSubmissionsChunked[(state.pageNumber-1)] : newAllPodcastSubmissions
        state.podcastSubmissions.totalRows = (state.podcastSubmissions.totalRows + payload.pagedPodcastSubmissions.length) + (payload.continuationToken ? 1 : 0)
        state.podcastSubmissions.totalPages = (state.podcastSubmissions.totalPages++) + (payload.continuationToken ? 1 : 0)
        state.podcastSubmissions.continuationToken = payload.continuationToken ? payload.continuationToken : null
      }
    },
    [getPodcastSubmissions.rejected]: (state, { payload, error }) => {
      state.podcastSubmissions.loading = false
      state.podcastSubmissions.error = payload ? payload : error
    },
    [resendOwnerVerification.pending]: (state, { meta }) => {
      state.resendOwnerVerify.loading = true
      state.resendOwnerVerify.error = null
      state.resendOwnerVerify.uuid = meta.arg.uuid ? meta.arg.uuid : null
      state.resendOwnerVerify.podcastSubmitData = meta.arg.podcastSubmitData ? meta.arg.podcastSubmitData : null
      state.resendOwnerVerify.resentByUuid[state.resendOwnerVerify.uuid] = {
        loading: true,
        response: null,
        error: null,
      }
    },
    [resendOwnerVerification.fulfilled]: (state, { payload }) => {
      //console.debug('payload', payload);
      state.resendOwnerVerify.loading = false
      state.resendOwnerVerify.verifyResponseData = payload
      state.resendOwnerVerify.resentByUuid[state.resendOwnerVerify.uuid] = {
        loading: false,
        response: payload.data,
      }
    },
    [resendOwnerVerification.rejected]: (state, { error }) => {
      state.resendOwnerVerify.loading = false
      state.resendOwnerVerify.error = error
      state.resendOwnerVerify.resentByUuid[state.resendOwnerVerify.uuid] = {
        loading: false,
        error: error?.message,
      }
    },
    [postUpdateRssUrl.pending]: (state, { meta }) => {
      //console.debug('updateRssUrl.pending - meta', meta);
      state.updateRssUrl.loading = true
      state.updateRssUrl.error = null
      state.updateRssUrl.showId = meta.arg.showId ? meta.arg.showId : null
      state.updateRssUrl.rssFeedUrl = meta.arg.rssFeedUrl ? meta.arg.rssFeedUrl : null
      state.updateRssUrl.updatedByShowId[meta.arg.showId] = {
        loading: true,
        showId: meta.arg.showId ? meta.arg.showId : null,
        rssFeedUrl: meta.arg.rssFeedUrl ? meta.arg.rssFeedUrl : null,
        error: null,
        formError: false,
      }
    },
    [postUpdateRssUrl.fulfilled]: (state, { payload }) => {
      //console.debug('payload', payload);
      state.updateRssUrl.loading = false
      state.updateRssUrl.responseData = payload
      state.updateRssUrl.updatedByShowId[state.updateRssUrl.showId] = {
        loading: false,
        responseData: payload.data,
      }
    },
    [postUpdateRssUrl.rejected]: (state, { error, payload }) => {
      //console.debug('updateRssUrl.rejected - payload', payload);
      state.updateRssUrl.loading = false
      state.updateRssUrl.formError = true
      state.updateRssUrl.error = payload
      state.updateRssUrl.updatedByShowId[state.updateRssUrl.showId] = {
        loading: false,
        formError: true,
        error: payload,
      }
    },
    [postTakedownShow.pending]: (state, { meta }) => {
      state.takedownShow.loading = true
      state.takedownShow.showId = meta.arg.showId ? meta.arg.showId : null
      state.takedownShow.partitionKey = meta.arg.partitionKey ? meta.arg.partitionKey : null
      state.takedownShow.rowKey = meta.arg.rowKey ? meta.arg.rowKey : null
      state.takedownShow.updatedByShowId[meta.arg.showId] = {
        loading: true,
        showId: meta.arg.showId ? meta.arg.showId : null,
        partitionKey: meta.arg.partitionKey ? meta.arg.partitionKey : null,
        rowKey: meta.arg.rowKey ? meta.arg.rowKey : null,
        responseData: null,
        error: null,
      }
      state.takedownShow.responseData = null
      state.takedownShow.error = null
    },
    [postTakedownShow.fulfilled]: (state, { payload }) => {
      //console.debug('payload', payload);
      state.takedownShow.loading = false
      state.takedownShow.responseData = payload
      state.takedownShow.updatedByShowId[state.takedownShow.showId] = {
        ...state.takedownShow.updatedByShowId[state.takedownShow.showId],
        loading: false,
        responseData: payload.data,
      }
    },
    [postTakedownShow.rejected]: (state, { error, payload }) => {
      //console.debug('postTakedownShow.rejected - error', error);
      //console.debug('postTakedownShow.rejected - payload', payload);
      state.takedownShow.loading = false
      state.takedownShow.error = payload
      state.takedownShow.updatedByShowId[state.takedownShow.showId] = {
        ...state.takedownShow.updatedByShowId[state.takedownShow.showId],
        loading: false,
        error: payload,
      }
    },
    [postRestoreShow.pending]: (state, { meta }) => {
      state.restoreShow.loading = true
      state.restoreShow.responseData = null
      state.restoreShow.error = null
      state.restoreShow.showId = meta.arg.showId ? meta.arg.showId : null
      state.restoreShow.partitionKey = meta.arg.partitionKey ? meta.arg.partitionKey : null
      state.restoreShow.rowKey = meta.arg.rowKey ? meta.arg.rowKey : null
      state.restoreShow.updatedByShowId[meta.arg.showId] = {
        loading: true,
        showId: meta.arg.showId ? meta.arg.showId : null,
        partitionKey: meta.arg.partitionKey ? meta.arg.partitionKey : null,
        rowKey: meta.arg.rowKey ? meta.arg.rowKey : null,
        responseData: null,
        error: null,
      }
    },
    [postRestoreShow.fulfilled]: (state, { payload }) => {
      //console.debug('payload', payload);
      state.restoreShow.loading = false
      state.restoreShow.responseData = payload
      state.restoreShow.updatedByShowId[state.restoreShow.showId] = {
        ...state.restoreShow.updatedByShowId[state.restoreShow.showId],
        loading: false,
        responseData: payload.data,
      }
    },
    [postRestoreShow.rejected]: (state, { error, payload }) => {
      state.restoreShow.loading = false
      state.restoreShow.error = payload
      state.restoreShow.updatedByShowId[state.restoreShow.showId] = {
        ...state.restoreShow.updatedByShowId[state.restoreShow.showId],
        loading: false,
        error: payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, toggleModal_updateRssUrl, closeModal_updateRssUrl, setPodcastUpdateData, toggleModal_takedownShow, closeModal_takedownShow, toggleModal_restoreShow, closeModal_restoreShow } = dashboardSlice.actions;
export const selectDashboard = (state) => state.dashboard;
export const dashboardReducer = dashboardSlice.reducer