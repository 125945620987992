import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPodcastSubmit, setRecaptcha, setRssVerifyUrl, postRssValidate } from '../podcastSubmitSlice'
import { selectDashboard, } from '../../dashboard/dashboardSlice'

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator';

import {
  Button,
  Card,
  Form,
  Message,
} from 'semantic-ui-react';

export default function PodcastRssValidate(props) {

  const fromView = props.fromView;
  //console.debug('PodcastRssValidate - fromView', fromView);

  const recaptchaRef = useRef();

  const dispatch = useDispatch()
  const { recaptcha, rssVerification } = useSelector(selectPodcastSubmit);
  const { loading, rssUrlValid, rssFeedUrl, formError, error, } = rssVerification;

  const { modalUpdateRssUrl } = useSelector(selectDashboard);

  const [formState, setFormState] = useState({
    rssFeedUrl: '',
  })

  useEffect(() => {
    if (!loading && !error && (recaptcha && recaptcha.valid && recaptcha.value ) && (rssFeedUrl && rssUrlValid)) {
      dispatch(postRssValidate({recaptchaToken: recaptcha.value, rssFeedUrl}));
    }
  }, [dispatch, loading, error, rssUrlValid, rssFeedUrl, recaptcha])

  const handleChange = (e, { name, value }) => setFormState({ [name]: value ? value : ''});
  //console.debug('formState', formState.rssFeedUrl);

  // Form - Google reCaptcha
  const recaptcha_scriptLoaded = () => {
    //console.debug('recaptcha_scriptLoaded');
  }
  /* const recaptcha_ref_reset = () => {
    console.debug('recaptcha_ref_reset');
    console.debug('recaptcha_ref_reset', recaptchaRef);
    const recaptchaRef = recaptchaRef.current;
    console.debug('recaptcha_ref_reset', recaptchaRef);
    recaptchaRef.reset();
  } */
  const recaptcha_onChange = (value) => {
    //console.debug('Captcha value:', value);
    dispatch(setRecaptcha({value: value}));
  }
  const recaptcha_onError = () => {
    //console.debug('recaptcha_onError');
    const error = {
      type: 'recaptcha',
      message: 'Network error: please try again.'
    };
    dispatch(setRecaptcha({error: error,}));
    //this.recaptcha_ref_reset();
  }
  const recaptcha_onExpired = () => {
    //console.debug('recaptcha_onExpired');
    const error = {
      type: 'recaptcha',
      message: 'Expired: please try again.'
    };
    dispatch(setRecaptcha({error: error,}));
    //recaptcha_ref_reset();
  }

  const handle_ihrVerifyRssFeed = () => {    
    // remove all white spaces
    const parsedRssFeedUrl = formState.rssFeedUrl.replace(/\s/g, "");
    // test for valid URL regex
    const validateRssFeedUrl = validator.isURL(parsedRssFeedUrl, {
      protocols: ['http', 'https', ],
      require_tld: true,
      require_protocol: true,
      require_host: true,
      require_valid_protocol: true,
      allow_underscores: true,
      host_whitelist: false,
      host_blacklist: false,
      allow_trailing_dot: false,
      allow_protocol_relative_urls: false,
      disallow_auth: false
    });
    //console.debug('validateRssFeedUrl', validateRssFeedUrl);
    if (validateRssFeedUrl === true) {
      dispatch(setRssVerifyUrl({rssFeedUrl: parsedRssFeedUrl, }));
    } else {
      //this.recaptcha_ref_reset();
      const error = {
        type: 'validate-url',
        message: 'Please provide a valid RSS Feed URL.'
      };
      dispatch(setRssVerifyUrl({error, rssFeedUrl: parsedRssFeedUrl,}));
    }
  }

  const submitButtonDisabled = !recaptcha.valid ? true : loading ? true : (formState.rssFeedUrl === null || formState.rssFeedUrl === '' || formState.rssFeedUrl.length === 0) ? true : (formError && formState.rssFeedUrl === rssFeedUrl) ? true : (fromView === 'ModalUpdateRssUrl' && modalUpdateRssUrl.podcastSubmitData.rssFeedUrl === formState.rssFeedUrl) ? true : false

  return (
    <>
      <div className={'view-activate'}>
        <Card.Header>Verify Your Podcast</Card.Header>
        <Card.Description>
          <p>Enter your Podcast RSS Feed URL below and verify to continue.</p>
          <Form error={formError} success={rssUrlValid}>
            <Form.Field required>
              <Form.Input type="text" min="5"
                placeholder='Enter RSS Feed URL...'
                name='rssFeedUrl'
                value={formState.rssFeedUrl}
                onChange={handleChange}
                error={formError}
                //disabled={rssUrlValid}
                readOnly={rssUrlValid}
              />
              {!loading && formError && error && error.type === 'validate-url' ?
                <Message error size={'tiny'} header={error.message} />
              :
                null
              }
              {!loading && formError && error && error.type === 'validate-rss' ?
                <Message error size={'tiny'} header={error.message} 
                  //content={error.rawError}
                />
              :
                null
              }
            </Form.Field>
            <Form.Field required className={'recaptcha-field'}>
              <ReCAPTCHA ref={recaptchaRef} tabindex={2} isolated={'true'} size={'normal'} theme={'light'}
                sitekey={recaptcha.sitekey}
                asyncScriptOnLoad={recaptcha_scriptLoaded}
                onChange={recaptcha_onChange}
                onErrored={recaptcha_onError}
                onExpired={recaptcha_onExpired}
              />
              {!loading && formError && error && error.type === 'recaptcha' ?
                <Message error size={'tiny'} header={error.message} />
              :
                null
              }
            </Form.Field>
          </Form>

          {fromView === 'ModalUpdateRssUrl' && modalUpdateRssUrl.podcastSubmitData.rssFeedUrl === formState.rssFeedUrl ?
            <Message error size={'tiny'} header={'Please provide a new RSS Feed URL'} />
          :
            null  
          }

          <Button primary fluid
            loading={loading}
            disabled={submitButtonDisabled}
            onClick={()=>{handle_ihrVerifyRssFeed()}}
          >
            Verify Podcast RSS
          </Button>

          <div className={'extra'}>
            <p>If your podcast is already on iHeartRadio and you need to make a change to your iHeartRadio podcast page, please access Your Dashboard.</p>
            <Button as={Link} secondary fluid
              to={'/dashboard'}
            >
              Your Dashboard
            </Button>
          </div>
        </Card.Description>
      </div>
    </>
  )
}