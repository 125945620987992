// App
// ----------------------
import React, { useEffect, } from 'react';
// routing
import { Routes, Route, } from "react-router-dom";
import { NoMatch } from './router-no-match.js'; // 404 - No Match
import { PrivateRouteAuthIhrWebSdk, OwnerVerifyRoute, } from './routes'; // custom routes

// authentication
// auth/ihr-web-sdk
import AuthWrapperIhrWebSdk from './features/auth/ihr-web-sdk/auth-wrapper/auth-wrapper';
// layouts
// Public
import PublicDefaultLayout from './layouts/public/default-layout/default-layout'; // Default Layout
// components
import Homepage from './features/public/homepage/homepage'; // public marketing homepage
// Private
import DashboardDefaultLayout from './layouts/private/dashboard/default/dashboard-default'; // layout - private dashboard
import DashboardHome from './features/dashboard/home/home'; // feature - private dashboard home
// npm
import ScriptTag from '@gumgum/react-script-tag';
import TagManager from '@sooro-io/react-gtm-module'
// libraries
import 'semantic-ui-less/semantic.less';
// styles
//import './App.css'; //App level css import
//import './App.scss'; //App level scss import
//import stylesCss from './App-css-module.module.css'; //App level-scoped css import
//import stylesScss from './App-scss-module.module.scss'; //App level-scoped scss import

const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
//console.debug('REACT_APP_ENV_MODE', REACT_APP_ENV_MODE);
const REACT_APP_ANALYTICS_GTM = process.env.REACT_APP_ANALYTICS_GTM;
//console.debug('REACT_APP_ANALYTICS_GTM', REACT_APP_ANALYTICS_GTM);

function App() {

  useEffect(() => {
    //if (REACT_APP_ENV_MODE !== 'development') {
      // Adobe Analytics
      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        // eslint-disable-next-line
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
      window.analyticsData = {
        "events": {
          "active": {
            "device": {
              "host": "webapp.US"
            },
            "querystring": {
              "sc": getParameterByName('sc'),
              "campid": getParameterByName('campid'),
              "cid": getParameterByName('cid'),
              "keyid": getParameterByName('keyid'),
              "pname": getParameterByName('pname')
            },
            "pageName": "microsite_home",
            "view": {
              "stationCallLetter": "ihm-ip",
              "stationFormat": "IHEARTMUSIC",
              "stationMarket": "CORPORATE",
              "stationMicrosite": "Podcasters"
            }
          }
        }
      };
      // init comScore tracking
      // comScore requires window variables set before loading the script
      // when the component mounts, we will attach the comscore window variable
      if (window._comscore) {
        //console.debug('componentDidMount - window._comscore is true');
        const _comscore = window._comscore || [];
        _comscore.push({ c1: "2", c2: "6036262"});
      } else {
        //console.debug('componentDidMount - no _comscore');
        window._comscore = [];
        //console.debug('componentDidMount - window._comscore', window._comscore);
        window._comscore.push({ c1: "2", c2: "6036262"});
        //console.debug('componentDidMount - window._comscore', window._comscore);
      }
      if (REACT_APP_ANALYTICS_GTM) {
        const tagManagerArgs = {
          gtmId: REACT_APP_ANALYTICS_GTM,
        };
        TagManager.initialize(tagManagerArgs);
      }
   //}
  }, []);

  // Adobe Analytics load
  const handle_adobeAnalyticsLoad = () => {
    //console.debug('handle_adobeAnalyticsLoad', window._satellite);
    window._satellite.pageBottom();
  }
  const handle_adobeAnalyticsError = () => {
    console.debug('adobeAnalyticsError');
  }
  // comscore load
  const handle_comscoreLoad = () => {
    //console.debug('handle_comscoreLoad');
    // init comScore tracking
    // comScore requires window variables set before loading the script
    // when the component mounts, we will attach the comscore window variable
    if (window._comscore) {
      //console.debug('window._comscore is true');
      const _comscore = window._comscore || [];
      _comscore.push({ c1: "2", c2: "6036262"});
    } else {
      //console.debug('no _comscore');
      window._comscore = [];
      //console.debug('window._comscore', window._comscore);
      window._comscore.push({ c1: "2", c2: "6036262"});
      //console.debug('window._comscore', window._comscore);
    }
  }
  const handle_comscoreError = () => {
    console.debug('comscoreError');
  }

  return (
    <div className="App">
      <AuthWrapperIhrWebSdk>
        <Routes>
          <Route path={'/*'} element={<PublicDefaultLayout component={Homepage} />} />
          <Route path={'/dashboard/'} element={<PrivateRouteAuthIhrWebSdk component={PublicDefaultLayout} redirectTo={'/'} />}>
            <Route index element={<DashboardDefaultLayout component={DashboardHome} />} />
          </Route>
          <Route path={'/verify/:submissionUUID'} element={<PublicDefaultLayout component={OwnerVerifyRoute} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </AuthWrapperIhrWebSdk>
      {REACT_APP_ENV_MODE !== 'development' ?
        <>
          <ScriptTag type="text/javascript"
            src={REACT_APP_ENV_MODE === 'production' ?
              'https://assets.adobedtm.com/98de9919e92a38033805f9f035c43198533e4380/satelliteLib-38c7b1e4b1bb3e0378e82d10601662aebfb04eda.js'
            :
              'https://assets.adobedtm.com/dba7b4ad2c8b/bb53a2b65c2f/launch-a86e88c9f3a6-staging.min.js'
            }
            onLoad={handle_adobeAnalyticsLoad}
            onError={handle_adobeAnalyticsError}
          />
          <ScriptTag type="text/javascript" src="https://sb.scorecardresearch.com/beacon.js" onLoad={handle_comscoreLoad} onError={handle_comscoreError} />
        </>
      :
        null
      }
    </div>
  );
}

export default App;
