// Public - Default Layout

// ----------------------
// IMPORTS
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { findDOMNode } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuthIhrWebSdk, fetchLoginIfNeeded, fetchLogout } from '../../../features/auth/ihr-web-sdk/authSlice'; // auth/ihr-web-sdk
import { selectPublic, 
  //uiStateSetFixed 
} from '../../../features/public/publicSlice' // public

// NPM
import { Outlet, Link } from "react-router-dom";
//import Helmet from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { disableBodyScroll, enableBodyScroll, 
  //clearAllBodyScrollLocks 
} from 'body-scroll-lock';
import { useResizeDetector } from 'react-resize-detector';
import {
  Sidebar,
  Menu,
  Button,
  Container,
  Segment,
  Image,
  Icon,
  Divider,
  Dropdown,
} from 'semantic-ui-react';

import { 
  scrollWithOffset, 
 //generateMediaServerUrl 
} from '../../utils' // public

// Components
// navigation menu items
import { ExternalLink, } from './navigation/menu-item/menu-items';
import Footer from './footer/footer';

// get temp site data
import { SiteData } from './site-data';

// styles
import styles from './default-layout.module.scss';

// images
// iHeart Logo - Menu
import svg_iHR_Logo_FullColor from '../../images/iheartradio-logo-fullcolor.svg';
import svg_iHR_Logo_White from '../../images/iheartradio-logo-white.svg';

//const iHR_Logo_FullColor = generateMediaServerUrl(svg_iHR_Logo_FullColor)
//const iHR_Logo_White = generateMediaServerUrl(svg_iHR_Logo_White)

/* ---------------------- */


export default function PublicDefaultLayout (props) {
  const { children, component: Component } = props;
  const refMobileSidebarMenuTarget = useRef(null);
  const refPriorityNavContainer = useRef();
  const priorityNavRefs = useMemo(() => [], []);
  //let TargetElementScrollBlock = null;
  //const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const dispatch = useDispatch();
  const { init, authentication, profile } = useSelector(selectAuthIhrWebSdk);
  const { uiState, } = useSelector(selectPublic);

  let [visible, setVisible] = useState(false);
  //let [fixed, setFixed] = useState(false);
  let [priorityNav, setPriorityNav] = useState({
    initialNavItems: SiteData.navigation.items,
    navItems: SiteData.navigation.items,
    overflowNavItems: [],
    pnContainerWidth: 0,
    pnTotalContentWidth: 0,
    pnCurrentWidth: 0,
    calculations: {},
    loading: false,
    init: false,
  });

  /* // Main Navbar - Fixed Menu controls
  const hideFixedMenu = () => dispatch(uiStateSetFixed(null));
  const showFixedMenu = () => dispatch(uiStateSetFixed("top")); */
  // Mobile Sidebar Menu - Sidebar Toggle Behavior
  const handleSidebarButtonClick = () => {
    //console.debug('PublicDefaultLayout - handleSidebarButtonClick - visible', visible);
    setVisible(!visible);
  }
  // Mobile Sidebar Menu - Sidebar Hide Behavior
  const handleSidebarHide = () => {
    //console.debug('PublicDefaultLayout - handleSidebarHide - visible', visible);
    setVisible(false);
    enableBodyScroll(refMobileSidebarMenuTarget.current);
  }
  // Mobile Sidebar Menu - Scroll Locking Behavior
  if (refMobileSidebarMenuTarget.current && visible === true) {
    //console.debug('disableBodyScroll');
    disableBodyScroll(refMobileSidebarMenuTarget.current);
  } else if (refMobileSidebarMenuTarget.current) {
    //console.debug('enableBodyScroll');
    enableBodyScroll(refMobileSidebarMenuTarget.current);
  }
  // Priority Nav Menu - Resize Update Behavior
  const onResize = useCallback((width, height) => {
    //console.debug('onResize', {width, height})
    //console.debug('priorityNav.pnCurrentWidth', priorityNav.pnCurrentWidth)
    const priorityNavUpdate = (width, height, priorityNav) => {
      const { initialNavItems, overflowNavItems, loading } = priorityNav;
      if (!loading) {
        // test for dynamic refs generated
        if (priorityNavRefs.length !== 0) {
          let cloneNavItems = JSON.parse(JSON.stringify(initialNavItems));
          let newNavItems = [];
          let newOverflowNavItems = [];
          let newContainerWidth = width; // get container width
          let newTotalContentWidth = 0;
          const addNavItem = (index) => {
            newNavItems = [...newNavItems, cloneNavItems[index]];
          }
          const removeNavItem = (prevIndex, prevNavItem) => {
            newOverflowNavItems = [...newOverflowNavItems, cloneNavItems[prevIndex]];
            newNavItems = newNavItems.length !== 0 && newNavItems.filter(navItem => navItem.key !== prevNavItem.key);
          }
          const measureElement = element => {
            const DOMNode = findDOMNode(element);
            return {
              width: DOMNode.offsetWidth,
              height: DOMNode.offsetHeight,
            };
          }
          const newInitialNavItems = cloneNavItems.map((navItem, i) => {
            let pnRef = priorityNavRefs[i];
            if (typeof pnRef !== 'undefined' || pnRef !== null || navItem.width) {
              // store refWidth in State
              // use this to avoid re-rendering each item to re-measure again
              if (!navItem.width) {
                let refWidth = pnRef !== null ? measureElement(pnRef).width : navItem.width;
                navItem.width = refWidth;
              }
              let calcAddWidth = newTotalContentWidth + navItem.width;
              newTotalContentWidth = calcAddWidth;            
              if (newTotalContentWidth > newContainerWidth) {
                let prevIndex = i;
                let prevNavItem = cloneNavItems[i];
                removeNavItem(prevIndex, prevNavItem);
              } else {
                addNavItem(i);
              }
            }
            return navItem
          });
          // get last item to add to overflow if overflow has items
          // only if the overflow menu isn't already available
          if (overflowNavItems.length === 0 && newOverflowNavItems.length !== 0) {
            let newLastItemIndex = newNavItems.length - 1;
            let newLastItem = newNavItems[newLastItemIndex];
            newOverflowNavItems = [newLastItem, ...newOverflowNavItems];
            newNavItems.splice(newLastItemIndex, 1);
          }
          setPriorityNav(prevState => ({
            ...prevState,
            init: true,
            loading: false,
            pnCurrentWidth: width,
            initialNavItems: newInitialNavItems,
            navItems: newNavItems,
            overflowNavItems: newOverflowNavItems,
            pnContainerWidth: newContainerWidth,
            pnTotalContentWidth: newTotalContentWidth,
          }))
        }
      }
    }

    // on resize logic
    if (typeof width !== 'undefined' && (priorityNav.pnCurrentWidth !== width) && (width > 350)) {
      priorityNavUpdate(width, height, priorityNav)
    }
  }, [priorityNav, priorityNavRefs, setPriorityNav, ]);
  // eslint-disable-next-line
  const { width, height } = useResizeDetector({
    targetRef: refPriorityNavContainer,
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
    onResize
  });
  //console.debug('refPriorityNavContainer', refPriorityNavContainer)

  //console.debug('uiState.fixed', uiState.fixed)

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>Public Layout</title>
      </Helmet> */}
      <div className={styles.layout_default}>
        <Sidebar.Pushable>
          <Sidebar id={'mobile-sidebar'} animation={'overlay'} visible={visible} onHide={()=>handleSidebarHide()} ref={refMobileSidebarMenuTarget}>
            <Menu id={'main-mobile-nav'} vertical secondary borderless fluid>
              <Menu.Item header >
                <Image as={'a'} fluid 
                  src={svg_iHR_Logo_White}
                  alt={'iHeartRadio Logo'}
                  //src={svg_iHR_Logo_White} 
                  href={'https://www.iheart.com/'}
                  target={'_self'}
                  aria-label={'iHeartRadio Link'}
                />
                {/* <Button as={'a'} fluid color={'red'}
                  href={'https://www.iheart.com/upgrade?upsellFrom=54'}
                  target={'_self'}
                >
                  Upgrade Now
                </Button> */}
              </Menu.Item>
              {/* <Menu.Item>
                <Button fluid color={'red'}>Upgrade Now</Button>
              </Menu.Item> */}
              {SiteData && SiteData.navigation.items.length !== 0 ?
                (SiteData.navigation.items.map((navItem, i) => (
                  ExternalLink({ key: i, navItem: navItem, })
                )))
              :
                null
              }
              <Divider />
              <Menu.Item className={'nav-footer-signin'}>
                {!init.loading && init.isInitialized === true && !authentication.loading && !profile.loading && authentication.loggedIn && profile.profileData !== null && authentication.loginData.avatar ?
                  <div>
                    <div className={'nav-footer-login'} onClick={()=>dispatch(fetchLogout())}>
                      <Image avatar src={authentication.loginData.avatar} alt={'Your iHeartRadio Account avatar'} />
                      <span>{profile.profileData.name}</span>
                    </div>
                    <div className={'nav-footer-signup'}>
                      <div>
                        <Button basic inverted attached='left' onClick={()=>dispatch(fetchLogout())}>
                          Log Out
                        </Button>
                        <Button basic inverted attached='right' as={'a'} to={'https://www.iheart.com/my/settings/'}>
                          Setings
                        </Button>
                      </div>
                      
                    </div>
                    
                  </div>
                :
                  <div>
                    <div className={'nav-footer-login'} onClick={()=>dispatch(fetchLoginIfNeeded())}>
                      <Icon name='user circle' size={'large'} />
                      <span>Login</span>
                    </div>
                    <div className={'nav-footer-signup'}>
                      <p>Sign Up</p>
                    </div>
                  </div>
                }
              </Menu.Item>
              <Menu.Item>
                <Button as={'a'} fluid basic inverted to={'https://iheart.com'} target={'_blank'}>
                  Get The App
                </Button>
              </Menu.Item>
            </Menu>
          </Sidebar>
          <Sidebar.Pusher dimmed={visible} onClick={()=> visible ? handleSidebarHide() : null}>
            {isNotMobile ?
              <Segment id={'main-nav-container'} basic vertical textAlign={'center'}>
                <Menu id={'main-nav'} size={'large'} fixed={'top'} pointing borderless className={uiState.fixed ? 'nav-menu-scroll-enabled' : ''}>
                  <Container>
                    <Menu.Item header as={Link} to={'/'} onClick={(el) => scrollWithOffset(el, 0)}>
                      <Image fluid width={120} height={28}
                        src={svg_iHR_Logo_FullColor}
                        alt={'iHeartRadio Logo'}
                        //src={svg_iHR_Logo_FullColor} 
                        className={'menu-logo-image'} 
                      />
                    </Menu.Item>
                    <div position={'left'} className={'left menu menu-items-fold-wrapper'} ref={refPriorityNavContainer}>
                      {priorityNav.initialNavItems && priorityNav.initialNavItems.length !== 0 ?
                        (priorityNav.initialNavItems.map((navItem, i) => (
                          priorityNav.navItems && priorityNav.navItems.length !== 0 && priorityNav.navItems[i] && ExternalLink({ key: i, navItem: navItem, source: 'main-nav', priorityNavRefs: priorityNavRefs, })
                        )))
                      :
                        null
                      }
                    </div>
                    {priorityNav.overflowNavItems && priorityNav.overflowNavItems.length !== 0 ?
                      <Dropdown item simple text='More' className={'menu-more-dropdown'} aria-label={'expand options'}>
                        <Dropdown.Menu>
                          {priorityNav.overflowNavItems.map((dropdownItem, i) => (
                            <Dropdown.Item key={i} as={'a'}
                              href={dropdownItem.value}
                              target={'_self'}
                              aria-label={dropdownItem.ariaLabel ? dropdownItem.ariaLabel : (dropdownItem.text+' Navigation Link')}
                            >
                              {dropdownItem.text}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    :
                      null
                    }
                    <Menu.Menu position='right' className={'menu-functions-wrapper'}>
                      {!init.loading && init.isInitialized === true && !authentication.loading && !profile.loading && authentication.loggedIn && profile.profileData !== null && authentication.loginData.avatar ?
                        <Dropdown item simple compact
                          trigger={
                            <div>
                              <Image avatar src={authentication.loginData.avatar} alt={'Your iHeartRadio Account avatar'} />
                              <span>{profile.profileData.name && profile.profileData.name.split('@')[0]}</span>
                            </div>
                          }
                          aria-label={'expand options'}
                        >
                          <Dropdown.Menu>
                            {SiteData && SiteData.navigation.dropdownItems.length !== 0 ?
                              (SiteData.navigation.dropdownItems.map((dropdownItem, i) => (
                                dropdownItem.key === 'log_out' ? 
                                  <Dropdown.Item key={i} onClick={()=>dispatch(fetchLogout())}>
                                    {dropdownItem.text}
                                  </Dropdown.Item>
                                :
                                  <Dropdown.Item key={i} as={'a'} href={dropdownItem.value} target={'_self'} aria-label={dropdownItem.ariaLabel ? dropdownItem.ariaLabel : (dropdownItem.text+' Navigation Link')}>
                                    {dropdownItem.text}
                                  </Dropdown.Item>
                                )
                              ))
                            :
                              null
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      :
                        <Menu.Item>
                          <Icon link name='user circle' size={'large'} onClick={()=>dispatch(fetchLoginIfNeeded())} />
                        </Menu.Item>
                      }
                    </Menu.Menu>
                  </Container>
                </Menu>
              </Segment>
            :
              <Menu id={'main-navbar-mobile'} fixed={'top'} size={'large'} secondary borderless className={uiState.fixed ? 'nav-menu-scroll-enabled': ''}>
                <Container>
                  <Menu.Item position={'left'} className={'mobile-navbar-menu-button'} onClick={()=>handleSidebarButtonClick()}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item header as={Link} to={'/'} onClick={(el) => scrollWithOffset(el, 0)}>
                    <Image fluid src={svg_iHR_Logo_FullColor} alt={'iHeartRadio Logo'} className={'menu-logo-image'} />
                  </Menu.Item>
                  <Menu.Item position={'right'} className={'mobile-navbar-login-button'}>
                    {!init.loading && init.isInitialized === true && !authentication.loading && !profile.loading && authentication.loggedIn && profile.profileData !== null && authentication.loginData.avatar ?
                      <div>
                        <Image avatar src={authentication.loginData.avatar} alt={'Your iHeartRadio Account avatar'} />
                      </div>
                    :
                      <Icon link name='user circle' size={'large'} onClick={()=>dispatch(fetchLoginIfNeeded())} />
                    }
                  </Menu.Item>
                </Container>
              </Menu>
            }
            <main id={'main-content-container'}>
              {/* <Visibility once={false} offset={[0,80]} onTopPassed={()=>showFixedMenu()} onTopPassedReverse={()=>hideFixedMenu()}> */}
                {children ? 
                  children 
                : Component ? 
                  <Component />
                :
                  <Outlet />
                }
              {/* </Visibility> */}
            </main>
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </React.Fragment>
  )
}
