// Public - Marketing - Homepage - Homepage Hero
// ----------------------
import React from 'react'
import { convertSvgToImgSrc } from '../../../layouts/utils';
import {
  Segment,
  Container,
  Grid,
  Header,
  Image,
  Icon,
} from "semantic-ui-react";

// styles
import styles from './homepage-benefits.module.scss';
// images
const svg_icon_podcast = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,20a1.18,1.18,0,0,1,2.36,0h2.36a1.19,1.19,0,0,1,1.19,1.19V23H6.41V21.22A1.19,1.19,0,0,1,7.6,20Z" style="fill:#8b6ae9"/><path d="M14.46,1.55a5.81,5.81,0,0,1,4.37,6.93l-1.5,5.67A5.78,5.78,0,0,1,10.11,18a6,6,0,0,1-4.53-5.13l3.25.87a1,1,0,0,0,.52-1.93l-3.56-.95L6.3,8.9l3.56,1a1,1,0,1,0,.52-1.93L6.81,7l.43-1.59A2.47,2.47,0,0,1,7.35,5l3.38.91A1,1,0,1,0,11.25,4L8.4,3.23A6,6,0,0,1,14.46,1.55Z" style="fill:#8b6ae9"/></svg>`;
const imgSrcIconPodcast = convertSvgToImgSrc(svg_icon_podcast);


export default function HomepageBenefits() {
  return (
    <>
      <section className={styles.component_homepage_benefits}>
        <Segment basic id={'how-to'}>
          <Container className={'main-container'}>
            <Grid columns={3} verticalAlign={'middle'}>
              <Grid.Row textAlign={'center'}>
                <Grid.Column className={'first-content-column'}>
                  <Segment basic>
                    <Header as='h2' icon size={'small'}>
                      <Icon name='user outline' />
                      Login iHeartRadio Account
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column className={'second-content-column'}>
                  <Segment basic>
                    <Header as='h2' icon size={'small'}>
                      <Icon name='rss' />
                      Enter Podcast RSS Feed URL
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column className={'third-content-column'}>
                  <Segment basic>
                    <Header as='h2' icon size={'small'}>
                      <Image width={30} height={30} src={imgSrcIconPodcast} alt={'Microphone icon'} />
                      Listen on iHeartRadio
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </section>
    </>
  )
}