// Public - Marketing - Homepage - Homepage Podcast Submit - Success View
// ----------------------
import React from 'react'
import { useSelector, } from 'react-redux'
import { selectPodcastSubmit, } from '../podcastSubmitSlice'

import { Navigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion"

import {
  Segment,
  //Card,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import DefaultSuccessView from './default-success-view'
import Code409View from './code-409-view'
import OwnerVerifyView from './owner-verify-view'

// styles
import styles from './success-view.module.scss';

export default function SuccessView() {
  const { view, submitPodcast } = useSelector(selectPodcastSubmit);
  const { loading, submitResponseData, } = submitPodcast;

  const location = useLocation();

  const cardVariants = {
    offscreen: {
      x: 300,
      scale: 0.8, 
      opacity: 0
    },
    onscreen: {
      x: 0,
      scale: 1, 
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    },
    exit: {
      x: -300,
      scale: 0.8, 
      opacity: 0
    },
  };

  return (
    <>
      <section className={styles.podcast_success_view}>
        <Segment basic id="success-view" className={'hero-content-wrapper view-complete'}>
          <motion.div className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            exit="exit"
            viewport={{ once: true, amount: 0.8 }}
            //layout
          >
            <motion.div layout className="card-wrapper" variants={cardVariants}>
              {view === 'podcast-submit-success' ?
                (!loading && submitResponseData !== null ?
                  (typeof submitResponseData.ownerVerify !== 'undefined' && submitResponseData.ownerVerify.send === true ?
                    <OwnerVerifyView />
                  : submitResponseData.code === 409 ?
                    <Code409View />
                  : submitResponseData.showId ?
                    <DefaultSuccessView />
                  : 
                    null
                  )
                : 
                  <Segment basic style={{ height: '300px', }}>
                    <Dimmer active inverted><Loader content='Loading' /></Dimmer>
                  </Segment>
                )
              :
                <Navigate to={'/'} state={{ from: location }} replace />
              }
            </motion.div>
          </motion.div>
        </Segment>
      </section>
    </>
  )
}