// Private - Dashboard - Podcasts Table - Modal - Takedown Show
// ----------------------
import React, { useState,} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, closeModal_takedownShow, postTakedownShow, getPodcastSubmissions } from '../../../dashboardSlice'

// Semantic UI
import {
  Modal,
  Segment,
  Button,
  Message,
  Form,
  //Label,
  Item,
} from 'semantic-ui-react';

// styles
//import styles from './modal-update-rss-url.module.scss';

export default function ModalTakedownShow(props) {
  //console.debug('DashboardModalUpdateRssUrl - props', props);

  const dispatch = useDispatch();

  const { modalTakedownShow, takedownShow, podcastSubmissions } = useSelector(selectDashboard);  

  const [formState, setFormState] = useState({
    confirmed: false,
    showName: '',
  });
  const handleChange = (e, { name, value }) => setFormState({ 
    confirmed: value === modalTakedownShow.showName ? true : false,
    [name]: value ? value : ''
  });

  const handle_closeModal = () => {
    //console.debug('handle_closeModal');
    dispatch(closeModal_takedownShow());
    if (takedownShow.responseData !== null) {
      //dispatch(getPodcastSubmissions());
      let continuationToken = null
      if (podcastSubmissions.pageNumber-1 > 0) {
        continuationToken = podcastSubmissions.psByLimitPage[podcastSubmissions.limit+'-'+(podcastSubmissions.pageNumber-1)].continuationToken
      }
      dispatch(getPodcastSubmissions({refresh: true, continuationToken}));
    }
  }

  const handle_takedownShow = () => {
    //console.debug('handle_takedownShow');
    dispatch(postTakedownShow({
      showId: modalTakedownShow.showId,
      partitionKey: modalTakedownShow.partitionKey,
      rowKey: modalTakedownShow.rowKey,
    }));
  }

  return (
    <>
      <Modal size={'small'} id={'modal-takedown-show'} 
        //className={styles.modal_takedown_show}
        open={modalTakedownShow.visible}
        onClose={()=> handle_closeModal()}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          Takedown Show
          <br/>
          <p>Disable your podcast on iHeartRadio services</p>
          {/* <Label content={'Show Title'} detail={modalTakedownShow.showName} /> */}
        </Modal.Header>
        <Modal.Content scrolling>
          <Segment basic textAlign={'center'} className={'modal-content-body'}>
            {modalTakedownShow.podcastSubmitData ?
              <Item.Group>
                <Item>
                  <Item.Image as={'a'} href={modalTakedownShow.podcastSubmitData.ihrUrl} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'} size='tiny' src={modalTakedownShow.podcastSubmitData.rssFeedDataSnapshot.image} alt={'Podcast Show Image'} />
                  <Item.Content verticalAlign='middle'>
                    <Item.Header>{modalTakedownShow.showName}</Item.Header>
                    <Item.Meta>
                      <a href={modalTakedownShow.podcastSubmitData.ihrUrl} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                        {modalTakedownShow.podcastSubmitData.ihrUrl}
                      </a>
                    </Item.Meta>
                  </Item.Content>
                </Item>
              </Item.Group>
            :
              null
            }
            {!takedownShow.loading && takedownShow.responseData ?
              <div className={'success-view'}>
                <h2>Removal of your show from iHeartRadio is in progress.</h2>
                {/* <p>{takedownShow.responseData.message ? takedownShow.responseData.message : 'Your show is no longer available on iHeartRadio.'}</p> */}
                <p>Deactivation should occur within 1 hour.</p>
                <p>Your podcast can be restored through the 'Restore' function in your dashboard.</p>
                <Button primary content={'Close'}
                  onClick={()=>handle_closeModal()}
                />
              </div>
            :
              <div className={'form-view'}>
                {/* <Header style={{ marginBottom: '25px'}}>Take down your podcast show on iHeartRadio</Header> */}
                <Form error={takedownShow.error ? true : false} success={formState.confirmed}>
                  <Form.Field required>
                    <Form.Input type="text" min="3"
                      label={'Confirm show takedown by entering your show title:'}
                      placeholder='Confirm Show Title...'
                      name='showName'
                      value={formState.showName}
                      onChange={handleChange}
                      error={takedownShow.error ? true : false}
                      readOnly={takedownShow.error ? true : false}
                      //disabled={rssUrlValid}
                    />
                    {!takedownShow.loading && takedownShow.error ?
                      <Message error size={'tiny'} header={'Error'} content={takedownShow.error?.rawError?.error ? takedownShow.error.rawError.error : null} />
                    :
                      null
                    }
                  </Form.Field>
                </Form>
                <p>By entering the show title and clicking the button below, you acknowledge that your podcast will be unavailable on iHeartRadio services.</p>
                <Button primary
                  loading={takedownShow.loading}
                  disabled={(!formState.confirmed || takedownShow.loading) || (formState.showName !== modalTakedownShow.showName) || (!takedownShow.loading && takedownShow.error)}
                  onClick={()=>{handle_takedownShow()}}
                >
                  Takedown Show
                </Button>
              </div>
            }
          </Segment>
        </Modal.Content>
      </Modal>
    </>
  )
}