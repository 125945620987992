// Public - Marketing - Homepage - Homepage Hero
// ----------------------
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPublic, fetchHeroPodcastCategoryIfNeeded } from '../../../features/public/publicSlice'
import { selectAuthIhrWebSdk } from '../../../features/auth/ihr-web-sdk/authSlice'
// routing
import { Link, } from "react-router-dom";
// smoothscroll
import { HashLink, } from 'react-router-hash-link';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useInView } from 'react-intersection-observer';
// Semantic UI
import {
  Segment,
  Container,
  Grid,
  Button,
} from "semantic-ui-react";

import { scrollWithOffset } from '../../../layouts/utils';

// styles
import styles from './homepage-hero.module.scss';


export default function HomepageHero() {
  const dispatch = useDispatch()
  const { heroPodcastsCategory, } = useSelector(selectPublic);
  const { authentication, } = useSelector(selectAuthIhrWebSdk);

  const [particlesState, setParticlesState] = useState({
    particlesContainer: null
  })

  useEffect(() => {
    if (!heroPodcastsCategory.loading && heroPodcastsCategory.categoryData === null && !heroPodcastsCategory.error) {
      dispatch(fetchHeroPodcastCategoryIfNeeded({categoryID: 132, limit: 10}))
    }
  }, [dispatch, heroPodcastsCategory,])

  const particlesInit = useCallback(async (engine) => {
    //console.log('particlesInit - engine', engine);
    await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async (container) => {
    //console.log('particlesLoaded - container', container);
    setParticlesState(prevState => ({
      ...prevState,
      particlesContainer: container,
    }))
  }, []);
  //console.debug('HomepageHero - particlesState.particlesContainer', particlesState.particlesContainer);
  //console.debug('HomepageHero - uiState', uiState);

  // eslint-disable-next-line
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    initialInView: true, //Set the initial value of the inView boolean. This can be used if you expect the element to be in the viewport to start with, and you want to trigger something when it leaves.
    onChange: (inView, entry) => {
      //console.debug('useInView - onChange - inView', {inView,entry});
      
      if (particlesState.particlesContainer !== null) {
        if (inView === false) {
          particlesState.particlesContainer._paused !== true && particlesState.particlesContainer.pause();
        } else {
          particlesState.particlesContainer._paused !== false && particlesState.particlesContainer.play();
        }
      }
      //console.debug('useInView - onChange - particlesState.particlesContainer', particlesState.particlesContainer);
    }
  });
  
  return (
    <>
      <section className={styles.component_homepage_hero} ref={ref}>
        <Segment basic id={'overview'}>
          {!heroPodcastsCategory.loading && !heroPodcastsCategory.error && heroPodcastsCategory.particleData !== null ?
            <>
              <Particles id={'tsparticles'} className={'particle-hero'} canvasClassName={'particle-hero-canvas'}
                width={'100%'}
                height={'100%'}
                init={particlesInit}
                loaded={particlesLoaded}
                options={heroPodcastsCategory.particleData}
              />
            </>
          :
            null
          }
          <Container className={'main-container'}>
            <Grid stackable columns={'equal'} verticalAlign={'middle'}>
              <Grid.Row>
                <Grid.Column className={'left-content-column'}>
                  <Segment basic>
                    <h2>Join the World's Largest Podcast Network</h2>
                    <Button as={HashLink} primary
                      to={'#submit-podcast'}
                      scroll={el => scrollWithOffset(el, 0)}
                    >
                      Submit Your Podcast
                    </Button>
                    <Button as={Link} secondary
                      to={'/dashboard'}
                      disabled={!authentication.loggedIn}
                      loading={authentication.loading}
                      //onClick={()=>handle_ihrLogin()}
                    >
                      Your Dashboard
                    </Button>
                    {/* <Button onClick={()=>particlesState.particlesContainer.pause()}>
                      Pause
                    </Button> */}
                  </Segment>
                </Grid.Column>
                <Grid.Column only={'computer'}></Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </section>
    </>
  )
}