// Private - Dashboard - Home
// ----------------------
import React, { 
  //useState, 
  useEffect, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, getPodcastSubmissions, resendOwnerVerification, toggleModal_restoreShow, } from '../../dashboardSlice'
import { selectAuthIhrWebSdk } from '../../../auth/ihr-web-sdk/authSlice';

import PodcastExpanded from './podcast-expanded/podcast-expanded';
import ModalUpdateRssUrl from './modals/modal-update-rss-url'
import ModalTakedownShow from './modals/modal-takedown-show'
import ModalRestoreShow from './modals/modal-restore-show'

import { HashLink } from 'react-router-hash-link';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import {
  Segment,
  Container,
  Image,
  Popup,
  Label,
  Button,
  Message,
  Loader,
} from "semantic-ui-react";

// styles
import styles from './podcasts-table.module.scss';

// table config
const customStyles = {
  rows: {
    style: {
      minHeight: '70px', // override the row height
    },
  },
};
const CustomLoader = () => {
  return (
    <div style={{ padding: '100px' }}>
      <Loader active content='Loading...' />
    </div>
  )
}

export default function PodcastsTable() {
  const dispatch = useDispatch();
  const { authentication } = useSelector(selectAuthIhrWebSdk);
  const { podcastSubmissions, resendOwnerVerify, modalUpdateRssUrl, modalTakedownShow, modalRestoreShow, restoreShow, } = useSelector(selectDashboard);
  // eslint-disable-next-line
  const { loading, error, continuationToken, allPodcastSubmissions, psByLimitPage,  limit, pageNumber, totalRows, } = podcastSubmissions

  useEffect(() => {
    if (!loading && !error && allPodcastSubmissions === null) {
      dispatch(getPodcastSubmissions());
    }
  }, [dispatch, loading, error, allPodcastSubmissions])

  const columns = [
    {
      cell: (row) => {
        return (
          <Image size={'tiny'} src={row.showImageUrl} alt={'Podcast Show Image'} />
        )
      },
      width: '80px', // custom width for icon button
      // style: {
      //   borderBottom: '1px solid #FFFFFF',
      //   marginBottom: '-1px',
      // },
    },
    {
        name: 'Name',
        grow: 3,
        selector: row => {
          const parsedSubmissionData = JSON.parse(row.submissionData);
          const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
          return (row?.ihrData?.title ? row.ihrData.title : parsedRssFeedDataSnapshot.title)
        },
        format: row => {
          const parsedSubmissionData = JSON.parse(row.submissionData);
          const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
          return (row?.ihrData?.title ? row.ihrData.title : parsedRssFeedDataSnapshot.title)
        },
    },
    {
        name: 'Show Status',
        selector: row => row.onboardStatus.message,
        cell: (row) => (
          <Popup hoverable on={['hover', 'click']} position='bottom center' trigger={
            <Label color={row.onboardStatus.code === 200  || row.onboardStatus.message === 'Show is live.' ? 'green' : row?.onboardStatus?.rawError?.message ? 'red' : 'yellow' }>
              {row.onboardStatus.code === 200 || row.onboardStatus.message === 'Show is live.' ?
                'Available'
              : row?.onboardStatus?.rawError?.message ?
                'Failed'
              :
                'Processing'
              }
              {/* {row.onboardStatus.code === 404 || row.onboardStatus.message === 'Show has failed processing. Contact iHeartRadio.' ?
                'Failed'
              : row.onboardStatus.message === 'Show is live.' ?
                'Available'
              : row?.onboardStatus?.rawError?.message ?
                row.onboardStatus.rawError.message
              :
                'Processing'
              } */}
            </Label>
          }>
            <Popup.Header><small>Submission Notes</small></Popup.Header>
            <Popup.Content>
              {row.onboardStatus.code === 200 || row.onboardStatus.message === 'Show is live.' ?
                'Show is live.'
              : row.onboardStatus.code === 404 || row.onboardStatus.message === 'Show has failed processing. Contact iHeartRadio.' ?
                <p>Show has failed processing. <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help</a></p>
              : row?.onboardStatus?.rawError?.message ?
                row.onboardStatus.rawError.message
              :
                'Show has been scheduled to process. This may take up to 24 hours.'
              }
              {/* {row.onboardStatus.code === 404 || row.onboardStatus.message === 'Show has failed processing. Contact iHeartRadio.' ?
                <p>Show has failed processing. <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help</a></p>
              : row.onboardStatus.message === 'Show is live.' ?
                'Show is live.'
              :
                'Show has been scheduled to process. This may take up to 24 hours.'
              } */}
            </Popup.Content>
          </Popup>
        ),
    },
    {
      name: 'Owner Verified',
      selector: row => row.verifyOwnerStatus,
      cell: (row) => {
        const rowUuid = authentication.loginData.profileId+'_-_'+row.showId;
        return (
          <>
            {row.verifyOwnerStatus === 'verified' ?
              <Label color={'green'} style={{ textTransform: 'capitalize', }}>
                {row.verifyOwnerStatus}
              </Label>
            : row.verifyOwnerStatus === 'denied' ?
              <Popup hoverable on={['hover', 'click']} position='bottom center' trigger={
                <Label color={'red'} style={{ textTransform: 'capitalize', }}>
                  {row.verifyOwnerStatus}
                </Label>
              }>
                <p>Verification request rejected by RSS Feed Owner.</p>
              </Popup>
            : row.verifyOwnerStatus === 'not-verified' ?
              <Popup hoverable on={['hover', 'click']} position='bottom center' trigger={
                <Label color={'yellow'} style={{ textTransform: 'capitalize', }}>
                  {'Not Verified'}
                </Label>
              }>
                <div style={{ textAlign: 'center', }}>
                  <p>Your iHeartRadio Account Email Address does not match the RSS Feed Owner Email Address.</p>
                  <p>A notification has been sent to the Podcast Owner for verification.</p>
                  <Button secondary style={{ fontSize: '.78571429rem', minHeight: 'auto', margin: '8px auto 0 auto', }}
                    onClick={()=>handleResendOwnerVerify(row)}
                    loading={(rowUuid === resendOwnerVerify.uuid) && (typeof resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid] !== 'undefined') && resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid].loading}
                    disabled={(rowUuid === resendOwnerVerify.uuid) && typeof resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid] !== 'undefined'}
                  >
                    Resend Verification
                  </Button>
                  {(rowUuid === resendOwnerVerify.uuid) && typeof resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid] !== 'undefined' && resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid].loading === false ? <Message compact size={'mini'} success={resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid].response !== null} error={resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid].error !== null} content={resendOwnerVerify.resentByUuid[resendOwnerVerify.uuid].response} /> : null}
                </div>
              </Popup>
            :
              null
            }
          </>
        )
      },
    },
    {
      name: 'Show ID',
      selector: row => row.showId,
      format: row => `${row.verifyOwnerStatus === 'denied' ? '' : row.showId}`,
    },
    {
      name: 'Active',
      selector: row => row.showStatus,
      format: row => {
        if (row.showStatus !== null) {
          return (
            <Popup hoverable on={['hover', 'click']} position='bottom center' trigger={
              <Label color={row.showStatus === 'true' ? 'green' : 'red'}>
                {row.showStatus === 'true' ? 'Active' : 'Disabled'}
              </Label>
            }>
              <Popup.Content>
                {row.showStatus === 'true' ?
                  <div style={{ textAlign: 'center', }}>
                    <p>Your show is active on iHeartRadio.</p>
                    {/* <Button color={'red'} icon={'remove'} style={{ fontSize: '.78571429rem', minHeight: 'auto', margin: '8px auto 0 auto', }}
                      content={'Takedown Show'}
                      //onClick={()=>handleResendOwnerVerify(row)}
                      loading={(row.showId === takedownShow.showId) && (typeof takedownShow.updatedByShowId[row.showId] !== 'undefined') && takedownShow.updatedByShowId[row.showId].loading}
                      disabled={(row.showId === takedownShow.showId) && typeof takedownShow.updatedByShowId[row.showId] !== 'undefined'}
                    /> */}
                  </div>
                :
                  <div style={{ textAlign: 'center', }}>
                    <p>Your show is disabled on iHeartRadio.</p>
                    <Button primary className={'small'} style={{ fontSize: '.78571429rem', minHeight: 'auto', margin: '8px auto 0 auto', }}
                      content={'Restore Show'}
                      onClick={()=>handle_toggleModalRestoreShow(row)}
                      loading={(row.showId === restoreShow.showId) && (typeof restoreShow.updatedByShowId[row.showId] !== 'undefined') && restoreShow.updatedByShowId[row.showId].loading}
                      disabled={(row.showId === restoreShow.showId) && typeof restoreShow.updatedByShowId[row.showId] !== 'undefined'}
                    />
                  </div>
                }
              </Popup.Content>
            </Popup>
          )
        } else {
          return (
            <Popup hoverable on={['hover', 'click']} position='bottom center' trigger={
              <Label color={'green'}>
                {'Active'}
              </Label>
            }>
              <Popup.Content>
                {/* <div style={{ textAlign: 'center', }}> */}
                  <p>Published on iHeartRadio</p>
                  {/* <Button primary size={'tiny'} color={'red'} icon={'remove'} style={{ fontSize: '.78571429rem', minHeight: 'auto', margin: '8px auto 0 auto', }}
                    content={'Takedown Show'}
                    //onClick={()=>handleResendOwnerVerify(row)}
                    loading={(row.showId === takedownShow.showId) && (typeof takedownShow.updatedByShowId[row.showId] !== 'undefined') && takedownShow.updatedByShowId[row.showId].loading}
                    disabled={(row.showId === takedownShow.showId) && typeof takedownShow.updatedByShowId[row.showId] !== 'undefined'}
                  /> */}
                {/* </div> */}
              </Popup.Content>
            </Popup>
          )
        }
      },
    },
    {
      name: 'Updated',
      selector: row => { 
        //console.debug('row.timestamp', row.timestamp);
        return row.timestamp
      },
      format: row => {
        //console.debug('row.timestamp', row.timestamp);
        return `${dayjs(row.timestamp).format('MMM D, YYYY h:mm A')}`
      },
      grow: 2,
    },
  ];

  const rowPreDisabled = row => ((row.onboardStatus.code === 200 || row.onboardStatus.message === 'Show is live.') && row.verifyOwnerStatus === 'verified' && (row.showStatus === 'true' || row.showStatus === null)) ? false : true;

  const handlePageChange = (page) => {
    //console.debug('handlePageChange - page', page);
    let continuationToken
    if (page < pageNumber) {
      //console.debug('page < pageNumber');
      if (page !== 1) {
        //console.debug('page !== 1');
        continuationToken = psByLimitPage[limit+'-'+(page-1)].continuationToken
      }
    } else {
      //console.debug('not page < pageNumber');
      continuationToken = psByLimitPage[limit+'-'+pageNumber].continuationToken
    }
    dispatch(getPodcastSubmissions({page, continuationToken}));
  }

  const handlePerRowsChange = (newPerPage, page) => {
    //console.debug('handlePerRowsChange - newPerPage, page', {newPerPage, page});
    dispatch(getPodcastSubmissions({page: 1, limit: newPerPage,}));
  }

  const handleResendOwnerVerify = (row) => {
    //console.debug('handleResendOwnerVerify - row', row);
    const loggedInUserPID = authentication.loginData.profileId;
    const uuid = loggedInUserPID+'_-_'+row.showId;
    const parsedSubmissionData = JSON.parse(row.submissionData);
    const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
    const podcastSubmitData = {
      ihrProfileId: parsedSubmissionData.ihrProfileId,
      ihrProfileName: parsedSubmissionData.ihrProfileName,
      ihrProfileEmail: parsedSubmissionData.ihrProfileEmail,
      ihrUrl: parsedSubmissionData.ihrUrl,
      rssFeedUrl: parsedSubmissionData.rssFeedUrl,
      showId: row.showId,
      submissionUUID: parsedSubmissionData.submissionUUID,
      rssFeedDataSnapshot: {
        owner: {
          name: parsedRssFeedDataSnapshot?.owner?.name ? parsedRssFeedDataSnapshot.owner.name : null,
          email: parsedRssFeedDataSnapshot.owner.email,
        },
        title: parsedRssFeedDataSnapshot.title,
        description: {
          long: parsedRssFeedDataSnapshot?.description?.long ? parsedRssFeedDataSnapshot.description.long : null,
          short: parsedRssFeedDataSnapshot?.description?.short ? parsedRssFeedDataSnapshot.description.short : null,
        },
        image: parsedRssFeedDataSnapshot.image
      }
    }
    dispatch(resendOwnerVerification({uuid, podcastSubmitData}));
  }

  const handle_toggleModalRestoreShow = (row) => {
    //console.debug('handle_toggleModalRestoreShow - row', row);
    const parsedSubmissionData = JSON.parse(row.submissionData);
    const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
    const podcastSubmitData = {
      ihrProfileId: parsedSubmissionData.ihrProfileId,
      ihrProfileName: parsedSubmissionData.ihrProfileName,
      ihrProfileEmail: parsedSubmissionData.ihrProfileEmail,
      ihrUrl: parsedSubmissionData.ihrUrl,
      rssFeedUrl: parsedSubmissionData.rssFeedUrl,
      showId: row.showId,
      submissionUUID: parsedSubmissionData.submissionUUID,
      customScanBroadcasterName: parsedSubmissionData.customScanBroadcasterName,
      rssFeedDataSnapshot: {
        owner: {
          name: parsedRssFeedDataSnapshot?.owner?.name ? parsedRssFeedDataSnapshot.owner.name : null,
          email: parsedRssFeedDataSnapshot.owner.email,
        },
        title: parsedRssFeedDataSnapshot.title,
        description: {
          long: parsedRssFeedDataSnapshot?.description?.long ? parsedRssFeedDataSnapshot.description.long : null,
          short: parsedRssFeedDataSnapshot?.description?.short ? parsedRssFeedDataSnapshot.description.short : null,
        },
        image: parsedRssFeedDataSnapshot.image
      }
    }
    dispatch(toggleModal_restoreShow({
      showId: row.showId,
      partitionKey: row.partitionKey,
      rowKey: row.rowKey,
      showName: row?.ihrData?.title ? row.ihrData.title : parsedRssFeedDataSnapshot.title,
      podcastSubmitData: podcastSubmitData
    }));
  }

  //console.debug('PodcastsTable - selectDashboard', {loading, error, allPodcastSubmissions, psByLimitPage, continuationToken, limit, totalRows});

  return (
    <>
      {modalUpdateRssUrl.visible ?
        <ModalUpdateRssUrl />
      :
        null
      }
      {modalTakedownShow.visible ?
        <ModalTakedownShow />
      :
        null
      }
      {modalRestoreShow.visible ?
        <ModalRestoreShow />
      :
        null
      }
      <section className={styles.component_podcasts_table}>
        <Segment id={'podcasts-table'}>
          <Container className={'main-container'}>
            {!loading && error ?
              <Message error size={'large'} icon='remove'
                header={<h4>Error</h4>}
                content={<p>{'There was a problem getting podcast submissions'}</p>}
                //content={<p>{error.message}</p>}
              />
            :!loading && (allPodcastSubmissions && allPodcastSubmissions.length === 0) ?
              <Message as={HashLink} warning size={'large'} icon='info circle'
                header={<h4>No Podcast Submissions Found</h4>}
                content={
                  <>
                    <p>No Podcast Submissions were found. Submit a podcast now, or login to a different account.</p>
                  </>
                }
                smooth
                to={'/#submit-podcast'}
              />
            :
              <Segment basic>
                <DataTable
                  columns={columns}
                  progressPending={loading}
                  progressComponent={<CustomLoader />}
                  keyField={'showId'}
                  data={psByLimitPage[limit+'-'+pageNumber] && psByLimitPage[limit+'-'+pageNumber].pagedPodcastSubmissions ? psByLimitPage[limit+'-'+pageNumber].pagedPodcastSubmissions : []}
                  customStyles={customStyles}
                  highlightOnHover
                  expandableRows
                  expandableRowsComponent={PodcastExpanded}
                  expandableRowDisabled={rowPreDisabled}
                  pagination
                  paginationServer
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[5,10,25]}
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={{rowsPerPageText: 'Podcasts per page'}}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={'905px'}
                  //dense
                />
              </Segment>
            }
          </Container>
        </Segment>
      </section>
    </>
  )
}