import { createSlice } from '@reduxjs/toolkit';
// MS Graph Service
import { service_initialize, service_checkAuthStatus, service_getLogin, service_getProfile, service_getLogOut, } from './services/ihr-web-sdk-services';

/* // check local storage for auth credentials
let initialState
let auth_tests
if (typeof localStorage === 'undefined') {
  //console.debug('you should probably be in a real browser for this');
  auth_tests = null
  initialState = null
} else {
  auth_tests = {
    dashboardUser : JSON.parse(localStorage.getItem('dashboard_user'))
  }

  initialState = auth_tests.dashboardUser ? { loading: false, loggedIn: true, graphProfile: auth_tests.dashboardUser, error: null, receivedAt: Date.now(), } : { loading: false, loggedIn: false, graphProfile: null, error: null, receivedAt: null, };
} */

const initialState = { 
  init: {
    loading: false,
    isInitialized: false,
    error: null,
  },
  authentication: {
    loading: false,
    loggedIn: false,
    loginData: null,
    error: null,
  },
  profile: {
    loading: false,
    profileData: null,
    error: null,
  },
};
export const authIhrWebSdkSlice = createSlice({
  name: 'authIhrWebSdk',
  initialState,
  reducers: {
    requestIhrInit: (state) => {
      return {
        ...state,
        init: {
          ...state.init,
          loading: true,
        }
      };
    },
    receiveIhrInit: (state, action) => {
      return {
        ...state,
        init: {
          ...state.init,
          loading: false,
          isInitialized: action.payload ? false : true,
          error: action.payload && action.payload.error ? action.payload.error : null,
        }
      };
    },
    requestLogin: (state) => {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: true,
        }
      };
    },
    receiveLogin: (state, action) => {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          loggedIn: action.payload && action.payload.error ? false : true,
          loginData: action.payload ? action.payload : null,
          error: action.payload && action.payload.error ? action.payload.error : null,
        }
      };
    },
    requestUserProfile: (state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        }
      };
    },
    receiveUserProfile: (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          profileData: action.payload ? action.payload : null,
          error: action.payload && action.payload.error ? action.payload.error : null,
        }
      };
    },
    requestIhrLogout: (state) => {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: true,
        },
        profile: {
          ...state.profile,
          loading: true,
        }
      }
    },
    receiveIhrLogout: (state) => {
      return {
        ...state,
        authentication: initialState.authentication,
        profile: initialState.profile,
      }
    }
  },
});

// iHR initialize
const fetchIhrInit = () => (dispatch) => {
  //console.debug('fetchIhrInit');
  dispatch(requestIhrInit());
  return service_initialize()
    .then((response) => {
      //console.debug('fetchIhrInit - service_initialize - response', response);
      dispatch(receiveIhrInit());
      if (typeof response.error === 'undefined') {
        dispatch(requestLogin());
        return service_checkAuthStatus()
          .then((authResponse) => {
            //console.debug('fetchIhrInit - service_checkAuthStatus - authResponse', authResponse);
            dispatch(receiveLogin(authResponse));
            if (typeof authResponse.error === 'undefined') {
              dispatch(requestUserProfile());
              return service_getProfile()
                .then((ihrUserProfileData) => {
                  //console.debug('fetchIhrInit - service_getProfile - ihrUserProfileData', ihrUserProfileData);
                  dispatch(receiveUserProfile(ihrUserProfileData));
                })
                .catch((error) => {
                  //console.debug('fetchIhrInit error - service_getProfile', error);
                  return dispatch(receiveUserProfile(error));
                });
            }
          })
          .catch((error) => {
            //console.debug('fetchIhrInit error - service_checkAuthStatus', error);
            return dispatch(receiveLogin(error));
          });
      }
    })
    .catch((error) => {
      //console.debug('fetchIhrInit error - service_initialize', error);
      return dispatch(receiveIhrInit(error));
    });
}
const shouldFetchIhrInit = (state, ) => {
  const { init } = state.authIhrWebSdk;
  if (init.loading) { return false }
  if (init.isInitialized === false) { return true }
}
export const fetchIhrInitIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchIhrInit(getState(), dispatch,)) {
    return dispatch(fetchIhrInit())
  }
}
// iHR Login
const fetchLogin = () => (dispatch) => {
  // eslint-disable-next-line
  let ihrUserLoginData
  dispatch(requestLogin());
  return service_getLogin()
    .then((service_getLoginResponse) => {
      //console.debug('fetchLogin - service_getLogin - service_getLoginResponse', service_getLoginResponse);
      ihrUserLoginData = service_getLoginResponse
      dispatch(receiveLogin(service_getLoginResponse));
      dispatch(requestUserProfile());
      return service_getProfile()
    })
    .then((ihrUserProfileData) => {
      //console.debug('fetchLogin - ihrUserProfileData', ihrUserProfileData);
      dispatch(receiveUserProfile(ihrUserProfileData));
    })
    .catch((error) => {
      return dispatch(receiveLogin(error));
    });
}
const shouldFetchLogin = (state, ) => {
  const { authentication } = state.authIhrWebSdk;
  if (authentication.loading) {
    return false
  }
  if (authentication.loginData === null || typeof authentication.loginData.error !== 'undefined') {
    return true
  }
}
export const fetchLoginIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchLogin(getState(), dispatch,)) {
    return dispatch(fetchLogin())
  }
}
// iHR Get Profile
const fetchProfile = () => (dispatch) => {
  dispatch(requestUserProfile());
  return service_getProfile()
    .then((ihrUserProfileData) => {
      dispatch(receiveUserProfile(ihrUserProfileData));
    })
    .catch((error) => {
      dispatch(receiveUserProfile(error));
    });
}
const shouldFetchProfile = (state, ) => {
  const { profile } = state.authIhrWebSdk;
  if (profile.loading) {
    return false
  }
  if (profile.profileData === null) {
    return true
  }
}
export const fetchProfileIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchProfile(getState(), dispatch,)) {
    return dispatch(fetchProfile())
  }
}
// iHR Logout
export const fetchLogout = () => (dispatch) => {
  dispatch(requestIhrLogout());
  return service_getLogOut()
    .then((response) => {
      dispatch(receiveIhrLogout(response));
    })
    .catch((error) => {
      dispatch(receiveIhrLogout(error));
    });
}

export const { requestIhrInit, receiveIhrInit, requestLogin, receiveLogin, requestUserProfile, receiveUserProfile, requestIhrLogout, receiveIhrLogout } = authIhrWebSdkSlice.actions;
export const selectAuthIhrWebSdk = (state) => state.authIhrWebSdk;
export const authIhrWebSdkReducer = authIhrWebSdkSlice.reducer;
