import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { generateApiUrl, generateiHrAuthHeaders } from './utils'

const apiUrl = generateApiUrl('portalAccountSlice.js');
//console.debug('portalAccountSlice.js - apiUrl', apiUrl);
// Google Recaptcha Client Key
const GRECAPTCHAKEY = process.env.REACT_APP_GRECAPTCHAKEY;
//console.debug('GRECAPTCHAKEY', GRECAPTCHAKEY);

const initialState = {
  value: 0,
  view: 'landing',
  status: 'rss-validate',
  recaptcha: {
    sitekey: GRECAPTCHAKEY,
    valid: false,
    value: null,
    error: null,
  },
  rssVerification: {
    loading: false,
    attempt: 0,
    rssUrlValid: false,
    rssFeedUrl: '',
    rssData: null,
    formError: false,
    error: null,
  },
  submitPodcast: {
    loading: false,
    attempt: 0,
    podcastSubmitData: null,
    submitResponseData: null,
    formError: false,
    error: null,
  },
}

export const postRssValidate = createAsyncThunk(
  'podcastSubmit/postRssValidate',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('postRssValidate - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'postRssValidate');
    //console.debug('postRssValidate - headers', headers);
    //const state = getState();
    //const { rssVerification } = state.podcastSubmit;
    const { recaptchaToken, rssFeedUrl, } = args;

    //const newAttemptValue = rssVerification.attempt+1;
    //console.debug('postRssValidate - newAttemptValue', newAttemptValue);

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        recaptchaToken,
        rssFeedUrl,
      })
    };
    //console.debug('postRssValidate - requestOptions', requestOptions);

    try {
      const res = await fetch(apiUrl+'/submit-podcast/validate', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('postRssValidate - error', error);
      let errorBody
      if (error.type) {
        errorBody = error
      } else {
        errorBody = {
          type: 'validate-rss',
          message: 'RSS Validation Failed',
          rawError: error && error.message ? error.message : error,
        }
      }
      //throw errorBody
      return rejectWithValue(errorBody)
    }
})

export const postSubmitPodcast = createAsyncThunk(
  'podcastSubmit/postSubmitPodcast',
  async (args, { getState, rejectWithValue, dispatch }) => {
    //console.debug('postSubmitPodcast - args', args);
    const headers = await generateiHrAuthHeaders(getState, 'postSubmitPodcast');
    //console.debug('postSubmitPodcast - headers', headers);
    const state = getState();
    const { authentication, profile } = state.authIhrWebSdk;
    const { 
      //submitPodcast, 
      rssVerification 
    } = state.podcastSubmit;
    const { confirmInfoAccurate, confirmNoGuarantee, confirmTermsCopyright } = args;

    //const newAttemptValue = submitPodcast.attempt+1;
    //console.debug('postSubmitPodcast - newAttemptValue', newAttemptValue);

    // generate a snapshot of the Podcast Submission Data User Verified
    //const rssFeedDataSnapshot = JSON.stringify(rssVerification.rssData);
    //console.debug('postSubmitPodcast - rssFeedDataSnapshot', rssFeedDataSnapshot);
    let rssFeedDataSnapshotNoEpisodes = JSON.parse(JSON.stringify(rssVerification.rssData));
    if (rssFeedDataSnapshotNoEpisodes.episodes && rssFeedDataSnapshotNoEpisodes.episodes.length !== 0) {
      rssFeedDataSnapshotNoEpisodes.episodes = []
    }
    //console.debug('postSubmitPodcast - rssFeedDataSnapshotNoEpisodes - episodes removed', rssFeedDataSnapshotNoEpisodes);

    const podcastSubmitData = {
      ihrProfileId: authentication.loginData.profileId,
      ihrProfileName: profile.profileData.name,
      ihrProfileEmail: profile.profileData.email,
      formData: {confirmInfoAccurate, confirmNoGuarantee, confirmTermsCopyright},
      rssFeedUrl: rssVerification.rssFeedUrl,
      rssFeedDataSnapshot: JSON.stringify(rssFeedDataSnapshotNoEpisodes),
    };
    //console.debug('postSubmitPodcast - podcastSubmitData', podcastSubmitData);
    dispatch(setPodcastSubmitData(podcastSubmitData));

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(podcastSubmitData)
    };
    //console.debug('postSubmitPodcast - requestOptions', requestOptions);

    try {
      const res = await fetch(apiUrl+'/submit-podcast/create', requestOptions)
      let resJSON = await res.json();
      //console.debug('postSubmitPodcast - resJSON', resJSON);
      if (resJSON.rawError || typeof resJSON.showId === 'undefined') {
        throw resJSON;
      } else {
        return resJSON
      }
    } catch (error) {
      //console.debug('postSubmitPodcast - error', error);
      // catch already on iHeartRadio error
      if (error.code === 409) {
        let alreadyAddedResponse = error.rawError;
        alreadyAddedResponse.code = error.code;
        // append owner verify data
        if (typeof error.ownerVerify !== 'undefined') {
          alreadyAddedResponse.ownerVerify = error.ownerVerify
        }
        return alreadyAddedResponse
      } else {
        //throw error
        return rejectWithValue(error)
      }
    }
})

export const podcastSubmitSlice = createSlice({
  name: 'podcastSubmit',
  initialState,
  reducers: {
    setActiveView: (state, action) => {
      state.view = action.payload
    },
    setActiveStatus: (state, action) => {
      state.status = action.payload ? action.payload : 'rss-validate'
    },
    resetActiveViewForm: (state, action) => {
      state.view = initialState.view
      state.status = initialState.status
      state.recaptcha = initialState.recaptcha
      state.rssVerification = initialState.rssVerification
      state.submitPodcast = initialState.submitPodcast
    },
    setRecaptcha: (state, action) => {
      state.recaptcha.valid = action.payload.error ? false : true
      state.recaptcha.value = action.payload.value ? action.payload.value : null
      state.recaptcha.error = action.payload.error ? true : false
      state.rssVerification.formError = action.payload.error ? true : false
      state.rssVerification.error = action.payload.error ? action.payload.error : null
    },
    setRssVerifyUrl: (state, action) => {
      state.rssVerification.rssUrlValid = action.payload.error ? false : true
      state.rssVerification.rssFeedUrl = action.payload.rssFeedUrl ? action.payload.rssFeedUrl : initialState.rssVerification.rssFeedUrl
      state.rssVerification.error = action.payload.error ? action.payload.error : false
      state.rssVerification.formError = action.payload.error ? true : false
    },
    setPodcastSubmitData: (state, action) => {
      state.submitPodcast.podcastSubmitData = action.payload ? action.payload : null
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
  extraReducers: {
    [postRssValidate.pending]: (state) => {
      state.status = initialState.status
      state.rssVerification.loading = true
      state.rssVerification.formError = false
      state.rssVerification.error = null
    },
    [postRssValidate.fulfilled]: (state, { payload }) => {
      state.status = 'rss-url-valid'
      state.rssVerification.loading = false
      state.rssVerification.rssUrlValid = true
      state.rssVerification.rssData = payload
    },
    [postRssValidate.rejected]: (state, { payload }) => {
      state.rssVerification.loading = false
      state.rssVerification.rssUrlValid = false
      state.rssVerification.formError = true
      state.rssVerification.error = payload
    },
    [postSubmitPodcast.pending]: (state) => {
      state.submitPodcast.loading = true
      state.submitPodcast.formError = false
      state.submitPodcast.error = null
    },
    [postSubmitPodcast.fulfilled]: (state, { payload }) => {
      state.view = 'podcast-submit-success'
      state.submitPodcast.loading = false
      state.submitPodcast.submitResponseData = payload
    },
    [postSubmitPodcast.rejected]: (state, { payload }) => {
      state.submitPodcast.loading = false
      state.submitPodcast.formError = true
      state.submitPodcast.error = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setActiveView, setActiveStatus, resetActiveViewForm, setRecaptcha, setRssVerifyUrl, setPodcastSubmitData, } = podcastSubmitSlice.actions;
export const selectPodcastSubmit = (state) => state.podcastSubmit;
export const podcastSubmitReducer = podcastSubmitSlice.reducer