import React, { useState, 
  //useEffect 
} from 'react'
import { useSelector } from 'react-redux'
import { selectPodcastSubmit, } from '../podcastSubmitSlice'

import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import {
  Dimmer,
  Loader,
  Segment,
  Button,
  List,
  Image,
  Header,
  Grid,
  Accordion,
  Icon,
  Label,
  Input,
} from 'semantic-ui-react';

// icons
import svg_icon_play from '../../../layouts/images/icons/icon-play.svg';

export default function PodcastRssPreview() {
  const { rssVerification } = useSelector(selectPodcastSubmit);
  const { loading, rssUrlValid, rssData, rssFeedUrl, } = rssVerification;

  const [episodesAccordionState, setEpisodesAccordionState] = useState({
    activeIndex: null,
  })
  const handleAccordianClick = (index) => {
    const newIndex = episodesAccordionState.activeIndex === index ? -1 : index
    setEpisodesAccordionState({ activeIndex: newIndex });
  }

  return (
    <>
      <Segment basic className={'podcast-rss-preview'}>
        {!loading && rssUrlValid === true && rssData !== null ?
          <Grid columns={'equal'}>
            <Grid.Row style={{margin: '0', padding: '0',}}>
              <Grid.Column width={16}>
                <div className={'podcast-field'}>
                  <span>URL</span>
                  <Input fluid size={'mini'} type="text" name='rssFeedUrl'
                    label={{ color: 'green', content: 'Valid' }}
                    //label={<Message success header={'Valid'} />}
                    value={rssFeedUrl}
                    readOnly={'readonly'}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <div className={'podcast-field'}>
                  <span>Podcast Artwork</span>
                  {typeof rssData.image !== 'undefined' ?
                    <Image src={rssData.image} alt={'Podcast Show Image'} />
                  :
                    null
                  }
                </div>
                <div className={'podcast-field'}>
                  <span>Type</span>
                  <p>{typeof rssData.type !== 'undefined' ? rssData.type : null}</p>
                </div>
                <div className={'podcast-field'}>
                  <span>Explicit</span>
                  {typeof rssData.explicit !== 'undefined' ?
                    <p>{rssData.explicit ? 'Yes' : 'No'}</p>
                  :
                    null
                  }
                </div>
                <div className={'podcast-field'}>
                  <span>Categories</span>
                  <Label.Group size='tiny'>
                    {rssData.categories && rssData.categories.length !== 0 && rssData.categories.map((category, index) => (
                      <Label key={index}>
                        {category}
                      </Label>
                    ))}
                  </Label.Group>
                </div>
                <div className={'podcast-field'}>
                  <span>Language</span>
                  <p>{typeof rssData.language !== 'undefined' ? rssData.language : null}</p>
                </div>
                <div className={'podcast-field'}>
                  <span>Updated</span>
                  {typeof rssData.updated !== 'undefined' ?
                    <p>{dayjs(rssData.updated).format('MMMM DD, YYYY')}</p>
                  :
                    null
                  }
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <div className={'podcast-field'}>
                  <span>Title</span>
                    <Header as={'h2'}>
                      {typeof rssData.title !== 'undefined' ? rssData.title : null}
                    </Header>
                </div>
                <div className={'podcast-field'}>
                  <span>Description</span>
                  {typeof rssData.description !== 'undefined' && rssData.description.long !== 'undefined' ?
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((rssData.description.long))}}></div>
                  :
                    null
                  }
                </div>
                <div className={'podcast-field'}>
                  <span>Copyright</span>
                  <p>{typeof rssData.copyright !== 'undefined' ? rssData.copyright : null}</p>
                </div>
                <div className={'podcast-field'}>
                  <span>Website Link</span>
                  {typeof rssData.link !== 'undefined' ?
                    <a href={rssData.link} target={'_blank'} aria-label={'RSS Data Show Link'} rel={'noreferrer'}>{rssData.link}</a>
                  :
                    null
                  }
                </div>
                <div className={'podcast-field'}>
                  <List horizontal>
                    <List.Item>
                      <span>Author</span>
                      <p>{typeof rssData.author !== 'undefined' ? rssData.author : '\u00A0'}</p>
                    </List.Item>
                    <List.Item>
                      <span>Owner Name</span>
                      <p>{typeof rssData.owner !== 'undefined' && rssData.owner.name !== 'undefined' ? rssData.owner.name : '\u00A0'}</p>
                    </List.Item>
                    <List.Item>
                      <span>Owner Email</span>
                      <p>{typeof rssData.owner !== 'undefined' && rssData.owner.email !== 'undefined' ? rssData.owner.email : '\u00A0'}</p>
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={'podcast-episodes-row'}>
              <Grid.Column width={16}>
                <Segment basic className={'podcast-episodes'}>
                  {rssData.episodes && rssData.episodes.length !== 0 && typeof rssData.totalEpisodes !== 'undefined' ?
                    <React.Fragment>
                      <Header>
                        Episodes <Label size={'tiny'}>{rssData.totalEpisodes} Total</Label>
                      </Header>
                      <div className={'accordion-container'}>
                        <Accordion styled fluid>
                          {rssData.episodes && rssData.episodes.length !== 0 && rssData.episodes.map((podcastEpisode, index) => (
                            <div key={index}>
                              <Accordion.Title active={episodesAccordionState.activeIndex === index} index={index}
                                onClick={()=>{handleAccordianClick(index)}}
                                aria-label={'Accordion show expander'}
                              >
                                <Icon name='angle down' />
                                <a href={typeof podcastEpisode.enclosure !== 'undefined' && typeof podcastEpisode.enclosure.url !== 'undefined' ? podcastEpisode.enclosure.url : null} target={'_blank'} aria-label={'RSS Data Show Episode Link'} rel={'noreferrer'}>
                                  <Image wrapped size={'mini'} src={svg_icon_play} alt={'Play icon image'} />
                                </a>
                                <h3>{podcastEpisode.title}</h3>
                                <List horizontal bulleted>
                                  <List.Item>
                                    {dayjs(podcastEpisode.published).format('MMMM DD, YYYY')}
                                  </List.Item>
                                  <List.Item>
                                    Approx. {Math.round(podcastEpisode.duration / 60)} min
                                  </List.Item>
                                </List>
                              </Accordion.Title>
                              <Accordion.Content active={episodesAccordionState.activeIndex === index}>
                                <p>{podcastEpisode.description}</p>
                              </Accordion.Content>
                            </div>
                          ))}
                        </Accordion>
                        {(rssData.totalEpisodes - rssData.episodes.length) > 0 ?
                          <div style={{ textAlign: 'center', }}>
                            <Button as={'a'} secondary href={rssFeedUrl} target={'_blank'} aria-label={'View all podcast show episodes Link'} rel={'noreferrer'}>
                              And {rssData.totalEpisodes - rssData.episodes.length} More Episodes...
                            </Button>
                          </div>
                        :
                          null
                        }
                      </div>
                    </React.Fragment>                            
                  :
                    <p>No Episodes Found</p>
                  }
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        :
          <Segment basic style={{ height: '400px', }}>
            <Dimmer active inverted><Loader content='Loading Your Podcast...' /></Dimmer>
          </Segment>
        }
      </Segment>
    </>
  )
}