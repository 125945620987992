// Public - Marketing - Homepage - Homepage Podcast Submit
// ----------------------
import React, { useEffect } from 'react'
import { Routes, Route, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { selectPodcastSubmit, resetActiveViewForm, } from '../podcastSubmitSlice'

import { Link, useNavigate, useLocation, } from "react-router-dom"
import { AnimatePresence } from "framer-motion";

import LandingView from '../landing-view/landing-view';
import AddPodcastView from '../add-podcast-view/add-podcast-view';
import SuccessView from '../success-view/success-view';

import {
  Segment,
  Container,
  Grid,
  Header,
  Button,
  Popup,
  Step,
} from "semantic-ui-react";

// styles
import styles from './homepage-podcast-submit.module.scss';


export default function HomepagePodcastSubmit() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { view, submitPodcast } = useSelector(selectPodcastSubmit);

  useEffect(() => {
    //console.debug('HomepagePodcastSubmit - useEffect - location', location);
    // redirect on podcast submission success
    if (view === 'podcast-submit-success' && location.pathname !== '/success/' && !submitPodcast.loading && !submitPodcast.error && submitPodcast.submitResponseData !== null) {
      //console.debug('HomepagePodcastSubmit - useEffect - view, submitPodcast', {view, submitPodcast,});
      navigate('/success/');
      //navigate('/success/?transifex');
    }
  }, [location, view, submitPodcast, navigate])
  
  return (
    <>
      <section className={styles.component_podcast_submit}>
        <Segment basic id={'submit-podcast'}>
          <Container className={'main-container'}>
            <Segment basic>
              <Header as='h2' textAlign={'center'}>
                Submit Your Podcast
                <Header.Subheader>
                  Use the form below to submit a new podcast for inclusion on iHeartRadio.
                </Header.Subheader>
              </Header>
                <Grid columns={1} verticalAlign={'middle'} stackable>
                  <Grid.Row>
                    <Grid.Column textAlign={'left'} className={'main-content-column'}>
                      <AnimatePresence mode={'wait'}>
                        <Routes>
                          <Route index element={<LandingView />} />
                          <Route path={'/add-podcast/'} element={<AddPodcastView />} />
                          <Route path={'/success/'} element={<SuccessView />} />
                        </Routes>
                      </AnimatePresence>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered className={'steps-row'}>
                    <Grid.Column textAlign={'center'} width={8} style={{marginTop: '25px',}}>
                      <Step.Group unstackable size={'mini'}>
                        <Step icon={'user outline'}
                          as={Link} link
                          active={view === 'landing'}
                          disabled={view === 'landing'}
                          completed={view !== 'landing'}
                          title='Login'
                          to={'/#submit-podcast'}
                          onClick={()=>{dispatch(resetActiveViewForm())}}
                        />
                        <Step icon={'rss'}
                          //as={Link} link
                          active={view === 'verify-podcast'}
                          disabled={view !== 'verify-podcast'}
                          completed={view === 'podcast-submit-success'}
                          title='Enter Podcast RSS'
                          //to={'/add-podcast'}
                          //onClick={()=>{dispatch(setActiveView('verify-podcast'))}}
                        />
                        <Step icon={'volume up'}
                          //as={Link} link
                          active={view === 'podcast-submit-success'}
                          disabled={view !== 'podcast-submit-success'}
                          completed={view === 'podcast-submit-success'}
                          title='Listen on iHeartRadio'
                          //to={'/success'}
                          //onClick={()=>{dispatch(setActiveView('podcast-submit-success'))}}
                        />
                      </Step.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered className={'meta-row'}>
                    <Grid.Column textAlign={'center'} width={6}>
                      <p>
                        <strong>Please note:</strong> Only individuals located in our supported regions of The United States, Canada, Mexico, Australia and New Zealand can access and submit podcasts via <a href={'https://podcasters.iheart.com'} target={'_self'} rel="noreferrer" aria-label={'iHeartRadio Podcasters Link'}>podcasters.iheart.com</a>.
                      </p>
                      <Popup basic hoverable position='bottom center' trigger={<Button secondary className={'help-btn'}>Need Help?</Button>}>
                        <p>Contact the <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help Desk</a> to report any issues with podcast submission.</p>
                      </Popup>
                      <p className={'h5'}>Podcasters must adhere to the <a href={'https://iheartradio.com/legal/cla/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Legal Link'}>iHeartMedia Content License Agreement</a> and podcast content must not violate the <a href={'https://iheartradio.com/legal/pcp/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Content Policy Link'}>iHeartMedia Podcast Content Policy</a>.</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
            </Segment>
          </Container>
        </Segment>
      </section>
    </>
  )
}