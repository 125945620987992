// Private - Layout - Dashboard - Default
// ----------------------
import React from 'react'
import { Outlet, Link, } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import Helmet from 'react-helmet';
import {
  Segment,
  Container,
  Header,
  Button,
  Popup,
  Menu,
} from "semantic-ui-react";

// styles
import styles from './dashboard-default.module.scss';


export default function DashboardDefaultLayout(props) {
  const { children, component: Component } = props;
  return (
    <>
      <Helmet>
        <title>Podcasters Dashboard | iHeartRadio</title>
      </Helmet>
      <section className={styles.layout_dashboard_default}>
        <Segment basic id={'dashboard-default'}>
          <Container className={'main-container'}>
            <Segment basic>
              <Menu fluid borderless>
                <Menu.Item header>
                  <Header as='h1'>
                    Your Dashboard
                  </Header>
                </Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item header>
                    <Button as={Link} secondary to={'/'}>
                      Back
                    </Button>
                  </Menu.Item>
                  <Menu.Item header>
                    <Button as={HashLink} primary
                      smooth
                      to={'/#submit-podcast'}
                    >
                      Submit Podcast
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>              
            </Segment>
          </Container>
          <Segment basic className={'main-content'}>
            {children ? 
              children 
            : Component ? 
              <Component />
            :
              <Outlet />
            }
          </Segment>
          <Container text textAlign={'center'} className={'notice'}>
            <Popup basic hoverable position='bottom center' trigger={<Button secondary className={'help-btn'}>Need Help?</Button>}>
              <p>Contact the <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help Desk</a> to report any issues with podcast submission.</p>
            </Popup>
          </Container>
        </Segment>
      </section>
    </>
  )
}