// Private - Dashboard - Podcasts Table - Modal - Restore Show
// ----------------------
import React, { useState,} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, closeModal_restoreShow, postRestoreShow, getPodcastSubmissions } from '../../../dashboardSlice'

// Semantic UI
import {
  Modal,
  Segment,
  Button,
  Message,
  Form,
  Item,
} from 'semantic-ui-react';

// styles
//import styles from './modal-update-rss-url.module.scss';

export default function ModalRestoreShow(props) {
  //console.debug('DashboardModalUpdateRssUrl - props', props);

  const dispatch = useDispatch();

  const { modalRestoreShow, restoreShow, podcastSubmissions } = useSelector(selectDashboard);  

  const [formState, setFormState] = useState({
    confirmed: false,
    showName: '',
  });
  const handleChange = (e, { name, value }) => setFormState({ 
    confirmed: value === modalRestoreShow.showName ? true : false,
    [name]: value ? value : ''
  });

  const handle_closeModal = () => {
    //console.debug('handle_closeModal');
    dispatch(closeModal_restoreShow());
    if (restoreShow.responseData !== null) {
      //dispatch(getPodcastSubmissions());
      let continuationToken = null
      if (podcastSubmissions.pageNumber-1 > 0) {
        continuationToken = podcastSubmissions.psByLimitPage[podcastSubmissions.limit+'-'+(podcastSubmissions.pageNumber-1)].continuationToken
      }
      dispatch(getPodcastSubmissions({refresh: true, continuationToken}));
    }
  }

  const handle_restoreShow = () => {
    //console.debug('handle_restoreShow');
    dispatch(postRestoreShow({
      showId: modalRestoreShow.showId,
      partitionKey: modalRestoreShow.partitionKey,
      rowKey: modalRestoreShow.rowKey,
    }));
  }

  return (
    <>
      <Modal size={'small'} id={'modal-restore-show'} 
        //className={styles.modal_restore_show}
        open={modalRestoreShow.visible}
        onClose={()=> handle_closeModal()}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          Restore Show
          <p>Make your show live on iHeartRadio</p>
          {/* <br/>
          <Label content={'Show Name'} detail={modalRestoreShow.showName} /> */}
        </Modal.Header>
        <Modal.Content scrolling>
          <Segment basic textAlign={'center'} className={'modal-content-body'}>
            {modalRestoreShow.podcastSubmitData ?
              <Item.Group>
                <Item>
                  {modalRestoreShow.podcastSubmitData.rssFeedDataSnapshot.image ?
                    <Item.Image as={'a'} href={modalRestoreShow.podcastSubmitData.ihrUrl ? modalRestoreShow.podcastSubmitData.ihrUrl : null} target={modalRestoreShow.podcastSubmitData.ihrUrl ? '_blank' : null} rel={modalRestoreShow.podcastSubmitData.ihrUrl ? 'noreferrer' : null} aria-label={modalRestoreShow.podcastSubmitData.ihrUrl ? 'iHeartRadio Podcast Show Link' : null} size='tiny' src={modalRestoreShow.podcastSubmitData.rssFeedDataSnapshot.image} alt={modalRestoreShow.showName+' Image'} />
                  :
                    null
                  }
                  <Item.Content verticalAlign='middle'>
                    <Item.Header>{modalRestoreShow.showName}</Item.Header>
                    <Item.Meta>
                      {/* eslint-disable-next-line */}
                      <a href={modalRestoreShow.podcastSubmitData.ihrUrl ? modalRestoreShow.podcastSubmitData.ihrUrl : null} target={modalRestoreShow.podcastSubmitData.ihrUrl ? '_blank' : null} rel={modalRestoreShow.podcastSubmitData.ihrUrl ? 'noreferrer' : null} aria-label={modalRestoreShow.podcastSubmitData.ihrUrl ? 'iHeartRadio Podcast Show Link' : null}>
                        {modalRestoreShow.podcastSubmitData.ihrUrl ? modalRestoreShow.podcastSubmitData.ihrUrl : modalRestoreShow.podcastSubmitData.rssFeedUrl ? modalRestoreShow.podcastSubmitData.rssFeedUrl : null}
                      </a>
                    </Item.Meta>
                  </Item.Content>
                </Item>
              </Item.Group>
            :
              null
            }
            {!restoreShow.loading && restoreShow.responseData ?
              <div className={'success-view'}>
                <h2>Restore Show Success!</h2>
                <p>{modalRestoreShow.showName + ' has been scheduled to be restored. This process can take up to 1 hour.'}</p>
                {/* <p>{restoreShow.responseData.message ? restoreShow.responseData.message : 'Your show is available on iHeartRadio.'}</p> */}
                {/* <p>Takedown your show by selecting the Takedown action from the Dashboard.</p> */}
                <Button primary content={'Close'}
                  onClick={()=>handle_closeModal()}
                />
              </div>
            :
              <div className={'form-view'}>
                {/* <Header style={{ marginBottom: '25px'}}>Restore your podcast show on iHeartRadio</Header> */}
                <Form error={restoreShow.error ? true : false} success={formState.confirmed}>
                  <Form.Field required>
                    <Form.Input type="text" min="3"
                      label={'Confirm restore show by entering your show title:'}
                      placeholder='Confirm Show Title...'
                      name='showName'
                      value={formState.showName}
                      onChange={handleChange}
                      error={restoreShow.error ? true : false}
                      readOnly={restoreShow.error ? true : false}
                      //disabled={rssUrlValid}
                    />
                    {!restoreShow.loading && restoreShow.error ?
                      <Message error size={'tiny'} header={'Error'} 
                        content={restoreShow.error?.rawError?.error ? restoreShow.error.rawError.error : restoreShow.error?.rawError?.message ? restoreShow.error.rawError.message : null} 
                      />
                    :
                      null
                    }
                  </Form.Field>
                </Form>
                <Button primary style={{ margin: '25px auto', }}
                  loading={restoreShow.loading}
                  disabled={(!formState.confirmed || restoreShow.loading) || (formState.showName !== modalRestoreShow.showName) || (!restoreShow.loading && restoreShow.error !== null)}
                  onClick={()=>{handle_restoreShow()}}
                >
                  Restore Show
                </Button>
              </div>
            }
          </Segment>
        </Modal.Content>
      </Modal>
    </>
  )
}