// Public - Marketing - Homepage - Homepage Marketing
// ----------------------
import React from 'react'
import {
  Segment,
  Container,
  Grid,
  Header,
  Image,
  Icon,
  List,
  Button,
  Card,
} from "semantic-ui-react";

// styles
import styles from './homepage-marketing.module.scss';
// images
import svg_devicesLockup from './images/devices-lockup.svg';
import svg_icon_apple from '../../../layouts/images/icons/icon-apple.svg';
import svg_icon_gPlay from '../../../layouts/images/icons/icon-google-play.svg';
import image_featurefollow_lockup from './images/feature-follow.png';
import image_featureoffline_lockup from './images/feature-offline.png';

export default function HomepageMarketing() {
  return (
    <>
      <section className={styles.component_homepage_marketing}>
        <Segment basic id={'learn-more'}>
          <Segment basic className={'marketing-s1'}>
            <Container className={'main-container'}>
              <Segment basic>
                <Grid stretched stackable reversed={'mobile'} verticalAlign={'middle'} columns={'equal'}>
                  <Grid.Row>
                    <Grid.Column only='mobile' className={'second-content-column'}>
                      <Segment basic>
                        <Image fluid wrapped centered width={550} height={301} src={svg_devicesLockup} alt={'Phone, Tablet, Desktop devices and speaker'} />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column className={'first-content-column'}>
                      <Segment basic>
                        <Header as='h2' textAlign={'left'}>
                          Reach your audience anywhere
                          {/* <Header.Subheader>
                            With iHeartRadio, listeners can stream thousands of live broadcast radio stations, hundreds of thousands of podcasts, music playlists, custom Artist Radio stations and on demand, everywhere, on every device.
                          </Header.Subheader> */}
                          <Header.Subheader>
                            The free iHeartRadio app is available on over 250 platforms and over 2,000 different connected devices — including smart speakers, digital auto dashes, tablets, wearables, smartphones, virtual assistants, televisions and gaming consoles.
                          </Header.Subheader>
                        </Header>
                        <List relaxed size={'large'} verticalAlign={'middle'}>
                          <List.Item>
                            <Button as={'a'} className={'app-store-download'}
                              href={'https://itunes.apple.com/us/app/iheartradio/id290638154?mt=8'}
                              target={'_blank'}
                              rel={'noreferrer'}
                              aria-label={'Download on the App Store Link'}
                            >
                              <Image wrapped width={24} height={29} src={svg_icon_apple} alt={'Apple Computer Logo'} />
                              <div>
                                <div>Download on the</div>
                                <span>App Store</span>
                              </div>
                            </Button>
                          </List.Item>
                          <List.Item>
                            <Button as={'a'} className={'app-store-download'}
                              href={'https://play.google.com/store/apps/details?id=com.clearchannel.iheartradio.controller&hl=en_US'}
                              target={'_blank'}
                              rel={'noreferrer'}
                              aria-label={'Download on the Google Play Link'}
                            >
                              <Image wrapped width={26} height={29} src={svg_icon_gPlay} alt={'Google Play Logo'} />
                              <div>
                                <div>Download on</div>
                                <span>Google Play</span>
                              </div>
                            </Button>
                          </List.Item>
                          <List.Item>
                            <Button as={'a'} icon labelPosition={'right'} className={'plain-labeled'}
                              href={'https://www.iheart.com/apps/'} 
                              target={'_blank'}
                              rel={'noreferrer'}
                              aria-label={'View All Apps Link'}
                            >
                              View All Apps
                              <Icon name='angle right' />
                            </Button>
                          </List.Item>
                        </List>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column only='computer tablet' className={'second-content-column'}>
                      <Segment basic>
                        <Image fluid wrapped centered width={550} height={301} src={svg_devicesLockup} alt={'Phone, Tablet, Desktop devices and speaker'} />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Container>
          </Segment>

          <Segment basic className={'marketing-s2'}>
            <Container className={'main-container'}>
              <Segment basic>
                <Header as='h2' textAlign={'center'}>
                  iHeartRadio Exclusives
                  <Header.Subheader>
                    Give your audience even more ways to tune in with iHeartRadio.
                  </Header.Subheader>
                </Header>
                <Grid stretched stackable centered columns={'equal'} verticalAlign={'middle'}>
                  <Grid.Row>
                    <Grid.Column textAlign={'center'} className={'first-content-column'}>
                      <Segment basic>
                        <Card fluid as={'a'} href={'https://www.iheart.com/podcast/category/podcast-top-100-132/'} target={'_blank'} rel={'noreferrer'} aria-label={'Podcast Top 100 Link'} className={'pt100'}>
                          <Card.Content>
                            <Card.Header>
                              Podcast Top 100
                            </Card.Header>
                          </Card.Content>
                        </Card>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column textAlign={'center'}>
                      <Segment basic>
                        <Card fluid as={'a'} href={'https://www.iheart.com/podcast/category/curiosity-136/'} target={'_blank'} rel={'noreferrer'} aria-label={'Curiosity Link'} className={'spooky'}>
                          <Card.Content>
                            <Card.Header>
                              iHeartRadio<br/>Curiosity
                            </Card.Header>
                          </Card.Content>
                        </Card>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column textAlign={'center'}>
                      <Segment basic>
                        <Card fluid as={'a'} href={'https://www.iheart.com/podcast-awards/'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Podcast Awards Link'} className={'ihrpa'}>
                          <Card.Content>
                            <Card.Header>
                              iHeartRadio<br/>Podcast Awards
                            </Card.Header>
                          </Card.Content>
                        </Card>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column textAlign={'center'}>
                      <Segment basic>
                        <Card fluid as={'a'} href={'https://iheartblog.iheart.online/post/iheartradio-now-offers-over-250000-podcasts'} rel={'noreferrer'} target={'_blank'} aria-label={'iHeartRadio Blog article Link'} className={'ihmpmb'}>
                          <Card.Content>
                            <Card.Header>
                              "Podcast, Meet Broadcast"
                            </Card.Header>
                          </Card.Content>
                        </Card>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Container>
          </Segment>

          <Segment basic className={'marketing-s3'}>
            <Container className={'main-container'}>
              <Segment basic>
                <Grid stackable columns={2} verticalAlign={'middle'} stretched>
                  <Grid.Row>
                    <Grid.Column className={'first-content-column'}>
                      <Segment basic>
                        <Header as='h2' textAlign={'left'}>
                          Drive audience engagement
                          <Header.Subheader>
                            iHeartRadio features give your audience easy ways to stay engaged.
                          </Header.Subheader>
                        </Header>
                        {/* <List relaxed size={'large'} verticalAlign={'middle'}>
                          <List.Item>
                            <List.Content>
                              <List.Header>Follow A Podcast</List.Header>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content>
                              <List.Header>Listen Offline</List.Header>
                            </List.Content>
                          </List.Item>
                        </List> */}
                        <Button as={'a'} secondary href={'https://help.iheart.com/hc/en-us/articles/115000241151-Podcasts'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Podcast Features Link'}>
                          iHeartRadio Podcast Features
                        </Button>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Image fluid wrapped width={240} height={477} src={image_featurefollow_lockup} alt={'Mobile device with screenshot of iHeartRadio App with Podcast Alert feature'} />
              <Image fluid wrapped width={240} height={477} src={image_featureoffline_lockup} alt={'Mobile device with screenshot of iHeartRadio App with Podcast Settings feature'} />
            </Container>
          </Segment>

        </Segment>
      </section>
    </>
  )
}