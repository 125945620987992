// Private - Dashboard - Podcasts Table - Modal - Update RSS URL
// ----------------------
import React from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, closeModal_updateRssUrl, getPodcastSubmissions, } from '../../../dashboardSlice'
import { selectPodcastSubmit, resetActiveViewForm, } from '../../../../podcast-submit/podcastSubmitSlice'

import PodcastRssValidate from '../../../../podcast-submit/add-podcast-view/podcast-rss-validate'
import PodcastRssPreview from '../../../../podcast-submit/add-podcast-view/podcast-rss-preview'
import PodcastSubmit from '../../../../podcast-submit/add-podcast-view/podcast-submit'
import OwnerVerifyView from '../../../../podcast-submit/success-view/owner-verify-view'

// Semantic UI
import {
  Grid,
  Modal,
  Segment,
  Header,
  Popup,
  Button,
  Label,
  Dimmer,
  Loader,
  Message,
  Item,
} from 'semantic-ui-react';

// styles
import styles from './modal-update-rss-url.module.scss';

export default function ModalUpdateRssUrl(props) {
  //console.debug('DashboardModalUpdateRssUrl - props', props);

  const dispatch = useDispatch();

  const { modalUpdateRssUrl, updateRssUrl, podcastSubmissions } = useSelector(selectDashboard);  

  const { status, rssVerification } = useSelector(selectPodcastSubmit);

  const handle_closeModal = () => {
    //console.debug('handle_closeModal');
    dispatch(resetActiveViewForm());
    dispatch(closeModal_updateRssUrl());
    if (status === 'update-rss-url-success') {
      //dispatch(getPodcastSubmissions());
      let continuationToken = null
        if (podcastSubmissions.pageNumber-1 > 0) {
          continuationToken = podcastSubmissions.psByLimitPage[podcastSubmissions.limit+'-'+(podcastSubmissions.pageNumber-1)].continuationToken
        }
        dispatch(getPodcastSubmissions({refresh: true, continuationToken}));
    }
  }

  return (
    <>
      <Modal size={status === 'rss-url-valid' ? 'large' : 'small'} id={'modal-update-rss-url'} className={styles.modal_update_rss_url}
        open={modalUpdateRssUrl.visible}
        onClose={()=> handle_closeModal()}
        closeIcon
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          Update RSS Feed URL
          <br/>
          <Label content={(!updateRssUrl.loading && updateRssUrl.responseData !== null ? 'Previous' : 'Current') + ' RSS URL'} detail={modalUpdateRssUrl.podcastSubmitData.rssFeedUrl} />
          {status === 'rss-url-valid' || status === 'update-rss-url-success' ?
            <>
              <br/>
              <Label content={'New RSS URL'} detail={rssVerification.rssFeedUrl} />
            </>
          :
            null
          }
        </Modal.Header>
        <Modal.Content scrolling>
          <Segment basic className={'modal-content-body'}>
            {status === 'rss-url-valid' ?
              <div className={'view-preview'}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <Segment className={'podcast-content-container'}>
                        <Header>
                          Your Podcast Details
                        </Header>
                        <PodcastRssPreview />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <PodcastSubmit fromView={'ModalUpdateRssUrl'} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            : status === 'update-rss-url-success' ?
              (!updateRssUrl.loading && updateRssUrl.responseData !== null ?
                <div className={'update-rss-url-success'}>
                  {typeof updateRssUrl.responseData.ownerVerify !== 'undefined' && updateRssUrl.responseData.ownerVerify.send === true ?
                    <div className={'success-owner-verify'}>
                      <h2>You've Successfully Updated Your Podcast's RSS URL!</h2>
                      <OwnerVerifyView fromView={'ModalUpdateRssUrl'} />
                      <Button primary content={'Close'}
                        onClick={()=>handle_closeModal()}
                      />
                    </div>
                  : updateRssUrl.responseData.code === 409 ?
                    <div className={'default-success'}>
                      <h2>You've Successfully Updated Your Podcast's RSS URL!</h2>
                      {updateRssUrl.responseData.ihrUrl ?
                        <Item.Group link>
                          <Item href={updateRssUrl.responseData.ihrUrl+'/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                            <Item.Image size='tiny' src={JSON.parse(updateRssUrl.podcastSubmitData.rssFeedDataSnapshot).image} alt={'Podcast Show Image'} />
                            <Item.Content verticalAlign='middle'>
                              <Item.Header>{JSON.parse(updateRssUrl.podcastSubmitData.rssFeedDataSnapshot).title}</Item.Header>
                              <Item.Meta>{updateRssUrl.responseData.ihrUrl+'/'}</Item.Meta>
                            </Item.Content>
                          </Item>
                        </Item.Group>
                      :
                        null
                      }
                      <Button primary content={'Close'}
                        onClick={()=>handle_closeModal()}
                      />
                    </div>
                  : updateRssUrl.responseData.showId ?
                    <div className={'default-success'}>
                      <h2>You've Successfully Updated Your Podcast's RSS URL!</h2>
                      {/* <p>{updateRssUrl.responseData.message ? updateRssUrl.responseData.message : 'RSS Url successfully updated'}</p> */}
                      {updateRssUrl.responseData.ihrUrl ?
                        <Item.Group link>
                          <Item href={updateRssUrl.responseData.ihrUrl+'/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                            <Item.Image size='tiny' src={JSON.parse(updateRssUrl.podcastSubmitData.rssFeedDataSnapshot).image} alt={'Podcast Show Image'} />
                            <Item.Content verticalAlign='middle'>
                              <Item.Header>{JSON.parse(updateRssUrl.podcastSubmitData.rssFeedDataSnapshot).title}</Item.Header>
                              <Item.Meta>{updateRssUrl.responseData.ihrUrl+'/'}</Item.Meta>
                            </Item.Content>
                          </Item>
                        </Item.Group>
                      :
                        null
                      }
                      {/* <a href={updateRssUrl.responseData.ihrUrl+'/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                        {updateRssUrl.responseData.ihrUrl+'/'}
                      </a> */}
                      <Button primary content={'Close'}
                        onClick={()=>handle_closeModal()}
                      />
                    </div>
                  :
                    null
                  }
                </div>
              : !updateRssUrl.loading && updateRssUrl.error ?
                <Message error size={'tiny'} header={updateRssUrl.error.message} content={updateRssUrl.error?.rawError?.rawError?.error} />
              : updateRssUrl.loading ?
                <Segment basic style={{ height: '300px', }}>
                  <Dimmer active inverted><Loader content='Loading' /></Dimmer>
                </Segment>
              :
                null
              )
            :
              <PodcastRssValidate fromView={'ModalUpdateRssUrl'}/>
            }
          </Segment>
          <Segment basic className={'modal-content-disclaimers'}>
            <div>
              <p>
                <strong>Please note:</strong> Only individuals located in our supported regions of The United States, Canada, Mexico, Australia and New Zealand can access and submit podcasts via <a href={'https://podcasters.iheart.com'} target={'_self'} rel="noreferrer" aria-label={'iHeartRadio Podcasters Link'}>podcasters.iheart.com</a>.
              </p>
              <Popup basic hoverable position='bottom center' trigger={<Button secondary className={'help-btn'}>Need Help?</Button>}>
                <p>Contact the <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help Desk</a> to report any issues with podcast submission.</p>
              </Popup>
              <p className={'h5'}>Podcasters must adhere to the <a href={'https://iheartradio.com/legal/cla/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Legal Link'}>iHeartMedia Content License Agreement</a> and podcast content must not violate the <a href={'https://iheartradio.com/legal/pcp/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Content Policy Link'}>iHeartMedia Podcast Content Policy</a>.</p>
            </div>
          </Segment>
        </Modal.Content>
      </Modal>
    </>
  )
}