// Public - Marketing - Homepage - Homepage Podcast Submit - Success View - Owner Verify View
// ----------------------
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPodcastSubmit, resetActiveViewForm } from '../podcastSubmitSlice'

import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

import {
  Card,
  Header,
  Image,
  Button,
} from 'semantic-ui-react';


export default function OwnerVerifyView(props) {

  const fromView = props.fromView;
  //console.debug('OwnerVerifyView - fromView', fromView);

  const dispatch = useDispatch();
  const { rssVerification } = useSelector(selectPodcastSubmit);

  return (
    <>
      <Card fluid>
        <Card.Content textAlign={'center'} className={'card-main-content submission-success'}>
          <div>
            <Card.Header>Podcast Ownership Verification Email Sent</Card.Header>
            <Card.Description>
              <p>A verification email was sent to the owner email address in your RSS feed. Please check your email to verify your Podcast Show Ownership Claim and complete your Podcast Submission. Your show will be submitted to iHeart once you complete the verification process.</p>
            </Card.Description>
            <Card.Description className={'podcast-preview'}>
              <Header icon size={'small'}>
                <Image size={'small'} src={rssVerification.rssData.image} alt={'Podcast Show Image'} />
                {/* <Image size={'small'} src={'https://i.iheart.com/v3/catalog/podcast/'+ podcastSubmitState.submitResponseData.showId +''} /> */}
                <p>{rssVerification.rssData.title}</p>
                {typeof rssVerification.rssData.description !== 'undefined' ?
                  <Header.Subheader>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((typeof rssVerification.rssData.description.long !== 'undefined' ? rssVerification.rssData.description.long : typeof rssVerification.rssData.description.short !== 'undefined' ? rssVerification.rssData.description.short : null ))}}></div>
                  </Header.Subheader>
                :
                  null
                }
              </Header>
            </Card.Description>
            {fromView === 'ModalUpdateRssUrl' ?
              null
            :
              <>
              <Card.Header>Access Your Dashboard</Card.Header>
              <Card.Description className={'dashboard'}>
                <Button as={Link} primary
                  to={'/dashboard'}
                  onClick={()=>dispatch(resetActiveViewForm())}
                >
                  Your Dashboard
                </Button>
              </Card.Description>
              </>
            }
          </div>
        </Card.Content>
      </Card>
    </>
  )
}