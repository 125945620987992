import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { publicReducer } from '../features/public/publicSlice'; // public
import { authIhrWebSdkReducer } from '../features/auth/ihr-web-sdk/authSlice'; // auth/ihr-web-sdk
import { podcastSubmitReducer } from '../features/podcast-submit/podcastSubmitSlice'; // podcast submit
import { dashboardReducer } from '../features/dashboard/dashboardSlice'; // dashboard


export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    public: publicReducer,
    authIhrWebSdk: authIhrWebSdkReducer,
    podcastSubmit: podcastSubmitReducer,
    dashboard: dashboardReducer,
  },
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
