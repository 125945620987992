// Public - Owner Verify View
// ----------------------
import React, { 
  //useState, 
  useEffect, 
  useRef 
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPublic, setRecaptcha, selectOwnerVerification, getOwnerVerification, postOwnerVerification, } from '../publicSlice'

import DefaultSuccessView from '../../podcast-submit/success-view/default-success-view'
import Code409View from '../../podcast-submit/success-view/code-409-view'

import { Link, useNavigate, } from "react-router-dom"
import { HashLink, } from 'react-router-hash-link';
import ReCAPTCHA from "react-google-recaptcha";
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

import {
  Container,
  Header,
  Segment,
  Grid,
  Card,
  Form,
  Message,
  Button,
  Label,
  Image,
  Popup,
  List,
  Input,
} from 'semantic-ui-react'

// styles
import styles from './verify-view.module.scss';

export default function VerifyView(props) {
  //console.debug('VerifyView - props', props);

  const recaptchaRef = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const { recaptcha, ownerVerification } = useSelector(selectPublic);

  const selectedRecord = ownerVerification?.ovByUid[ownerVerification.selected.uuid]?.record ? ownerVerification.ovByUid[ownerVerification.selected.uuid].record : null;
  const rssFeedDataSnapshot = selectedRecord?.submissionData?.rssFeedDataSnapshot ? selectedRecord.submissionData.rssFeedDataSnapshot : null;
  //console.debug('selectedRecord', selectedRecord);
  //console.debug('rssFeedDataSnapshot', rssFeedDataSnapshot);

  useEffect(() => {
    if (!recaptcha.loading && !recaptcha.error && (recaptcha.valid && recaptcha.value)) {
      if (ownerVerification.selected.uuid && typeof ownerVerification.ovByUid[ownerVerification.selected.uuid] === 'undefined') {
        dispatch(getOwnerVerification());
      }
    }
  }, [dispatch, recaptcha, ownerVerification])

  // Google reCaptcha
  const recaptcha_scriptLoaded = () => {
    //console.debug('recaptcha_scriptLoaded');
  }
  const recaptcha_onChange = (value) => {
    //console.debug('Captcha value:', value);
    dispatch(setRecaptcha({value: value}));
  }
  const recaptcha_onError = () => {
    //console.debug('recaptcha_onError');
    const error = {
      type: 'recaptcha',
      message: 'Network error: please try again.'
    };
    dispatch(setRecaptcha({error: error,}));
  }
  const recaptcha_onExpired = () => {
    //console.debug('recaptcha_onExpired');
    const error = {
      type: 'recaptcha',
      message: 'Expired: please try again.'
    };
    dispatch(setRecaptcha({error: error,}));
  }

  const processVerification = () => {
    //console.debug('VerifyView - processVerification', props);
    const { submissionUUID, type } = props;
    // test for verification type
    // if no match to "verify" or "deny" need to redirect to homepage because most likely invalid link
    if (type === 'verify' || type === 'deny') {
      dispatch(selectOwnerVerification({submissionUUID, type}));
    } else {
      navigate('/');
    }
  }

  const handleSelectVerification = (type) => {
    //console.debug('VerifyView - handleSelectVerification - type', type);
    dispatch(selectOwnerVerification({submissionUUID: ownerVerification.selected.uuid, type}));
  }

  const handleVerificationConfirm = () => {
    //console.debug('VerifyView - handleVerificationConfirm');
    dispatch(postOwnerVerification());
  }

  return (
    <>
      <section className={styles.verify_view}>
        <Segment basic id={'verify'}>
          <Segment basic className={'hero'}>
            <Container text>
              <Header as='h2' textAlign={'center'}>
                Verify Podcast Ownership Claim
                <Header.Subheader style={{ marginTop: '15px', }}>
                  {ownerVerification.selected.uuid && typeof ownerVerification.ovByUid[ownerVerification.selected.uuid] !== 'undefined' && !ownerVerification.ovByUid[ownerVerification.selected.uuid].loading && !ownerVerification.ovByUid[ownerVerification.selected.uuid].error && selectedRecord && selectedRecord.verifyOwnerStatus === 'not-verified' ?
                    <span style={{display: 'block',marginBottom: '15px'}}>Please confirm the iHeartRadio User associated with the Podcast Submission is valid by selecting an option below to Verify or Deny.</span>
                  : ownerVerification.selected.uuid && typeof ownerVerification.ovByUid[ownerVerification.selected.uuid] !== 'undefined' && !ownerVerification.ovByUid[ownerVerification.selected.uuid].loading && ownerVerification.ovByUid[ownerVerification.selected.uuid].error ?
                    null
                  :
                    <p>Please access your Owner Verification request to continue.</p>
                  }
                </Header.Subheader>
              </Header>
            </Container>
          </Segment>
          <Segment basic className={'main-content'}>
            <Container>
              {ownerVerification.selected.uuid && typeof ownerVerification.ovByUid[ownerVerification.selected.uuid] !== 'undefined' && !ownerVerification.ovByUid[ownerVerification.selected.uuid].loading ?
                <Grid columns={1} verticalAlign={'top'} stackable>
                  <Grid.Row centered className={'content-row'}>
                    {!ownerVerification.ovByUid[ownerVerification.selected.uuid].error && selectedRecord ?
                      <>
                        <Grid.Column width={10} textAlign={'left'} className={'main-content-column'}>
                          {selectedRecord.verifyOwnerStatus === 'verified' ?
                            <Segment basic className={'status-content-container'}>
                              {selectedRecord.submissionData.code === 409 ?
                                <Code409View record={selectedRecord.submissionData} />
                              : selectedRecord.submissionData.showId ?
                                <DefaultSuccessView record={selectedRecord.submissionData} />
                              : 
                                <p>Something went wrong.</p>
                              }
                            </Segment>
                          :
                            <Segment className={'podcast-rss-preview'}>
                              <Grid columns={'equal'}>
                                <Grid.Row style={{margin: '0', padding: '0',}}>
                                  <Grid.Column width={16}>
                                    <div className={'podcast-field'}>
                                      <span>URL</span>
                                      <Input fluid size={'mini'} type="text" name='rssFeedUrl'
                                        label={{ color: 'green', content: 'Valid' }}
                                        //label={<Message success header={'Valid'} />}
                                        value={selectedRecord.submissionData.rssFeedUrl}
                                        readOnly={'readonly'}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={4}>
                                    <div className={'podcast-field'}>
                                      <span>Podcast Artwork</span>
                                      {typeof rssFeedDataSnapshot.image !== 'undefined' ?
                                        <Image src={rssFeedDataSnapshot.image} alt={'Podcast Show Image'} />
                                      :
                                        null
                                      }
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Type</span>
                                      <p>{typeof rssFeedDataSnapshot.type !== 'undefined' ? rssFeedDataSnapshot.type : null}</p>
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Explicit</span>
                                      {typeof rssFeedDataSnapshot.explicit !== 'undefined' ?
                                        <p>{rssFeedDataSnapshot.explicit ? 'Yes' : 'No'}</p>
                                      :
                                        null
                                      }
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Categories</span>
                                      <Label.Group size='tiny'>
                                        {rssFeedDataSnapshot.categories && rssFeedDataSnapshot.categories.length !== 0 && rssFeedDataSnapshot.categories.map((category, index) => (
                                          <Label key={index}>
                                            {category}
                                          </Label>
                                        ))}
                                      </Label.Group>
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Language</span>
                                      <p>{typeof rssFeedDataSnapshot.language !== 'undefined' ? rssFeedDataSnapshot.language : null}</p>
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Updated</span>
                                      {typeof rssFeedDataSnapshot.updated !== 'undefined' ?
                                        <p>{dayjs(rssFeedDataSnapshot.updated).format('MMMM DD, YYYY')}</p>
                                      :
                                        null
                                      }
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column width={12}>
                                    <div className={'podcast-field'}>
                                      <span>Title</span>
                                        <Header as={'h2'}>
                                          {typeof rssFeedDataSnapshot.title !== 'undefined' ? rssFeedDataSnapshot.title : null}
                                        </Header>
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Description</span>
                                      {typeof rssFeedDataSnapshot.description !== 'undefined' && rssFeedDataSnapshot.description.long !== 'undefined' ?
                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((rssFeedDataSnapshot.description.long))}}></div>
                                      :
                                        null
                                      }
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Copyright</span>
                                      <p>{typeof rssFeedDataSnapshot.copyright !== 'undefined' ? rssFeedDataSnapshot.copyright : null}</p>
                                    </div>
                                    <div className={'podcast-field'}>
                                      <span>Website Link</span>
                                      {typeof rssFeedDataSnapshot.link !== 'undefined' ?
                                        <a href={rssFeedDataSnapshot.link} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Podcast Show Link'}>{rssFeedDataSnapshot.link}</a>
                                      :
                                        null
                                      }
                                    </div>
                                    <div className={'podcast-field'}>
                                      <List horizontal>
                                        <List.Item>
                                          <span>Author</span>
                                          <p>{typeof rssFeedDataSnapshot.author !== 'undefined' ? rssFeedDataSnapshot.author : '\u00A0'}</p>
                                        </List.Item>
                                        <List.Item>
                                          <span>Owner Name</span>
                                          <p>{typeof rssFeedDataSnapshot.owner !== 'undefined' && rssFeedDataSnapshot.owner.name !== 'undefined' ? rssFeedDataSnapshot.owner.name : '\u00A0'}</p>
                                        </List.Item>
                                        <List.Item>
                                          <span>Owner Email</span>
                                          <p>{typeof rssFeedDataSnapshot.owner !== 'undefined' && rssFeedDataSnapshot.owner.email !== 'undefined' ? rssFeedDataSnapshot.owner.email : '\u00A0'}</p>
                                        </List.Item>
                                      </List>
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={'podcast-episodes-row'}>
                                  <Grid.Column width={16}>
                                    <Segment basic className={'podcast-episodes'}>
                                      {typeof rssFeedDataSnapshot.totalEpisodes !== 'undefined' ?
                                        <div style={{ textAlign: 'center', }}>
                                          <Button as={'a'} secondary href={selectedRecord.submissionData.rssFeedUrl} target={'_blank'} rel={'noreferrer'} aria-label={'View all episodes Link'}>
                                            View Episodes
                                          </Button>
                                        </div>
                                      :
                                        null
                                      }
                                    </Segment>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          }
                        </Grid.Column>
                        <Grid.Column width={6} textAlign={'center'} className={'verify-column'}>
                          <Segment>
                            {ownerVerification.selected.verificationOptionSelected === 'verify' ?
                              <>
                                <Message success size={'big'}
                                  header={selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'denied' || selectedRecord.verifyOwnerStatus === false ? 'Verify Ownership Claim' : 'Ownership Claim Verified'}
                                  content={selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'denied' ? 'To confirm your Podcast Submission is Verified please click the button below.' : 'Your Podcast Submission is Verified and no further action is required.'}
                                />
                                {selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'denied' ?
                                  <Button color={'green'} size={'tiny'} content={'Verify Ownership Claim'}
                                    onClick={()=>{handleVerificationConfirm()}}
                                    loading={ownerVerification.selected.loading}
                                    disabled={ownerVerification.selected.loading || ownerVerification.selected.error ? true : false}
                                  />
                                :
                                  <Button as={Link} secondary fluid to={'/dashboard'}>
                                    Your Dashboard
                                  </Button>
                                }
                              </>
                            : ownerVerification.selected.verificationOptionSelected === 'deny' ?
                              <>
                                <Message negative size={'big'}
                                  header={selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'verified' ? 'Deny Ownership Claim' : 'Ownership Claim Denied'}
                                  content={selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'verified' ? 'To confirm your Podcast Submission is Denied please click the button below.' : 'This Podcast Submission is Denied and no further action is required.'}
                                />
                                {selectedRecord.verifyOwnerStatus === 'not-verified' || selectedRecord.verifyOwnerStatus === 'verified' ?
                                  <Button color={'red'} size={'tiny'} content={'Deny Ownership Claim'}
                                    onClick={()=>{handleVerificationConfirm()}}
                                    loading={ownerVerification.selected.loading}
                                    disabled={ownerVerification.selected.loading || ownerVerification.selected.error ? true : false}
                                  />
                                :
                                  null
                                }
                              </>
                            :
                              <h2>Validation Missing</h2>
                            }
                            <Header as='h4' textAlign={'center'}>
                              Change your Ownership Claim
                              <Header.Subheader style={{ marginTop: '15px', }}>
                                You can still change your Ownership Claim by selecting the option below:
                              </Header.Subheader>
                            </Header>
                            {ownerVerification.selected.verificationOptionSelected === 'verify' ?
                              <Button color={'red'} size={'tiny'} content={'Deny Ownership Claim'}
                                onClick={()=>{handleSelectVerification('deny')}}
                                loading={ownerVerification.selected.verificationOptionSelected === 'verify' && ownerVerification.selected.loading ? true : false }
                                disabled={ownerVerification.selected.loading || ownerVerification.selected.error ? true : false}
                              />
                            : ownerVerification.selected.verificationOptionSelected === 'deny' ?
                              <Button color={'green'} size={'tiny'} content={'Confirm Ownership Claim'}
                                onClick={()=>{handleSelectVerification('verify')}}
                                loading={ownerVerification.selected.verificationOptionSelected === 'deny' && ownerVerification.selected.loading ? true : false}
                                disabled={ownerVerification.selected.loading || ownerVerification.selected.error ? true : false}
                              />
                            :
                              null
                            }
                            {ownerVerification.selected.error ?
                              <>
                                <Message error style={{wordBreak: 'break-word',}}
                                  //header={'Something went wrong'}
                                  header={ownerVerification.selected.error.message}
                                />
                                <Button as={HashLink} secondary to={'/#submit-podcast'}>
                                  Resubmit Podcast
                                </Button>
                              </>
                            :
                              null
                            }
                          </Segment>
                        </Grid.Column>
                      </>
                    :
                      <Container text>
                        <Segment basic textAlign={'center'} style={{ width: '100%', height: '400px', }}>
                          <Message error header={ownerVerification.ovByUid[ownerVerification.selected.uuid].error.message} />
                        </Segment>
                      </Container>
                    }
                  </Grid.Row>
                  <Grid.Row centered className={'meta-row'}>
                    <Grid.Column textAlign={'center'} width={6}>
                      <p>
                        <strong>Please note:</strong> Only individuals located in our supported regions of The United States, Canada, Mexico, Australia and New Zealand can access and submit podcasts via <a href={'https://podcasters.iheart.com'} target={'_self'} rel={'noreferrer'} aria-label={'iHeartRadio Podcasters Link'}>podcasters.iheart.com</a>.
                      </p>
                      <Popup basic hoverable position='bottom center' trigger={<Button secondary className={'help-btn'}>Need Help?</Button>}>
                        <p>Contact the <a href={'https://help.iheart.com'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Support Link'}>iHeartRadio Help Desk</a> to report any issues with podcast submission.</p>
                      </Popup>
                      <p className={'h5'}>Podcasters must adhere to the <a href={'https://iheartradio.com/legal/cla/'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Legal Link'}>iHeartMedia Content License Agreement</a> and podcast content must not violate the <a href={'https://iheartradio.com/legal/pcp/'} target={'_blank'} rel={'noreferrer'} aria-label={'iHeartRadio Content Policy Link'}>iHeartMedia Podcast Content Policy</a>.</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              :
                <Grid centered>
                  <Grid.Column computer={6} tablet={16}>
                    <Card fluid>
                      <Card.Content textAlign={'center'} className={'card-main-content'}>
                        <Form 
                          error={recaptcha.error} 
                          //success={match.params.submissionUUID ? true : false}
                        >
                          <Form.Field required className={'recaptcha-field'}>
                            <ReCAPTCHA ref={recaptchaRef} tabindex={2} isolated={'true'} size={'normal'} theme={'light'}
                              sitekey={recaptcha.sitekey}
                              asyncScriptOnLoad={recaptcha_scriptLoaded}
                              onChange={recaptcha_onChange}
                              onErrored={recaptcha_onError}
                              onExpired={recaptcha_onExpired}
                            />
                            {!recaptcha.loading && recaptcha.error && recaptcha.error?.type === 'recaptcha' ?
                              <Message error size={'tiny'} header={recaptcha.error.message} />
                            :
                              null
                            }
                          </Form.Field>
                        </Form>
                        <Button primary fluid style={{ marginTop: '25px', }}
                          loading={recaptcha.loading|| ownerVerification.loading}
                          disabled={recaptcha.loading || recaptcha.error || (!recaptcha.valid || !recaptcha.value || ownerVerification.loading)}
                          onClick={()=>{processVerification()}}
                          aria-label={'Access Owner Verification button'}
                        >
                          Access Owner Verification
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid>
              }
            </Container>
          </Segment>
        </Segment>
      </section>
    </>
  )
}