// utils
//import { getMsGraphAccessToken } from '../auth/msal/utils/MsGraphApiCall';

const REACT_APP_APIM_SUBSCRIPTIONKEY = process.env.REACT_APP_APIM_SUBSCRIPTIONKEY;
//console.log('utils.js - REACT_APP_APIM_SubscriptionKey', REACT_APP_APIM_SubscriptionKey);
const REACT_APP_APIM_VERSION = process.env.REACT_APP_APIM_VERSION;
//console.log('utils.js - REACT_APP_APIM_VERSION', REACT_APP_APIM_VERSION);

export const emailCheck = (email) => {
  // eslint-disable-next-line
  var regExp = new RegExp("[a-z0-9\.-_]*@iheartmedia\.com$", "i");
  let match = email.match(regExp);
  if(match)
    match = true;
  else
    match = false
  //console.debug(email + ' - ' + match);
  return match
}

// generate ihr auth headers
export const generateiHrAuthHeaders = async (getState, from,) => {
  const state = getState();
  let headers = {
    'X-IHR-PID': state.authIhrWebSdk.authentication.loginData.profileId,
    'X-IHR-SID': state.authIhrWebSdk.authentication.loginData.sessionId,
  }
  // azure APIM
  if (REACT_APP_APIM_SUBSCRIPTIONKEY && REACT_APP_APIM_VERSION) {
    headers['Ocp-Apim-Subscription-Key'] = REACT_APP_APIM_SUBSCRIPTIONKEY;
    headers['API-VERSION'] = REACT_APP_APIM_VERSION;
  }
  return headers
}

// generate auth headers
export const generateAuthHeaders = async (getState, from,) => {
  const state = getState();
  //console.debug('generateAuthHeaders - '+ from +' - state.authIhrWebSdk.profile.profileData.email', state.authIhrWebSdk.profile.profileData.email);

  const testUserType = emailCheck(state.authIhrWebSdk.profile.profileData.email) ? 'iheartmedia' : 'external';
  //console.debug('testUserType', testUserType);

  let headers = {
    'PORTAL-USER-TYPE': testUserType, 
    'PORTAL-UID': state.authIhrWebSdk.authentication.loginData.profileId,
    'PORTAL-EMAIL': state.authIhrWebSdk.profile.profileData.email,
  }

  /* const auth = JSON.stringify({ 
    user_type: 'external', 
    uid: state.authIhrWebSdk.authentication.loginData.profileId, 
  }); */
  //msal
  //let authMsal
  if (state.authMsal && state.authMsal.loggedIn && state.authMsal.graphProfile) {
    //const getAuthMsal = await getMsGraphAccessToken();
    //console.debug('generateAuthHeaders - '+ from +' - getAuthMsal', getAuthMsal);
    headers['X-IHMQW-APP-ID'] = 'artistPortal';
    //headers['X-MS-USER-ID'] = getAuthMsal.localAccountId;
    //headers['X-MS-TOKEN-AAD-ID-TOKEN'] = getAuthMsal.accessToken ;

    /* authMsal = JSON.stringify({ 
      'X-IHMQW-APP-ID': 'artistPortal', 
      'X-MS-USER-ID': getAuthMsal.localAccountId, 
      'X-MS-TOKEN-AAD-ID-TOKEN': getAuthMsal.accessToken 
    }); */
  }
  //const headers = { auth, authMsal }
  // azure APIM
  if (REACT_APP_APIM_SUBSCRIPTIONKEY && REACT_APP_APIM_VERSION) {
    headers['Ocp-Apim-Subscription-Key'] = REACT_APP_APIM_SUBSCRIPTIONKEY;
    headers['API-VERSION'] = REACT_APP_APIM_VERSION;
  }
  //console.debug('generateAuthHeaders - '+ from+' - headers', headers);
  return headers
}

// generate API URL
export const generateApiUrl = (from,) => {
  //console.debug('generateApiUrl - from', from);
  // FROM ENV VARS
  const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
  //console.log('portalAccountSlice.js - REACT_APP_ENV_MODE', REACT_APP_ENV_MODE);
  // dev API
  const REACT_APP_AF_LOCAL_BASE_URL = process.env.REACT_APP_AF_LOCAL_BASE_URL;
  //console.log('portalAccountSlice.js - REACT_APP_AF_LOCAL_BASE_URL', REACT_APP_AF_LOCAL_BASE_URL);
  // staging-prod API
  const REACT_APP_APIM_URL = process.env.REACT_APP_APIM_URL;
  //console.log('portalAccountSlice.js - REACT_APP_APIM_URL', REACT_APP_APIM_URL);
  const apiUrl = REACT_APP_ENV_MODE === 'development' ? REACT_APP_AF_LOCAL_BASE_URL : REACT_APP_APIM_URL;
  return apiUrl
}