// Private - Dashboard - Home
// ----------------------
import React, { useState,} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, toggleModal_updateRssUrl, toggleModal_takedownShow, toggleModal_restoreShow } from '../../../dashboardSlice'

// Semantic UI
import {
  Header,
  Button,
  Grid,
  Segment,
  Image,
  Card,
  Menu,
  Message,
  Dropdown,
  Icon,
} from 'semantic-ui-react';
//font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

import svg_icon_facebook from '../../../../../layouts/images/icons/icon-facebook.svg';
//import svg_icon_twitter from '../../../../../layouts/images/icons/icon-twitter.svg';

export default function PodcastExpanded(props) {
  //console.debug('PodcastExpanded - props', props);
  const { data } = props;
  const { showId, submissionUUID, submissionData, onboardStatus, ihrData, verifyOwnerStatus, showStatus, } = data;
  const parsedSubmissionData = JSON.parse(submissionData)
  const podcastTitle = ihrData?.title ? ihrData.title : JSON.parse(parsedSubmissionData.rssFeedDataSnapshot).title;
  const isLive = typeof onboardStatus.message === 'undefined' || onboardStatus.message === 'Show is live.';
  const isFailed = onboardStatus?.code === 404 || onboardStatus?.message === 'Show has failed processing. Contact iHeartRadio.';
  const isProcessing = !isLive && !isFailed;

  let [copied, setCopied] = useState(false);

  const handle_CopyToClipboard = (stringToCopy) => {
    navigator.clipboard.writeText(stringToCopy).then(() => {
      //console.debug('copied')
      return setCopied(true)
    },() => {
      //console.debug('not copied')
      return setCopied(false)
    });
  }

  const dispatch = useDispatch();
  const { modalUpdateRssUrl, } = useSelector(selectDashboard);
  
  const handle_toggleModalUpdateRssUrl = () => {
    //console.debug('handleResendOwnerVerify - data', data);
    const parsedSubmissionData = JSON.parse(data.submissionData);
    const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
    const podcastSubmitData = {
      ihrProfileId: parsedSubmissionData.ihrProfileId,
      ihrProfileName: parsedSubmissionData.ihrProfileName,
      ihrProfileEmail: parsedSubmissionData.ihrProfileEmail,
      ihrUrl: parsedSubmissionData.ihrUrl,
      rssFeedUrl: parsedSubmissionData.rssFeedUrl,
      showId: data.showId,
      submissionUUID: parsedSubmissionData.submissionUUID,
      customScanBroadcasterName: parsedSubmissionData.customScanBroadcasterName,
      rssFeedDataSnapshot: {
        owner: {
          name: parsedRssFeedDataSnapshot?.owner?.name ? parsedRssFeedDataSnapshot.owner.name : null,
          email: parsedRssFeedDataSnapshot.owner.email,
        },
        title: parsedRssFeedDataSnapshot.title,
        description: {
          long: parsedRssFeedDataSnapshot?.description?.long ? parsedRssFeedDataSnapshot.description.long : null,
          short: parsedRssFeedDataSnapshot?.description?.short ? parsedRssFeedDataSnapshot.description.short : null,
        },
        image: parsedRssFeedDataSnapshot.image
      }
    }
    dispatch(toggleModal_updateRssUrl({showId, submissionUUID, podcastSubmitData}));
  }
  
  //console.debug('data', {isLive, isFailed, isProcessing})

  const handle_toggleModalTakedownShow = () => {
    //console.debug('handle_toggleModalTakedownShow - data', data);
    const parsedSubmissionData = JSON.parse(data.submissionData);
    const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
    const podcastSubmitData = {
      ihrProfileId: parsedSubmissionData.ihrProfileId,
      ihrProfileName: parsedSubmissionData.ihrProfileName,
      ihrProfileEmail: parsedSubmissionData.ihrProfileEmail,
      ihrUrl: parsedSubmissionData.ihrUrl,
      rssFeedUrl: parsedSubmissionData.rssFeedUrl,
      showId: data.showId,
      submissionUUID: parsedSubmissionData.submissionUUID,
      customScanBroadcasterName: parsedSubmissionData.customScanBroadcasterName,
      rssFeedDataSnapshot: {
        owner: {
          name: parsedRssFeedDataSnapshot?.owner?.name ? parsedRssFeedDataSnapshot.owner.name : null,
          email: parsedRssFeedDataSnapshot.owner.email,
        },
        title: parsedRssFeedDataSnapshot.title,
        description: {
          long: parsedRssFeedDataSnapshot?.description?.long ? parsedRssFeedDataSnapshot.description.long : null,
          short: parsedRssFeedDataSnapshot?.description?.short ? parsedRssFeedDataSnapshot.description.short : null,
        },
        image: parsedRssFeedDataSnapshot.image
      }
    }
    dispatch(toggleModal_takedownShow({
      showId, 
      partitionKey: data.partitionKey,
      rowKey: data.rowKey,
      showName: data?.ihrData?.title ? data.ihrData.title : parsedRssFeedDataSnapshot.title, 
      podcastSubmitData
    }));
  }

  const handle_toggleModalRestoreShow = () => {
    //console.debug('handle_toggleModalRestoreShow - data', data);
    const parsedSubmissionData = JSON.parse(data.submissionData);
    const parsedRssFeedDataSnapshot = JSON.parse(parsedSubmissionData.rssFeedDataSnapshot);
    dispatch(toggleModal_restoreShow({
      showId,
      partitionKey: data.partitionKey,
      rowKey: data.rowKey,
      showName: data?.ihrData?.title ? data.ihrData.title : parsedRssFeedDataSnapshot.title
    }));
  }

  return (
    <>
      {verifyOwnerStatus === 'verified' ?
        <Segment basic>
          <Grid id={'main-grid'} stackable centered columns={'equal'} className={'main-grid'}>
            <Grid.Row>
              <Grid.Column width={16} className={'content-column'}>
                <Menu secondary fluid borderless>
                  <Menu.Item header>
                    <h3>Podcast Details</h3>
                  </Menu.Item>
                  <Menu.Menu position='right'>
                    <Menu.Item header>
                      <Dropdown floating button icon={'ellipsis vertical'} 
                        text={'Manage Podcast'}
                        disabled={isFailed || (verifyOwnerStatus !== 'verified')}
                        className={'primary black small'}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Header content='Content Settings' />
                          <Dropdown.Item icon={'rss'} text={'Update RSS Feed URL'} 
                            disabled={modalUpdateRssUrl.visible}
                            onClick={()=> handle_toggleModalUpdateRssUrl()}
                          />
                          <Dropdown.Divider />
                          <Dropdown.Header content='Publish Settings' />
                          {showStatus === null || showStatus === 'true' ?
                            <Dropdown.Item
                              onClick={()=> handle_toggleModalTakedownShow()}
                            >
                              <Icon name={'remove'} color={'red'} />
                              Takedown Show
                            </Dropdown.Item>
                          :
                            <Dropdown.Item
                              onClick={()=> handle_toggleModalRestoreShow()}
                            >
                              <Icon name={'add'} color={'green'} />
                              Restore Show
                            </Dropdown.Item>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={'podcast-row'}
              //reversed={'mobile'}
            >
              <Grid.Column width={4}>
                <Segment basic className={'artwork-container'}>
                  <Card fluid link={onboardStatus?.ihrUrl ? true : false}
                    href={onboardStatus?.ihrUrl ? (onboardStatus.ihrUrl +'/') : null}
                    target={onboardStatus?.ihrUrl ? '_blank' : null}
                    aria-label={onboardStatus?.ihrUrl ? 'iHeartRadio Podcast Show Link' : null}
                  >
                    <Image wrapped ui={false} src={data.showImageUrl} alt={'Podcast Show Image'} />
                  </Card>
                  <div className={'content'}>
                    <Header size={'small'}>
                      <p>{podcastTitle}</p>
                      {onboardStatus?.ihrUrl ?
                        <a href={onboardStatus.ihrUrl +'/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                          {onboardStatus.ihrUrl + '/'}
                        </a>
                      :
                        null
                      }
                    </Header>
                    {ihrData?.description ?
                      <p>{ihrData.description}</p>
                    :
                      null
                    }
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column width={12}>
                <Segment basic className={'status-content-container'}>
                  <Message as={isLive && !isFailed ? 'a' : null} size={'large'}
                    success={isLive && !isFailed}
                    warning={isProcessing}
                    error={isFailed}
                    icon={isLive && !isFailed ? 'check' : isFailed ? 'remove' : 'info'}
                    header={isLive && !isFailed ? <h4>Your Podcast is on iHeartRadio!</h4> : isFailed ? <h4>Something Went Wrong!</h4> : <h4>You're Almost There!</h4>}
                    content={isLive && !isFailed ? <p>{onboardStatus.message}</p> : isFailed ? <p>Show has failed processing. <a href={'https://help.iheart.com'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Support Link'}>iHeartRadio Help</a></p> : <p>Show has been scheduled to process. This may take up to 24 hours.</p>}
                    href={(isLive && !isFailed && onboardStatus.ihrUrl) ? onboardStatus.ihrUrl +'/' : null}
                    target={(isLive && !isFailed && onboardStatus.ihrUrl) ? '_blank' : null}
                    rel={(isLive && !isFailed && onboardStatus.ihrUrl) ? 'noreferrer' : null}
                    aria-label={(isLive && !isFailed && onboardStatus.ihrUrl) ? 'iHeartRadio Podcast Show Link' : null}
                  />
                  {isLive && !isFailed && onboardStatus?.ihrUrl ?
                    <Segment basic className={'promote'}>
                      <Grid columns={'equal'} stackable>
                        <Grid.Row>
                          <Grid.Column className={'social'}>
                            <h4>Promote Your Podcast</h4>
                            <p>Share your Podcast on social media!</p>
                            <div>
                              <Button as={'a'} primary className={'facebook'} icon labelPosition='left'
                                href={'https://www.facebook.com/sharer/sharer.php?u='+onboardStatus.ihrUrl+'/'}
                                target={'_blank'}
                                rel="noreferrer"
                                aria-label={'Facebook Share Link'}
                              >
                                <Image size={'mini'} src={svg_icon_facebook} alt={'Facebook Logo icon'} />
                                Facebook
                              </Button>
                              <Button as={'a'} primary className={'twitter'} icon labelPosition='left'
                                href={'https://twitter.com/intent/tweet?related=iHeartRadio&text=I’m%20listening%20to%20'+ podcastTitle + '%20♫%20%40iHeartRadio&url='+onboardStatus.ihrUrl+'/'}
                                target={'_blank'}
                                rel="noreferrer"
                                aria-label={'Twitter Share Link'}
                              >
                                <FontAwesomeIcon icon={faXTwitter} className={'ui mini image'} alt={'Twitter Logo icon'} />
                                {/* <Image size={'mini'} src={svg_icon_twitter} alt={'Twitter Logo icon'} /> */}
                                Twitter
                              </Button>
                            </div>
                          </Grid.Column>
                          <Grid.Column className={'embed'}>
                            <h4>Embed Widget</h4>
                            <Button secondary size={'mini'} floated={'right'} icon={copied ? 'check' : 'copy'} labelPosition='left' content={'Copy to clipboard'}
                              onClick={()=>handle_CopyToClipboard('<iframe allow="autoplay" width="100%" height="300" src="'+ onboardStatus.ihrUrl +'/?embed=true&sc=widget&pname=submityourpodcast" frameborder="0"></iframe>')}
                              aria-label={'Copy HTML Embed Code to clipboard'}
                            >
                            </Button>
                            <p>Embed your podcast on any website or blog for frictionless listening... no app to download, no registration, no video preroll ads... just your episode audio!</p>
                            <code>
                              {'<iframe allow="autoplay" width="100%" height="300" src="'+ onboardStatus.ihrUrl +'/?embed=true&sc=widget&pname=submityourpodcast" frameborder="0"></iframe>'}
                            </code>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  :
                    null
                  }
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      :
        null
      }
    </>
  )
}