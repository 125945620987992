// ihr-web-sdk services

export const service_initialize = () => new Promise((resolve, reject) => {
  if (typeof window !== 'undefined' && window.IHR) {
    /* if (iHRWebSDK === null) {
      iHRWebSDK = window.IHR;
      console.debug('redux service_initialize - window found - iHRWebSDK', window.IHR);
    } */
    window.IHR.initialize({
      container: 'ihr-root',
      //host: 'https://beta.iheart.com',
      //amp: 'https://api2.iheart.com/api/',
      host: 'https://www.iheart.com',
      amp: 'https://us.api.iheart.com/api/',
      pname: 'web',
      callletters: 'ihr-podcasters',  // your unique id
      promo: 'ihr-podcasters', // your campaign id
      account_creation_referrer_web: 'ihr-podcasters' // your unique id
      /* container: 'ihr-root',
      host: 'http://www.iheart.com',
      amp: 'https://us.api.iheart.com/api/'
      pname: 'web',
      callletters: 'ihm-ip',  // your unique id
      promo: 'sdk-test-page', // your campaign id
      account_creation_referrer_web: 'ihm-ip' // your unique id */
    });
    //console.debug('redux service_initialize - iHRWebSDK - init', window.IHR);

    return resolve('ihr web sdk init');
  } else {
    //console.debug('error - window.iHR not available');
    return reject({error: 'window.iHR not available'});
    //return 'error - '
  }
});

export const service_checkAuthStatus = () => new Promise((resolve, reject) => {
  if (typeof window !== 'undefined' && window.IHR) {
    return window.IHR.checkAuthStatus(function (authenticated) {
      if (authenticated) {
        //console.debug('service_checkAuthStatus - authenticated', authenticated);
        // get loginData from authentication response
        const loginData = {
          profileId: authenticated.pid,
          sessionId: authenticated.aid,
          avatar: 'https://i.iheart.com/v3/user/'+ authenticated.pid +'/profile',
        }
        return resolve(loginData);
      } else {
        //console.debug('service_checkAuthStatus - not authenticated');
        return reject({error: 'not authenticated'});
      }
    });
  } else {
    //console.debug('error - window.iHR not available');
    return reject({error: 'window.iHR not available'});
  }
});

export const service_getLogin = () => new Promise((resolve, reject) => {
  if (typeof window !== 'undefined' && window.IHR) {

    return window.IHR.checkAuthStatus(function (authenticated) {
      if (authenticated) {
        // get loginData from authentication response
        const loginData = {
          profileId: authenticated.pid,
          sessionId: authenticated.aid,
          avatar: 'https://i.iheart.com/v3/user/'+ authenticated.pid +'/profile',
        }
        return resolve(loginData);
      } else {
        return window.IHR.login({
          success: function (data) {
            const loginData = data;
            loginData.avatar = 'https://i.iheart.com/v3/user/'+ data.profileId +'/profile';
            return resolve(data);
          },
          error: function (e) {
            //console.debug('error - requestLogin', e);
            return reject({error: e});
            //return error
          }
        });
      }
    });
  } else {
    //console.debug('error - window.iHR not available');
    return reject({error: 'window.iHR not available'});
    //return 'error - '
  }
});

export const service_getProfile = () => new Promise((resolve, reject) => {
  if (typeof window !== 'undefined' && window.IHR) {
    return window.IHR.api.profile.get({
      success: function (data) {
        //console.debug('getProfile - success - data', data);

        // test for profile data recieved
        // incognito states will prevent data from being recieved, which we should reject as a failed login
        if (typeof data.name === 'undefined') {
          const error = 'profile data not found';
          return reject({error: error});
        }
        return resolve(data);
      },
      error: function(error) {
        console.debug('getProfile - error', error);
        return reject({error: error});
      }
    });
  } else {
    //console.debug('error - window.iHR not available');
    return reject({error: 'window.iHR not available'});
  }
});

export const service_getLogOut = () => new Promise((resolve, reject) => {
  if (typeof window !== 'undefined' && window.IHR) {
    return window.IHR.api.profile.logout({
      success: function () {
        // delete cookies too for full logout!
        //cookie.remove('aid', { path: '/' });
        //cookie.remove('pid', { path: '/' });
        //cookie.remove('country', { path: '/' });

        //console.debug('service_getLogOut - logout success');

        return resolve('logout success');
      },
      error: function (e) {
        //console.debug('error - service_getLogOut', e);
        return reject({error: e});
      }
    });
  } else {
    //console.debug('error - window.iHR not available');
    return reject({error: 'window.iHR not available'});
  }
});