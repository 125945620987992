// import icon images
//import bath from 'static/icons/bath.png';

// site data
export const SiteData = {
  navigation: {
    items: [
      {
        key: 'for_you',
        text: 'For You',
        value: 'https://www.iheart.com/for-you/',
      },
      {
        key: 'your_library',
        text: 'Your Library',
        value: 'https://www.iheart.com/your-library/',
      },
      {
        key: 'live_radio',
        text: 'Live Radio',
        value: 'https://www.iheart.com/live/',
      },
      {
        key: 'podcasts',
        text: 'Podcasts',
        value: 'https://www.iheart.com/podcast/',
      },
      {
        key: 'artist_radio',
        text: 'Artist Radio',
        value: 'https://www.iheart.com/artist/',
      },
      {
        key: 'playlists',
        text: 'Playlists',
        value: 'https://www.iheart.com/playlist/',
      },
      {
        key: 'news',
        text: 'News',
        value: 'https://www.iheart.com/news/',
      },
      {
        key: 'features',
        text: 'Features',
        value: 'https://www.iheart.com/content/iheartradio-features/',
      },
      {
        key: 'events',
        text: 'Events',
        value: 'https://www.iheart.com/content/iheartradio-events/',
      },
      {
        key: 'contests',
        text: 'Contests',
        value: 'https://news.iheart.com/contests/',
      },
      {
        key: 'photos',
        text: 'Photos',
        value: 'https://news.iheart.com/photo/',
      },
    ],
    dropdownItems: [
      /* {
        key: 'profile',
        text: 'Profile',
        value: 'https://www.iheart.com/my/',
      },
      {
        key: 'listen_history',
        text: 'Listen History',
        value:'https://www.iheart.com/my/history/',
      },
      {
        key: 'friends',
        text: 'Friends',
        value:'https://www.iheart.com/my/friends/',
      }, */
      {
        key: 'settings',
        text: 'Settings',
        value:'https://www.iheart.com/my/settings/',
      },
      {
        key: 'log_out',
        text: 'Log Out',
        value:'logout',
      },
    ]
  },
}