import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// particle config
import { particleConfig } from '../../components/public/homepage-hero/particle-config';
import { generateApiUrl, generateiHrAuthHeaders } from '../../features/podcast-submit/utils'

const apiUrl = generateApiUrl('portalAccountSlice.js');

// Google Recaptcha Client Key
const GRECAPTCHAKEY = process.env.REACT_APP_GRECAPTCHAKEY;
//console.debug('GRECAPTCHAKEY', GRECAPTCHAKEY);

// construct API Endpoint
// popular podcasts
const ihrApi_podcastCategory = 'https://us.api.iheart.com/api/v3/podcast/categories/';

const initialState = {
  counter: {
    value: 0,
  },
  uiState: {
    fixed: null,
  },
  heroPodcastsCategory: {
    loading: false,
    categoryID: '',
    limit: 10,
    categoryData: null,
    particleData: null,
    error: null,
  },
  recaptcha: {
    sitekey: GRECAPTCHAKEY,
    valid: false,
    value: null,
    error: null,
  },
  ownerVerification: {
    selected: {
      uuid: null,
      verificationOptionSelected: null,
      loading: false,
      response: null,
      error: null,
    },
    loading: false,
    record: null,
    error: null,
    ovByUid: {},
  }
}

export const fetchHeroPodcastCategoryIfNeeded = createAsyncThunk(
  'public/fetchHeroPodcastCategoryIfNeeded',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('fetchHeroPodcastCategoryIfNeeded - args', args);
    const hpcState = JSON.parse(window.localStorage.getItem('hpcState'));
    //console.debug('fetchHeroPodcastCategoryIfNeeded - hpcState', hpcState);
    if (hpcState) {
      return {categoryData: hpcState.categoryData, particleData: hpcState.particleData}
    } else {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        };
        const res = await fetch(ihrApi_podcastCategory + args.categoryID + '?limit='+ args.limit, requestOptions)
          .then((data) => data.json())
          .then((json) => {
            //console.debug('fetchCategory - json', json);
            // limit returned results
            const limitItems = json.podcasts.slice(0, args.limit).map((podcast) => { return podcast });
            //console.debug('fetchCategory - limitItems', limitItems);
            // for each item, convert to particle object format
            let particleItems = [
              {
                src: 'https://i.iheart.com/v3/re/assets.brands/5965418bea8c438f840a101a?ops=format(%22png%22),cover(100,100)',
                height: 80,
                width: 80
              }
            ];
            for (const limitItem of limitItems) {
              const newParticleObject = {
                src: 'https://i.iheart.com/v3/catalog/podcast/'+ limitItem.id +'?ops=quality(80),cover(100,100),blur(4)',
                height: 80,
                width: 80
              };
              particleItems = [...particleItems, newParticleObject]
            }
            const newParticleOptions = JSON.parse(JSON.stringify(particleConfig));
            //console.debug('fetchHeroPodcastCategoryIfNeeded - newParticleOptions', newParticleOptions);
            newParticleOptions.particles.shape.type = [ "images", ];
            newParticleOptions.particles.shape.images = particleItems;
            // store for later
            const hpcState = {
              loading: false,
              categoryID: args.categoryID,
              limit: args.limit,
              categoryData: limitItems,
              particleData: newParticleOptions,
            }
            window.localStorage.setItem('hpcState', JSON.stringify(hpcState));
            return {categoryData: limitItems, particleData: newParticleOptions}
          })
        return res
      } catch (err) {
        //console.debug('fetchHeroPodcastCategoryIfNeeded - err', err);
        return rejectWithValue(err.response.data)
      }
    }
})

export const getOwnerVerification = createAsyncThunk(
  'public/getOwnerVerification',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('getOwnerVerification - args', args);
    let headers = await generateiHrAuthHeaders(getState, 'getOwnerVerification');
    //console.debug('getOwnerVerification - headers', headers);
    const state = getState();
    const { recaptcha, ownerVerification } = state.public;
    //console.debug('getOwnerVerification - ownerVerification', ownerVerification);
    //const { recaptchaToken, } = args;
    headers['recaptcha-token'] = recaptcha.value;
    headers['record-uuid'] = ownerVerification.selected.uuid;
    const requestOptions = {
      method: 'GET',
      headers,
    };
    //console.debug('getOwnerVerification - requestOptions', requestOptions);
    try {
      const res = await fetch(apiUrl+'/owner-verify', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('getOwnerVerification - error', error);
      let errorBody
      if (error.type) {
        errorBody = error
      } else {
        errorBody = {
          type: 'owner-verification',
          message: 'Owner Verification Failed',
          rawError: error && error.message ? error.message : error,
        }
      }
      //throw errorBody
      return rejectWithValue(errorBody)
    }
})

export const postOwnerVerification = createAsyncThunk(
  'public/postOwnerVerification',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('postOwnerVerification - args', args);
    let headers = await generateiHrAuthHeaders(getState, 'postOwnerVerification');
    //console.debug('postOwnerVerification - headers', headers);
    const state = getState();
    const { ownerVerification } = state.public;
    //console.debug('postOwnerVerification - ownerVerification', ownerVerification);
    //const { recaptchaToken, } = args;
    //headers['recaptcha-token'] = recaptcha.value;
    headers['record-uuid'] = ownerVerification.selected.uuid;
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        verificationOptionSelected: ownerVerification.selected.verificationOptionSelected,
      })
    };
    //console.debug('postOwnerVerification - requestOptions', requestOptions);
    try {
      const res = await fetch(apiUrl+'/owner-verify', requestOptions)
      const resJSON = await res.json();
      if (res.ok) {
        return resJSON;
      }
      throw resJSON
    } catch (error) {
      //console.debug('postOwnerVerification - error', error);
      let errorBody
      if (error.type) {
        errorBody = error
      } else {
        errorBody = {
          type: 'owner-verification',
          message: 'Owner Verification Failed',
          rawError: error && error.message ? error.message : error,
        }
      }
      //throw errorBody
      return rejectWithValue(errorBody)
    }
})

export const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    uiStateSetFixed: (state, action) => {
      state.uiState.fixed = action.payload
    },
    setRecaptcha: (state, action) => {
      state.recaptcha.valid = action.payload.error ? false : true
      state.recaptcha.value = action.payload.value ? action.payload.value : null
      state.recaptcha.error = action.payload.error ? true : false
    },
    selectOwnerVerification: (state, action) => {
      state.ownerVerification.selected.uuid = action.payload.submissionUUID
      state.ownerVerification.selected.verificationOptionSelected = action.payload.type
    },
    counter_increment: (state) => {
      state.counter.value += 1
    },
    counter_decrement: (state) => {
      state.counter.value -= 1
    },
    counter_incrementByAmount: (state, action) => {
      state.counter.value += action.payload
    },
  },
  extraReducers: {
    [fetchHeroPodcastCategoryIfNeeded.pending]: (state, { payload, meta }) => {
      //console.debug('meta', meta)
      state.heroPodcastsCategory.loading = true
      state.heroPodcastsCategory.categoryID = meta.arg.categoryID ? meta.arg.categoryID : ''
      state.heroPodcastsCategory.limit = meta.arg.limit ? meta.arg.limit : 10
    },
    [fetchHeroPodcastCategoryIfNeeded.fulfilled]: (state, { payload, meta }) => {
      //console.debug('payload', payload)
      state.heroPodcastsCategory.loading = false
      state.heroPodcastsCategory.categoryData = payload.categoryData ? payload.categoryData : false
      state.heroPodcastsCategory.particleData = payload.particleData ? payload.particleData : false
    },
    [fetchHeroPodcastCategoryIfNeeded.rejected]: (state) => {
      state.heroPodcastsCategory.loading = false
      state.heroPodcastsCategory.error = true
    },
    [getOwnerVerification.pending]: (state, { payload, meta }) => {
      //console.debug('meta', meta)
      state.ownerVerification.loading = true
      state.ownerVerification.record = initialState.ownerVerification.record
      state.ownerVerification.error = initialState.ownerVerification.error
      state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid] = {
        loading: true,
        record: initialState.ownerVerification.record,
        error: initialState.ownerVerification.error,
      }
    },
    [getOwnerVerification.fulfilled]: (state, { payload, meta }) => {
      //console.debug('payload', payload);
      //parse json before loading into redux state
      payload.submissionData = JSON.parse(payload.submissionData);
      payload.submissionData.rssFeedDataSnapshot = JSON.parse(payload.submissionData.rssFeedDataSnapshot);

      state.ownerVerification.loading = false
      state.ownerVerification.record = payload ? payload : null
      state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid] = {
        loading: false,
        record: payload ? payload : null,
      }
    },
    [getOwnerVerification.rejected]: (state, { payload }) => {
      state.ownerVerification.loading = false
      state.ownerVerification.error = payload ? payload : true
      state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid] = {
        loading: false,
        error: payload ? payload : true,
      }
    },
    [postOwnerVerification.pending]: (state, { payload, meta }) => {
      //console.debug('meta', meta)
      state.ownerVerification.selected.loading = true
      state.ownerVerification.selected.response = initialState.ownerVerification.selected.response
      state.ownerVerification.selected.error = initialState.ownerVerification.selected.error
    },
    [postOwnerVerification.fulfilled]: (state, { payload, meta }) => {
      //console.debug('payload', payload);
      if (payload && payload.record && payload.record.rssFeedDataSnapshot) {
        payload.record.rssFeedDataSnapshot = JSON.parse(payload.record.rssFeedDataSnapshot)
      }
      state.ownerVerification.selected.loading = false
      state.ownerVerification.selected.response = payload
      state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid] = {
        ...state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid],
        record: {
          ...state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid].record,
          submissionData: payload.record ? payload.record : state.ownerVerification.ovByUid[state.ownerVerification.selected.uuid].record.submissionData,
          verifyOwnerStatus: state.ownerVerification.selected.verificationOptionSelected === 'verify' ? 'verified' : state.ownerVerification.selected.verificationOptionSelected === 'deny' ? 'denied' : 'not-verified'
        }
      }
    },
    [postOwnerVerification.rejected]: (state, { payload }) => {
      state.ownerVerification.selected.loading = false
      state.ownerVerification.selected.error = payload
    },
  },
})

export const { counter_increment, counter_decrement, counter_incrementByAmount, uiStateSetFixed, setRecaptcha, selectOwnerVerification, } = publicSlice.actions
export const selectPublic = (state) => state.public;
export const publicReducer = publicSlice.reducer
