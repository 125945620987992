// Theme Selector

// ----------------------
// IMPORTS

/* NPM */

// React
import React, { Suspense } from 'react';

const ThemeIhrPodcasters = React.lazy(() => import('./themes/ihr-podcasters'));

// FROM ENV VARS
const REACT_APP_SEMANTIC_THEME = process.env.REACT_APP_SEMANTIC_THEME;

// ----------------------

//const Loading = () => <div>loading...</div>;

// theme selector
const ThemeSelector = ({ children }) => {
  //console.debug('ThemeSelector - REACT_APP_SEMANTIC_THEME', REACT_APP_SEMANTIC_THEME);
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        {REACT_APP_SEMANTIC_THEME === 'ihr-podcasters' && <ThemeIhrPodcasters />}
      </Suspense>
      {children}
    </React.Fragment>
  )
};

/* const ThemeSelector = ({ children }) => {
  return (
    <React.Fragment>
      {REACT_APP_SEMANTIC_THEME === 'artist-portal' && <ThemeArtistPortal />}
      {children}
    </React.Fragment>
  )
} */

export default ThemeSelector;