// particle config

export const particleConfig = {
  retina_detect: true,
  fpsLimit: 30,
  pauseOnBlur: true,
  pauseOnOutsideViewport: true,
  fullScreen: {
    zIndex: 0,
    enable: false,
  },
  interactivity: {
    events: {
      resize: true,
    }
  },
  particles: {
    opacity: {
      value: 0.5,
      random: false,
      /* anim: {
        enable: false,
        speed: 0.5,
        opacity_min: 0.3
      }, */
    },
    number: {
      value: 24,
      density: {
        enable: false,
        value_area: 1200
      }
    },
    line_linked: {
      enable: true,
      distance: 200,
      color: '#c6002b',
      opacity: 0.8,
      width: 0.5,
      shadow: {
        enable: false
      },
    },
    move: {
      enable: true,
      speed: 0.3,
      direction: "top-right",
      random: true,
      straight: true,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    },
    shape: {
      type: [
        //"image",
        "circle"
      ],
      //"image": heroPodcastsCategory.particleData,
    },
    color: {
      value: "#CCC"
    },
    size: {
      value: 40,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        size_min: 20,
        sync: false
      }
    }
  },
}