// Public - Marketing - Homepage - Homepage Podcast Submit - Success View - Code 409 View
// ----------------------
import React, { useState,} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPodcastSubmit, resetActiveViewForm } from '../podcastSubmitSlice'

import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

import {
  Card,
  Header,
  Image,
  Button,
  //Grid,
  //Segment,
} from 'semantic-ui-react';
//font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

import svg_icon_facebook from '../../../layouts/images/icons/icon-facebook.svg';
//import svg_icon_twitter from '../../../layouts/images/icons/icon-twitter.svg';


export default function Code409View(props) {
  const dispatch = useDispatch();
  const { submitPodcast, rssVerification } = useSelector(selectPodcastSubmit);
  const { submitResponseData, } = submitPodcast;

  const { record } = props;
  //console.debug('record', record)

  const rssFeed = {
    title: record && record.rssFeedData && record.rssFeedData.rssPodcastTitle ? record.rssFeedData.rssPodcastTitle : rssVerification && rssVerification.rssData && rssVerification.rssData.title ? rssVerification.rssData.title : record && record.rssFeedDataSnapshot && record.rssFeedDataSnapshot.title ? record.rssFeedDataSnapshot.title : '',
    descriptionShort: record && record.rssFeedData && record.rssFeedData.rssPodcastDescriptionShort ? record.rssFeedData.rssPodcastDescriptionShort : rssVerification && rssVerification.rssData && rssVerification.rssData.description && rssVerification.rssData.description.short ? rssVerification.rssData.description.short : record && record.rssFeedDataSnapshot && record.rssFeedDataSnapshot.description && record.rssFeedDataSnapshot.description.short ? record.rssFeedDataSnapshot.description.short : null,
    descriptionLong: record && record.rssFeedData && record.rssFeedData.rssPodcastDescriptionLong ? record.rssFeedData.rssPodcastDescriptionLong : rssVerification && rssVerification.rssData && rssVerification.rssData.description && rssVerification.rssData.description.long ? rssVerification.rssData.description.long : record && record.rssFeedDataSnapshot && record.rssFeedDataSnapshot.description && record.rssFeedDataSnapshot.description.long ? record.rssFeedDataSnapshot.description.long : null,
  }
  //console.debug('rssFeed', rssFeed)

  let [copied, setCopied] = useState(false);

  const handle_CopyToClipboard = (stringToCopy) => {
    navigator.clipboard.writeText(stringToCopy).then(() => {
      //console.debug('copied')
      return setCopied(true)
    },() => {
      //console.debug('not copied')
      return setCopied(false)
    });
  }

  return (
    <>
      <Card fluid>
        <Card.Content textAlign={'center'} className={'card-main-content submission-success'}>
          <div>
            <Card.Header>Your Podcast is on iHeartRadio!</Card.Header>
            <Card.Description>
              <p>Your Podcast already exists on iHeartRadio.</p>
              <a href={(record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '') +'/'} target={'_blank'} rel="noreferrer" aria-label={'iHeartRadio Podcast Show Link'}>
                {(record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '') +'/'}
              </a>
            </Card.Description>
            <Card.Description className={'podcast-preview'}>
              <Header icon size={'small'}>
                <Image size={'small'} src={'https://i.iheart.com/v3/catalog/podcast/'+ (record && record.showId ? record.showId : submitResponseData && submitResponseData.showId ? submitResponseData.showId : '') +''} alt={'Podcast Show Image'} />
                <p>{rssFeed && rssFeed.title ? rssFeed.title : ''}</p>
                {rssFeed && (rssFeed.descriptionShort || rssFeed.descriptionLong) ?
                  <Header.Subheader>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize((rssFeed.descriptionShort ? rssFeed.descriptionShort : rssFeed.descriptionLong ? rssFeed.descriptionLong : null ))}}></div>
                  </Header.Subheader>
                :
                  null
                }
              </Header>
            </Card.Description>
            <Card.Header>Promote Your Podcast</Card.Header>
            <Card.Description className={'promote'}>
              <Button as={'a'} primary className={'facebook'} icon labelPosition='left'
                href={'https://www.facebook.com/sharer/sharer.php?u='+(record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '')+'/'}
                target={'_blank'}
                rel="noreferrer"
                aria-label={'Facebook Share Link'}
              >
                <Image size={'mini'} src={svg_icon_facebook} alt={'Facebook Logo icon'} />
                Facebook
              </Button>
              <Button as={'a'} primary className={'twitter'} icon labelPosition='left'
                href={'https://twitter.com/intent/tweet?related=iHeartRadio&text=I’m%20listening%20to%20'+ (rssFeed && rssFeed.title ? rssFeed.title : '') + '%20♫%20%40iHeartRadio&url='+(record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '')+'/'}
                target={'_blank'}
                rel="noreferrer"
                aria-label={'Twitter Share Link'}
              >
                <FontAwesomeIcon icon={faXTwitter} className={'ui mini image'} alt={'Twitter Logo icon'} />
                {/* <Image size={'mini'} src={svg_icon_twitter} alt={'Twitter Logo icon'} /> */}
                Twitter
              </Button>
              <Header icon size={'small'}>
                Embed Widget
                <Header.Subheader>
                  <Button secondary size={'mini'} floated={'right'} icon={copied ? 'check' : 'copy'} labelPosition='left' content={'Copy to clipboard'}
                    onClick={()=>handle_CopyToClipboard('<iframe allow="autoplay" width="100%" height="300" src="'+ (record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '') +'/?embed=true&sc=widget&pname=submityourpodcast" frameborder="0"></iframe>')}
                    aria-label={'Copy HTML Embed Code to clipboard'}
                  >
                  </Button>
                  <p>Embed your podcast on any website or blog for frictionless listening... no app to download, no registration, no video preroll ads... just your episode audio!</p>
                  <code>
                    {'<iframe allow="autoplay" width="100%" height="300" src="'+ (record && record.ihrUrl ? record.ihrUrl : submitResponseData && submitResponseData.ihrUrl ? submitResponseData.ihrUrl : '') +'/?embed=true&sc=widget&pname=submityourpodcast" frameborder="0"></iframe>'}
                  </code>
                </Header.Subheader>
              </Header>
            </Card.Description>
            {!record ?
              <>
                <Card.Header>Access Your Dashboard</Card.Header>
                <Card.Description className={'dashboard'}>
                  <Button as={Link} primary
                    to={'/dashboard'}
                    aria-label={'Navigate to Your Dashboard Link'}
                    onClick={()=>dispatch(resetActiveViewForm())}
                  >
                    Your Dashboard
                  </Button>
                </Card.Description>
              </>
            :
              null
            }
          </div>
        </Card.Content>
      </Card>
    </>
  )
}