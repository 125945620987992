// Public - Marketing - Homepage - Homepage Podcast Submit - Landing View
// ----------------------
import React, { 
  //useState, 
  //useEffect 
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAuthIhrWebSdk, fetchLoginIfNeeded, fetchLogout } from '../../auth/ihr-web-sdk/authSlice';
import { 
  //selectPodcastSubmit, 
  setActiveView, 
} from '../podcastSubmitSlice'

import { Link } from "react-router-dom";
import { motion } from "framer-motion"

import {
  Segment,
  Button,
  Card,
  Dimmer,
  Loader,
  Header,
  Image,
} from 'semantic-ui-react';

// styles
import styles from './landing-view.module.scss';

export default function LandingView() {
  const dispatch = useDispatch()
  const { init, authentication, profile, } = useSelector(selectAuthIhrWebSdk);
  //const { view, } = useSelector(selectPodcastSubmit);

  const cardVariants = {
    offscreen: {
      y: 300,
      scale: 0.8, 
      opacity: 0
    },
    onscreen: {
      y: 0,
      scale: 1, 
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    },
    exit: {
      scale: 0.8, 
      opacity: 0
    },
  };

  const handle_ihrLogin = () => {
    //console.debug('handle_ihrLogin');
    dispatch(fetchLoginIfNeeded());
  }
  const handle_startPodcastSubmit = () => {
    //console.debug('handle_startPodcastSubmit');
    dispatch(setActiveView('verify-podcast'));
  }
  const handle_ihrLogout = () => {
    //console.debug('handle_ihrLogout');
    dispatch(fetchLogout());
  }

  return (
    <>
      <section className={styles.podcast_landing_view}>
        <Segment basic id="landing-view" className={'hero-content-wrapper view-landing'}>
          <motion.div className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            exit="exit"
            viewport={{ once: true, amount: 0.8 }}
            //layout
          >
            <motion.div layout className="card-wrapper" variants={cardVariants}>
              <Card fluid>
                <Card.Content textAlign={'center'} className={'card-main-content'}>
                  {!init.loading && init.isInitialized === true && !authentication.loading && !profile.loading ?
                    (!authentication.loggedIn && profile.profileData === null ?
                      <motion.div layoutId="arrow" className={'view-default'}>
                        <Card.Header>Get Started</Card.Header>
                        <Card.Description>
                          <p>Login or sign up with your iHeartRadio Account to continue.</p>
                          <Button primary 
                            onClick={()=>{handle_ihrLogin()}}
                          >
                            Login / Sign Up
                          </Button>
                        </Card.Description>
                      </motion.div>
                    : authentication.loggedIn && profile.profileData !== null ?
                      <motion.div layoutId="arrow" className={'view-options'}>
                        <Card.Header>You've successfully logged in</Card.Header>
                        <Card.Description>
                          <Header size={'small'}>
                            {authentication.loginData?.avatar ?
                              <Image circular src={authentication.loginData.avatar} alt={'Your iHeartRadio Account avatar'} />
                            :
                              null
                            }
                            {profile.profileData.name}
                          </Header>
                        </Card.Description>
                        <Card.Description>
                          <p>If you don't recognize this account, or would like to submit your podcast with a different account, please log out.</p>
                          <div className={'action-options'}>
                            <Button secondary onClick={()=>{handle_ihrLogout()}}>
                              Log Out
                            </Button>
                            <Button as={Link} primary
                              to={'/add-podcast/'}
                              //to={'/add-podcast/?transifex'}
                              onClick={()=>{handle_startPodcastSubmit()}}
                            >
                              Add Your Podcast
                            </Button>
                          </div>
                        </Card.Description>
                        <Card.Header className={'secondary'}>Already submitted a podcast?</Card.Header>
                        <Card.Description>
                          <p>Access your dashboard to manage your podcasts.</p>
                          <Button as={Link} secondary fluid
                            to={'/dashboard'}
                          >
                            Your Dashboard
                          </Button>
                        </Card.Description>
                      </motion.div>
                    :
                      null
                    )
                  :
                    <Segment basic style={{ padding: 0, height: '300px', }}>
                      <Dimmer active inverted><Loader content='Logging In...' /></Dimmer>
                    </Segment>
                  }
                </Card.Content>
              </Card>
            </motion.div>
          </motion.div>
        </Segment>
      </section>
    </>
  )
}