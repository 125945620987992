// Public - Marketing - Homepage
// ----------------------
import React from 'react'
import {
  Segment
} from 'semantic-ui-react'

import HomepageHero from '../../../components/public/homepage-hero/homepage-hero'
import HomepageBenefits from '../../../components/public/homepage-benefits/homepage-benefits'
import HomepageMarketing from '../../../components/public/homepage-marketing/homepage-marketing'
import HomepagePodcastSubmit from '../../podcast-submit/homepage-podcast-submit/homepage-podcast-submit'

// styles
import styles from './homepage.module.scss';


export default function Homepage() {
  return (
    <>
      <Segment basic id="home" className={styles.homepage_view}>
        <HomepageHero />
        <HomepageBenefits />
        <HomepageMarketing />
        <HomepagePodcastSubmit />
      </Segment>
    </>
  )
}