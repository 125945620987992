// Public - Default Layout - React component, that we'll import in `src/components/main/main.js`
// ----------------------
// IMPORTS
// React
import * as React from 'react';
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import { fetchIhrInitIfNeeded, receiveIhrInit } from '../authSlice';

import ScriptTag from '@gumgum/react-script-tag';

/* ---------------------- */

// Reducer Connection
const mapStateToProps = state => {
  //console.debug('mapStateToProps state', state);
  const { authIhrWebSdk } = state;
  return {
    authIhrWebSdk,
  };
}
class AuthWrapperIhrWebSdk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preloadedInitialData: null,
    };
  }

  componentDidMount() {
    //console.log('AuthWrapperIhrWebSdk - did mount fired - state', this.props);
  }
  
  componentWillUnmount() {
    //console.log('PublicDefaultLayout - unmount fired - state', this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('PublicDefaultLayout - componentDidUpdate fired', this.props);
  }

  handle_ihrSdkLoad = () => {
    //console.debug('handle_ihrSdkLoad');
    const { dispatch } = this.props;
    dispatch(fetchIhrInitIfNeeded());
  }
  handle_ihrSdkError = () => {
    //console.debug('handle_ihrSdkError');
    const { dispatch } = this.props;
    dispatch(receiveIhrInit({error: 'script failed to load'}))
  }

  render() {
    //console.log('PublicDefaultLayout - render - this state', this.state)
    //console.log('AuthWrapperIhrWebSdk - render - this props', this.props)

    const { children } = this.props;

    return (
      <React.Fragment>
        {children ? 
          children 
        : 
          <Outlet />
        }
        <ScriptTag type="text/javascript" src={'https://pylon.iheart.com/sdk/latest/sdk.js'} onLoad={ this.handle_ihrSdkLoad } onError={ this.handle_ihrSdkError } />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(AuthWrapperIhrWebSdk);