import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Themes
import ThemeSelector from './theme-selector/index.js';
// Routing
import { BrowserRouter } from "react-router-dom";
// Custom Router
//import { history, CustomRouter } from 'lib/routing.js';
// Redux
import { store } from './app/store';
import { Provider } from 'react-redux';

// FROM ENV VARS
const PUBLIC_URL = process.env.PUBLIC_URL;
//console.log('index.js - PUBLIC_URL', PUBLIC_URL);
const REACT_APP_SEMANTIC_THEME = process.env.REACT_APP_SEMANTIC_THEME;

// handle routes outside basename to redirect back to basename
// if we need multiple basenames running at the same time remove this
if (!window.location.pathname.includes(PUBLIC_URL)) {
  //console.log('index.js - window.location.pathname', window.location.pathname);
  window.history.replaceState(
    '',
    '',
    PUBLIC_URL + window.location.pathname
  );
}

if ((document && document.body && document.body.classList) && REACT_APP_SEMANTIC_THEME) { 
  document.body.classList.add(REACT_APP_SEMANTIC_THEME);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter basename={PUBLIC_URL}>
      <ThemeSelector>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeSelector>
    </BrowserRouter>
  // </React.StrictMode>
);

// Custom Router with history push state support
/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CustomRouter basename={PUBLIC_URL} 
      history={history}
    >
      <ThemeSelector>
        <App />
      </ThemeSelector>
    </CustomRouter>
  </React.StrictMode>
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
