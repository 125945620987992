// Private - Dashboard - Home
// ----------------------
import React from 'react'

import PodcastsTable from './podcasts-table/podcasts-table';

import {
  Segment,
  Container,
  Menu,
  //Button,
} from "semantic-ui-react";

// styles
import styles from './home.module.scss';


export default function DashboardHome() {
  return (
    <>
      <section className={styles.feature_dashboard_home}>
        <Segment basic id={'dashboard-home'}>
          <Container className={'main-container'}>
            <Segment basic className={'header-wrapper'}>
              <Menu fluid borderless>
                <Menu.Item header>
                  <h2>Submitted Podcasts</h2>
                </Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item header>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Segment>
            <PodcastsTable />
          </Container>
        </Segment>
      </section>
    </>
  )
}