/*
    Navigation - Menu Item - Types
    
*/

// React
import React from 'react';

import { Link } from "react-router-dom";

// Router Customizations for minimum features of Nav UI/UX: - Smooth scroll - Scroll Spy
// Add support for hashlinks and smooth scrolling, replaces Link and NavLink above in react-router-dom import because theyre being modified in react-router-hash-link
//import { HashLink, NavHashLink } from 'react-router-hash-link';

// Semantic UI
import {
  Menu,
  Button,
} from 'semantic-ui-react';

// Menu Item Types
// Drupal Type - hashlink
// smoothscroll hashlink to in-page HTML DOM ID
/* export const scrollHashLink = ({key, navItem, selectedLanguage, scrollWithOffset, }) => (
  <Menu.Item as={HashLink} key={key}
    to={navItem.to}
    scroll={el => scrollWithOffset(el, 80)}>
    {selectedLanguage === 'spanish' && navItem.content_alt !== null ? navItem.content_alt : navItem.content }
  </Menu.Item>
);
export const scrollHashLinkMobile = ({key, navItem, selectedLanguage, handleSidebarToggle, mobileNavScroll, }) => (
  <Menu.Item as={HashLink} key={key}
    to={navItem.to}
    onClick={handleSidebarToggle}
    scroll={el => mobileNavScroll(el, 60)}
  >
    {selectedLanguage === 'spanish' && navItem.content_alt !== null ? navItem.content_alt : navItem.content }
  </Menu.Item>
); */

export const InternalLink = ({key, navItem, source, priorityNavRefs}) => {
  let internalLinkRender
  if (source === 'main-nav') {
    internalLinkRender = (
      <Menu.Item key={key} as={Link}
        to={navItem.value}
        className={'internal-nav-link'}
        aria-label={navItem.text+' Navigation Link'}
      >
        {navItem.text}
      </Menu.Item>
    )
  } else {
    internalLinkRender = (
      <Menu.Item key={key} as={Link}
        to={navItem.value}
        className={'internal-nav-link'}
        aria-label={navItem.text+' Navigation Link'}
      >
        {navItem.text}
      </Menu.Item>
    )
  }
  return internalLinkRender
};

export const ExternalLink = ({key, navItem, source, priorityNavRefs}) => {
  let externalLinkRender
  if (source === 'main-nav') {
    externalLinkRender = (
      <Menu.Item key={key} as={'a'} 
        ref={(ref) => {
          //console.debug('ref', ref);
          if (ref !== null) {
            return ( priorityNavRefs[key] = ref)
          }
        }}
        href={navItem.value}
        target={'_blank'}
        rel={'noreferrer'}
        className={'external-nav-link'}
        aria-label={navItem.text+' Navigation Link'}
      >
        {navItem.text}
      </Menu.Item>
    )
  } else {
    externalLinkRender = (
      <Menu.Item key={key} as={'a'}
        href={navItem.value}
        target={'_blank'}
        rel={'noreferrer'}
        className={'external-nav-link'}
        aria-label={navItem.text+' Navigation Link'}
      >
        {navItem.text}
      </Menu.Item>
    )
  }
  return externalLinkRender
};

export const SignUpLink = ({signUpItem, selectedLanguage, uiEventLocation, css}) => {
  let selectedType;

  if (uiEventLocation === 'mobile-nav-bar') {
    selectedType = (
      <Menu.Item position='right' className='getTicketsButtonStyleWrapper' 
        style={{ padding: '0', border: 'none', height: '100%', position: 'absolute', left: '50%', transform: 'translate(-50%,0)', width: '150px' }}
      >
        <Button as={'a'} inverted className={css.signUpButtonStyle + ' postUpSignUpButton'}>
        {selectedLanguage === 'spanish' && signUpItem.content_alt !== null ? signUpItem.content_alt : signUpItem.content}
        </Button>
      </Menu.Item>
    )
  } else {
    selectedType = (
      <Menu.Item className={'sign-up-menu-item'}>
        <Button as={'a'} inverted className={selectedLanguage === 'spanish' ? 'postUpSignUpButtonSpanish' : 'postUpSignUpButton'}>
          {selectedLanguage === 'spanish' && signUpItem.content_alt !== null ? signUpItem.content_alt : signUpItem.content}
        </Button>
      </Menu.Item>
    )
  }

  return selectedType
};

export const GetTicketsLink = ({css, getTicketsItem, selectedLanguage, handleCustomReactPixelTrack, uiEventLocation}) => {

  let selectedType;

  //console.debug('uiEventLocation', uiEventLocation);

  if (uiEventLocation === 'main-nav-bar') {
    selectedType = (
      <Menu.Item as={'a'} position='right' style={{ padding: '0', border: 'none' }} className={css.getTicketsButtonStyle}
        href={getTicketsItem.link}
        target={'_blank'}
        onClick={() => {handleCustomReactPixelTrack('ViewContent', 'Get Tickets', uiEventLocation)}}
      >
        <div>
          <p>
            {selectedLanguage === 'spanish' && getTicketsItem.content_alt !== null ? getTicketsItem.content_alt : getTicketsItem.content}
          </p>
        </div>
      </Menu.Item>
    )
  } else if (uiEventLocation === 'mobile-nav-sidebar') {
    selectedType = (
      <Menu.Item as={'a'} className={css.getTicketsMenuButtonStyle}
        href={getTicketsItem.link}
        target={'_blank'}
        onClick={() => {handleCustomReactPixelTrack('ViewContent', 'Get Tickets', uiEventLocation)}}
      >
        <div>
          <p>
            {selectedLanguage === 'spanish' && getTicketsItem.content_alt !== null ? getTicketsItem.content_alt : getTicketsItem.content}
          </p>
        </div>
      </Menu.Item>
    )
  } else if (uiEventLocation === 'mobile-nav-bar') {
    selectedType = (
      <Menu.Item as={'a'} position={'right'} className={'getTicketsButtonStyleWrapper'}
        onClick={() => {handleCustomReactPixelTrack('ViewContent', 'Get Tickets', uiEventLocation)}}
        style={{ padding: '0', border: 'none', position: 'absolute', left: '50%', transform: 'translate(-50%,0)', width: '150px' }}>
        <div className={css.getTicketsButtonStyle}>
          <p>
            {selectedLanguage === 'spanish' && getTicketsItem.content_alt !== null ? getTicketsItem.content_alt : getTicketsItem.content}
          </p>
        </div>
      </Menu.Item>
    )
  }
  return selectedType
};