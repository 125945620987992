// Public - Marketing - Homepage - Homepage Podcast Submit - Add Podcast View
// ----------------------
import React, { 
  //useState, 
  //useEffect 
} from 'react'
import { useSelector, } from 'react-redux'
import { selectPodcastSubmit, } from '../podcastSubmitSlice'

import { Navigate, 
  //useNavigate, 
  useLocation, 
} from "react-router-dom"
import { motion } from "framer-motion"

import PodcastRssValidate from './podcast-rss-validate'
import PodcastRssPreview from './podcast-rss-preview'
import PodcastSubmit from './podcast-submit'

import {
  Segment,
  Card,
  Header,
  Grid,
} from 'semantic-ui-react';

// styles
import styles from './add-podcast-view.module.scss';

export default function LandingView() {
  const location = useLocation();
  const { view, status, } = useSelector(selectPodcastSubmit);

  const cardVariants = {
    offscreen: {
      x: 300,
      scale: 0.8, 
      opacity: 0
    },
    onscreen: {
      x: 0,
      scale: 1, 
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    },
    exit: {
      x: -300,
      scale: 0.8, 
      opacity: 0
    },
  };

  return (
    <>
      {(view === 'verify-podcast' || view === 'podcast-submit-success') ?
        <section className={styles.podcast_submit_view}>
          <Segment basic id="podcast-submit-view" className={'hero-content-wrapper view-verify'}>
            <motion.div className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              exit="exit"
              viewport={{ once: true, amount: 0.8 }}
              //layout
            >
              <motion.div layout className="card-wrapper" variants={cardVariants}>
                <Grid centered stackable reversed={'mobile'} columns={'equal'} className={'main-grid'}>
                  <Grid.Row>
                    {status === 'rss-url-valid' ?
                      <Grid.Column className={'view-rss-url-valid podcast-content-column'}>
                        <Segment className={'podcast-content-container'}>
                          <Header>
                            Your Podcast Details
                          </Header>
                          <PodcastRssPreview />
                        </Segment>
                      </Grid.Column>
                    :
                      null
                    }
                    <Grid.Column computer={6} tablet={16}>
                      <Card fluid>
                        <Card.Content textAlign={'center'} className={'card-main-content'}>
                          {status === 'rss-url-valid' ?
                            <PodcastSubmit />
                          :
                            <PodcastRssValidate />
                          }
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </motion.div>
            </motion.div>
          </Segment>
        </section>
      :
        <Navigate to={'/'} state={{ from: location }} replace />
      }
    </>
  )
}