import React, { useState, 
  //useEffect 
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPodcastSubmit, postSubmitPodcast, } from '../podcastSubmitSlice'
import { selectDashboard, postUpdateRssUrl, } from '../../dashboard/dashboardSlice'

import {
  Button,
  Card,
  Form,
  Message,
  Checkbox,
} from 'semantic-ui-react';

export default function PodcastSubmit(props) {

  const fromView = props.fromView;
  const dispatch = useDispatch()
  const { submitPodcast, rssVerification } = useSelector(selectPodcastSubmit);
  const { updateRssUrl, modalUpdateRssUrl, } = useSelector(selectDashboard);
  //console.debug('PodcastSubmit - updateRssUrl.updatedByShowId[updateRssUrl.showId]', updateRssUrl.updatedByShowId[updateRssUrl.showId]);

  const [formState, setFormState] = useState({
    inputCheckboxTerms1: false,
    inputCheckboxTerms2: false,
    inputCheckboxTerms3: false,
  })

  // Form - Checkboxes - Terms Verify
  const toggleInputCheckboxTerms = (e, { name, value }) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }))
  }

  const handle_ihrAddPodcast = () => {
    //console.debug('handle_ihrAddPodcast');
    // confirm terms and conditions
    const submitPodcastFormData = {
      confirmNoGuarantee: formState.inputCheckboxTerms1,
      confirmTermsCopyright: formState.inputCheckboxTerms2,
      confirmInfoAccurate: formState.inputCheckboxTerms3,
    };
    dispatch(postSubmitPodcast(submitPodcastFormData));
  }

  const handle_updateRssUrl = () => {
    //console.debug('handle_updateRssUrl');
    // confirm terms and conditions
    const submitPodcastFormData = {
      showId: modalUpdateRssUrl.showId,
      rssFeedUrl: rssVerification.rssFeedUrl,
      confirmNoGuarantee: formState.inputCheckboxTerms1,
      confirmTermsCopyright: formState.inputCheckboxTerms2,
      confirmInfoAccurate: formState.inputCheckboxTerms3,
    };
    dispatch(postUpdateRssUrl(submitPodcastFormData));
  }

  return (
    <>
      <div className={'view-submit'}>
        <Card.Header>Submit Your Podcast</Card.Header>
        <Card.Description style={{margin: '25px 0', }}>
          <p>Review your Podcast Details and agree to all Terms & Conditions to submit your podcast.</p>
        </Card.Description>
        <Card.Header>Terms &amp; Conditions</Card.Header>
        <Card.Description style={{margin: '10px 0 0 0', }}>
          <Form error={submitPodcast.formError || (updateRssUrl.formError)}>
            <Form.Field required>
              <Checkbox
                label={
                  <label>I acknowledge that iHeartMedia does not guarantee my submitted content will be used or published and I agree that my submitted content may be removed at any time without any notice</label>
                }
                name={'inputCheckboxTerms1'}
                checked={formState.inputCheckboxTerms1}
                onChange={toggleInputCheckboxTerms}
              />
            </Form.Field>
            <Form.Field required>
              <Checkbox
                label={
                  <label>I acknowledge that my submitted content is subject to the iHeartMedia Terms of Use and to which I agree to be bound and also subject to the Digital Millennium Copyright Act</label>
                }
                name={'inputCheckboxTerms2'}
                checked={formState.inputCheckboxTerms2}
                onChange={toggleInputCheckboxTerms}
              />
              <Button as={'a'} secondary compact size={'mini'} href={'https://iheartradio.com/legal/cla'} target={'_blank'} aria-label={'iHeartRadio Legal Link'}>iHeartMedia Terms of Use</Button>
            </Form.Field>
            <Form.Field required>
              <Checkbox 
                label={
                  <label>I certify under penalty of perjury that the information provided in this submission is true and accurate, that I am the individual noted, and that I am authorized to act on behalf of the podcast named above and have a good faith belief that the podcast named above do not infringe the intellectual property rights of others</label>
                }
                name={'inputCheckboxTerms3'}
                checked={formState.inputCheckboxTerms3}
                onChange={toggleInputCheckboxTerms}
              />
            </Form.Field>
            {!submitPodcast.loading && submitPodcast.formError && submitPodcast.error && submitPodcast.error.type === 'submit-podcast' ?
              <Message error size={'tiny'} header={submitPodcast.error.message} content={submitPodcast.error?.rawError?.rawError?.message} />
            :(fromView === 'ModalUpdateRssUrl' && (updateRssUrl.updatedByShowId[updateRssUrl.showId] && !updateRssUrl.updatedByShowId[updateRssUrl.showId].loading && updateRssUrl.updatedByShowId[updateRssUrl.showId].formError && updateRssUrl.updatedByShowId[updateRssUrl.showId].error)) ?
              <Message error size={'tiny'} header={updateRssUrl.updatedByShowId[updateRssUrl.showId].error.message} content={updateRssUrl.updatedByShowId[updateRssUrl.showId].error?.rawError?.rawError?.error} />
            :
              null
            }
          </Form>

          <Button primary fluid
            loading={submitPodcast.loading || updateRssUrl.loading}
            disabled={submitPodcast.loading || submitPodcast.error !== null || (formState.inputCheckboxTerms1 === false || formState.inputCheckboxTerms2 === false || formState.inputCheckboxTerms3 === false) || (fromView === 'ModalUpdateRssUrl' && !updateRssUrl.loading && updateRssUrl.formError)}
            onClick={()=>{fromView === 'ModalUpdateRssUrl' ? handle_updateRssUrl() : handle_ihrAddPodcast()}}
            style={{marginTop: '25px', }}
          >
            {fromView === 'ModalUpdateRssUrl' ? 'Update Podcast RSS URL' : 'Submit Podcast' }
          </Button>
        </Card.Description>
      </div>
    </>
  )
}